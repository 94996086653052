import { createBrowserRouter, RouterProvider, useNavigate } from "react-router-dom";
import { ErrorBoundary } from "./Components/Common/ErrorBoundary/Errorboundary";
import MainLayout from "./Components/Layouts/MainLayout/MainLayout";
import ErrorPage from "./Components/Pages/ErrorPage/ErrorPage";
import AuthLayout from "./Components/Layouts/AuthLayout/AuthLayout";
import WelcomePage from "./Components/Pages/Public/WelcomePage/WelcomePage";
import PasskeyPage from "./Components/Pages/Public/PasskeyPage/PasskeyPage";
import SetupPage from "./Components/Pages/Public/SetupPage/SetupPage";
import AddProfile from "./Components/Pages/Public/AddProfile/AddProfile";
import ChooseXp from "./Components/Pages/Public/ChooseXp/ChooseXp";
import TermsConditions from "./Components/Pages/Public/TermsConditions/TermsConditions";
import MyLibfiPage from "./Components/Pages/Private/MyLibfiPage/MyLibfiPage";
import Dashboard from "./Components/Pages/Private/Dashboard/Dashboard";
import PortfolioPage from "./Components/Pages/Private/PortfolioPage/PortfolioPage";
import TradeHistory from "./Components/Pages/Private/TradeHistory/TradeHistory";
import TradePage from "./Components/Pages/Private/TradePage/TradePage";
import TradeSimple from "./Components/Pages/Private/TradeSimple/TradeSimple";
import TradeAdvanced from "./Components/Pages/Private/TradeAdvanced/TradeAdvanced";
import TradeProfessional from "./Components/Pages/Private/TradeProfessional/TradeProfessional";
import Market from "./Components/Common/Cards/PlaceOrders/Market";
import Limit from "./Components/Common/Cards/PlaceOrders/Limit";
import Stoporders from "./Components/Common/Cards/PlaceOrders/Stoporders";
import { RequireAuth } from "./Routes/Guard/AuthGuard";
import EarnPage from "./Components/Pages/Private/EarnPage/EarnPage";
import EarnOverview from "./Components/Pages/Private/EarnOverview/EarnOverview";
import MyInvestment from "./Components/Pages/Private/MyInvestment/MyInvestment";
import EarnAMMPools from "./Components/Pages/Private/EarnAMMPools/EarnAMMPools";
import EarnAssetStaking from "./Components/Pages/Private/EarnAssetStaking/EarnAssetStaking";
import AssetStakingcards from "./Components/Pages/Private/AssetStakingcards/AssetStakingcards";
import AssetStakingToken from "./Components/Pages/Private/AssetStakingToken/AssetStakingToken";
import ShariahOverview from "./Components/Pages/Private/Shariah/ShariahOverview";
import TestnetGuide from "./Components/Pages/Private/TestnetGuide/TestnetGuide";
import ShariahTokenPage from "./Components/Pages/Private/Shariah/ShariahTokenPage";
import MawhibaOnboarding from "./Components/Pages/Private/Mawhiba/MawhibaOnboarding";
import MawhibaOverview from "./Components/Pages/Private/Mawhiba/MawhibaOverview";
import UnderConstruction from "./Components/Pages/UnderConstruction/UnderConstruction";
import AddKycProfile from "./Components/Pages/Public/AddKycProfile/AddKycProfile";
import AddKycDocument from "./Components/Pages/Public/AddKycDocument/AddKycDocument";
import CryptoBuySellPage from "./Components/Pages/Private/CryptoTrade/CryptoBuySellPage";
import OnOffRamp from "./Components/Pages/Private/CryptoTrade/OnOffRamp/OnOffRamp";
import OnOffRampPage, { ON_OFF_RAMP_TYPES } from "./Components/Pages/Private/CryptoTrade/OnOffRamp/OnOffRampPage";
import OrderHistory from "./Components/Pages/Private/CryptoTrade/OrderHistory/OrderHistory";


export const router = createBrowserRouter([
    {
      path: "/",
      element: <AuthLayout />,
      errorElement: <ErrorBoundary />,
        children: [
          {
            index: true,
            element: (
              <>
                <Dashboard />
                <MyLibfiPage />
              </>
            ),
          },
          {
            path: "portfolio",
            element: (
              <>           
                <Dashboard />
                <PortfolioPage />
              </>
            ),
          },
          {
            path: "trade-history",
            element: (
              <>
                <Dashboard />
                <TradeHistory />
              </>
            ),
          },
          {
            path: "trade",
            element: (
              <RequireAuth>
                <TradePage />
              </RequireAuth>
            ),
            children: [
              {
                path: "simple",
                element: <TradeSimple />,
                children: [
                  {
                    path: "market/:from?/:to?/:type?/:page?",
                    element: (
                      <RequireAuth>
                        <Market />
                      </RequireAuth>
                    ),
                  },
                  {
                    path: "limit",
                    element: (
                      <RequireAuth>
                        <Limit />
                      </RequireAuth>
                    ),
                  },
                  {
                    path: "stop-orders",
                    element: (
                      <RequireAuth>
                        <Stoporders />
                      </RequireAuth>
                    ),
                  },
                ],
              },
              {
                path: "professional",
                element: <TradeProfessional />,
                children: [
                  {
                    path: "market",
                    element: (
                      <RequireAuth>
                        <Market />
                      </RequireAuth>
                    ),
                  },
                  {
                    path: "limit",
                    element: (
                      <RequireAuth>
                        <Limit />,
                      </RequireAuth>
                    ),
                  },
                  {
                    path: "stop-orders",
                    element: (
                      <RequireAuth>
                        <Stoporders />
                      </RequireAuth>
                    ),
                  },
                ],
              },
              {
                path: "advanced",
                element: <TradeAdvanced />,
              },
            ],
          },
          {
            path: "earn",
            element: <EarnPage />,
            children: [
              {
                path: "overview",
                element: <EarnOverview />,
              },
              {
                path: "my-investments",
                element: <MyInvestment />,
              },
            ],
          },
          {
            path: "on-off-ramp",
            element: <CryptoBuySellPage />,
            children: [
              {
                path: "trade",
                element: <OnOffRamp />,
                children: [
                  {
                    path: "buy",
                    element: <OnOffRampPage type={ON_OFF_RAMP_TYPES.BUY} />,
                  },
                  {
                    path: "sell",
                    element: <OnOffRampPage type={ON_OFF_RAMP_TYPES.SELL} />,
                  },
                ],
              },
              {
                path: "order-history",
                element: <OrderHistory />,
              },
            ],
          },
          {
            path: "amm-pools/:from?/:to?/:type?/:page?",
            element: (
              <RequireAuth>
                <EarnAMMPools />
              </RequireAuth>
            ),
          },
          {
            path: "asset-staking",
            element: <EarnAssetStaking />,
            children: [
              {
                path: "all",
                element: <AssetStakingcards />,
              },
              {
                path: "stake",
                element: <AssetStakingToken />,
              },
            ],
          },
          {
            path: "shariah",
            children: [
              {
                path: "tokens",
                element: <ShariahOverview />,
              },
              {
                path: "analysis",
                element: (
                  <RequireAuth>
                    <ShariahTokenPage />
                  </RequireAuth>
                ),
              },
            ],
          },
          {
            path: "mawhiba",
            children: [
              {
                path: "onboarding",
                element: <MawhibaOnboarding />,
              },
              {
                path: "overview",
                element: (
                  <RequireAuth>
                    <MawhibaOverview />
                  </RequireAuth>
                ),
              },
            ],
          },
          {
            path: "testnet",
            children: [
              {
                path: "guide",
                element: <TestnetGuide />,
              },
            ],
          },
          {
            path: "under-construction",
            element: <UnderConstruction />,
          },
          {
            path: "*",
            element: <ErrorPage />,
          },
        ]
      },
    {
      path: "/onboarding",
      element: <MainLayout />,
      errorElement: <ErrorBoundary />,
      children: [
        {
          index: true,
          element: <WelcomePage />
        },
        {
          path: "kyc-profile",
          element: 
          <RequireAuth>
            <AddKycProfile />
          </RequireAuth>,
        },
        {
          path: "kyc-document",
          element: 
          <RequireAuth>
            <AddKycDocument />
          </RequireAuth>,
        },
        {
          path: "passkey-setup",
          element: 
          <RequireAuth>
            <PasskeyPage />
          </RequireAuth>,
        },
        {
          path: "add-profile",
          element: 
          <RequireAuth>
            <AddProfile />
          </RequireAuth>,
        },
        // {
        //   path: "choose-experience",
        //   element: <ChooseXp />,
        // },
        {
          path: "terms-conditions",
          element: 
          <RequireAuth>
            <TermsConditions />
          </RequireAuth>,
        },
        {
          path: "*",
          element: <ErrorPage />,
        },
      ],
    },
  ]);