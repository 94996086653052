import { callApiPostMethod } from "../../Redux/Actions/api.action";
import { DOLLAR_VAL, POOLS } from "../../Redux/Actions/apiResponseInterfaces";
import { ZERO_ADDRESS } from "../../Utils";
import { dynamicContractDetails } from "../../Services/dynamicContractDetails";
import LibfiService, { timeoutPromise } from "../libfiService";
import { getTotalReservesOfTokensInPool } from "../libfiService";
import { divideWithDecimal } from "../../Services/common.service";



export const getPoolData = async ({
  dispatch,
  walletAddress,
  sortBy,
  reverse,
}: {
    dispatch: any;
    walletAddress: string;
    sortBy?: string;
    reverse?: boolean;
  }) => {

  const result = await dispatch(
    callApiPostMethod(
      "GET_POOLS",
      sortBy ? { sort: sortBy, reverse } : {},
      false,
      false
    )
  );

  const fetchDollarValue = async () => {
    const result: DOLLAR_VAL = await dispatch(
      callApiPostMethod("DOLLAR_VALUE", {}, false, false)
    );
    return result?.data;
  };

  if (result) {
    
    const dollarValueArray: DOLLAR_VAL["data"] = await fetchDollarValue();

    // Create a map for faster lookup of token URIs and dollar values
    const tokenURIs = new Map(
      dynamicContractDetails.map((info: any) => [info.address?.toLowerCase(), info.img])
    );
    const dollarValueMap = new Map(
      dollarValueArray.map((value: any) => [value.assetAddress.toLowerCase(), value.price])
    );
      
    // Function to handle each data item
    const processData = async (data: any) => {
      if (data?.pairAddress?.toLowerCase() === ZERO_ADDRESS?.toLowerCase()) {
        return null;
      }

      try {
        const [
          totalLiquidity,
          reserves,
          LpDecimals,
          decimals0,
          decimals1
        ] = await Promise.all([
          timeoutPromise(LibfiService.getTotalSupply(data?.pairAddress), 10000),
          timeoutPromise(getTotalReservesOfTokensInPool(data?.pairAddress, data?.token0Address, data?.token1Address), 10000),
          timeoutPromise(LibfiService.getLPDecimals(data?.pairAddress), 10000),
          timeoutPromise(LibfiService.getDecimals(data?.token0Address), 10000),
          timeoutPromise(LibfiService.getDecimals(data?.token1Address), 10000),
        ]);

        data.totalLPAmount = divideWithDecimal(totalLiquidity, LpDecimals)

        data.reserve0 = reserves._reserve0;
        data.decimals0 = decimals0;
        data.reserve1 = reserves._reserve1;
        data.decimals1 = decimals1;

        data.LPDecimals = LpDecimals;

        const totalReserves = reserves._reserve0 + reserves._reserve1;
        data.token0Ratio = totalReserves === 0 ? 0 : ( Number(reserves._reserve0) / Number(totalReserves) ) * 100;
        data.token1Ratio = totalReserves === 0 ? 0 : ( Number(reserves._reserve1) / Number(totalReserves) ) * 100;

        data.token0URI = tokenURIs.get(data?.token0Address?.toLowerCase());
        data.token1URI = tokenURIs.get(data?.token1Address?.toLowerCase());
        data.token0DollarValue = dollarValueMap.get(data?.token0Address?.toLowerCase());
        data.token1DollarValue = dollarValueMap.get(data?.token1Address?.toLowerCase());

        data.volumeInUSD = data.volume;

        data.TVL = Number(reserves._reserve0) * data.token0DollarValue + Number(reserves._reserve1) * data.token1DollarValue;


        if (walletAddress) {

          const userLPBalance = await LibfiService.getLPBalance(walletAddress, data?.pairAddress);
          data.userLPBalance = divideWithDecimal(userLPBalance, data.LPDecimals)

          data.userShare = totalLiquidity !== BigInt(0)
                        ? ( Number(userLPBalance) * 100 ) / Number(totalLiquidity)
                        : "0.00%";

          data.userShareDollarValue = data.userShare !== 0
                                        ? data.userShare * data.TVL / 100
                                        : 0;

          data.userToken0Balance =  data.userShare !== 0
                                      ? data.userShare * Number(data.reserve0) / 100
                                      : 0;
        
          data.userToken1Balance =  data.userShare !== 0
                                      ? data.userShare * Number(data.reserve1) / 100
                                      : 0; 
          

        } else {
          data.userShare = "0.00%";
        }
        return data;
      } catch (error) {
        return null;
      }
    };


    // Process all pools data in parallel
    let poolData: POOLS["data"] = await Promise.all(result?.data?.map(processData) || []);

    // Filter out null values
    poolData = poolData.filter(pool => pool !== null);

    // Sort pools if required
    if (sortBy === 'myPoolShares' && poolData.length > 0) {
      poolData = poolData.sort((prev: any, next: any) => {
        return reverse
          ? next.userShare - prev.userShare
          : prev.userShare - next.userShare;
      });
    }
    return poolData;
  } else {
    return [];
  }
};

