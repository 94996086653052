import React, { useEffect, useMemo, useState } from "react";
import Big from 'big.js';
import "./Market.scss";
import TokenSelect from "../../FormInputs/TokenSelect/TokenSelect";
import {
  InfoIcon,
  SwapIcon,
  WalletIcon,
} from "../../../../Assets/Images/Icons/SvgIcons";
import ButtonCustom from "../../Button/ButtonCustom";
import Tooltip from "../../Tooltip/Tooltip";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { dynamicContractDetails } from "../../../../Services/dynamicContractDetails";
import { settokenOne, settokenTwo } from "../../../../Redux/Slices/tokenSlice";
import { cryptoDecimals, toFixed, cryptoRoundedDecimals, dynamicToDecimal, validateInput, CountDecimalDigits, countDecimals } from "../../../../Services/common.service";
import {
  convertUsingTokenDecimals,
  getAmountsInfunction,
  getAmountsOutfunction,
  getNativeBalance,
  getTokenBalance,
} from "../../../../Services/contractCallService";
import TransactionModal from "../../Modals/TransactionDone/TransactionModal";
import { swapHelperFunction } from "./SwapHelper";
import {
  fetchRecentTrade,
  setTokenSwitched
} from "../../../../Redux/Slices/user.slice";
import { checkUserConnectivity } from "../../../../Services/contract.service";
import { callApiPostMethod } from "../../../../Redux/Actions/api.action";
import {
  GET_AMOUNTS_DATA,
  TOKENS,
  TOKEN_DETAILS,
} from "../../../../interfaces/commonInterfaces";
import {useFetchTokenBalance} from "../../../../LibfiServices/Hooks/usefetchTokenBalance";
import { DOLLAR_VAL } from "../../../../Redux/Actions/apiResponseInterfaces";
import toast from 'react-hot-toast';
import { hundred } from "../../../../Utils";
import { useTranslation } from "react-i18next";
import { useDynamicContext } from '@dynamic-labs/sdk-react-core';
import { newSwapHelperFunction } from "../../../../LibfiServices/Helpers/SwapHelper";
import LibfiService from "../../../../LibfiServices/libfiService";
import { addBreadcrumb } from './../../../../SentryContext';



const Market = () => {

  // State variables
  const dispatch: any = useDispatch();
  const { primaryWallet } = useDynamicContext();
  useSelector((state: any) => state?.user);
  const tokenInfo: TOKENS[] = useSelector((state: any) => state?.tokens?.tokenList);
  const tokenOne: any = useSelector((state: any) => state?.tokens?.tokenOne);
  const tokenTwo: any = useSelector((state: any) => state?.tokens?.tokenTwo);
  const [tokensList, setTokensList] = useState<TOKENS[]>([]);
  const [show, setShow] = useState<boolean>(false);
  const [inputOne, setinputOne] = useState<{ convertedValue: bigint; inputValue: string; }>({ convertedValue: BigInt(0), inputValue: "" });
  const [inputTwo, setinputTwo] = useState<{ convertedValue: bigint; inputValue: string; }>({ convertedValue: BigInt(0), inputValue: "" });
  const [inputOneDisplay, setInputOneDisplay] = useState<string>("");
  const [inputTwoDisplay, setInputTwoDisplay] = useState<string>("");
  const [modalData, setModalData] = useState<{ heading: string; bodyText: string; hideCloseButton?: boolean; hideRetryButton?: boolean; status: string; txHash: string | null; }>({ heading: "", bodyText: "", hideCloseButton: false,  hideRetryButton: false, status: "", txHash: "", });
  const [switchTokenCheck, setSwitchTokenCheck] = useState<boolean>(false);
  const [newTokenSelected, setNewTokenSelected] = useState<boolean>(false);
  const [tokenADollarValue, setTokenADollarValue] = useState<string>("");
  const [tokenBDollarValue, setTokenBDollarValue] = useState<string>("");
  const [defaultSelectedTokens, setDefaultSelectedTokens] = useState<{ token1: TOKENS | null; token2: TOKENS | null; }>({ token1: null, token2: null, });
  const [selectedOption, setSelectedOption] = useState<{ optionOne: TOKENS | null; optionTwo: TOKENS | null; }>({ optionOne: null, optionTwo: null, });
  const [reserveRatio, setReserveRatio] = useState<{ reserve1: number; reserve2: number; }>({ reserve1: 0, reserve2: 0 });
  const [selectedField, setselectedField] = useState<string>("");
  const [triggerHandleChange, setTriggerHandleChange] = useState({ isRequired: false, inputField: "", amountIn: "" });
  const [fetchingReserve, setFetchingReserve] = useState(false);
  const [fetchingDollarValue, setFetchingDollarValue] = useState(false);
  const [autoConvertionState, setAutoConvertionState] = useState({ isLoading: false, inputField: "", });
  const [inputDollarValues, setInputDollarValues] = useState({ inputDollarValueTokenA: 0, inputDollarValueTokenB: 0, inputDollarValuesDiffPercentage: "0" });
  const [swapBtnEffect, setSwapBtnEffect] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [balanceFieldsEffect, setBalanceFieldsEffect] = useState("");
  const [recentFieldInput, setRecentFieldInput] = useState("");
  const fees = 0.0;
  const { t } = useTranslation();

  
  // Hooks

  /**
  * This is a memoized function to get details of boths selected tokens.
  **/
    const tokenDetails: TOKEN_DETAILS | undefined = useMemo(() => {
      const token0: string = tokenOne?.address;
      const token1: string = tokenTwo?.address;
      const tokenData: TOKENS | undefined = dynamicContractDetails.find(
        (a) => a.symbol == "ETH"
      );
      return {
        token0Address: token0,
        token1Address: token1,
        isToken0Native: tokenData?.symbol == tokenOne?.symbol ? true : false,
        isToken1Native: tokenData?.symbol == tokenTwo?.symbol ? true : false,
        token0Name: tokenOne?.symbol,
        token1Name: tokenTwo?.symbol
      };
    }, [tokenOne, tokenTwo]);

  /**
  * This is a custom hook fetching the balance of the two selected tokens from the user's wallet.
  **/
  const { tokenBalance, fetchData, fetchingBalance } = useFetchTokenBalance({
    tokenDetails,
    dispatch
  });

  /**
  * This useEffect hook allows refreshing data every 30s 
  * if no action has been performed by the user
  **/
  useEffect(() => {
    // Run the following functions every 30000 milliseconds = 30 seconds if the user wallet is connected
    const interval = setInterval(() => {
      const runRefreshFunctions = async () => {
        if (primaryWallet?.connected) {
          setIsRefreshing(true);
  
          // This is used to manage the animation for the tokens balances
          setBalanceFieldsEffect("both");
  
          // Ensure fetchData, getReservesRatio, and fetchDollarValue are completed before setting isRefreshing to false
          await fetchData();
          await getReservesRatio();
          await fetchDollarValue();
          
          if (recentFieldInput === "field1" && Number(inputOne.inputValue) !== 0) {
            await handleChange(inputOneDisplay, false, "field1");
          } else if (recentFieldInput === "field2" && Number(inputTwo.inputValue) !== 0) {
            await handleChange(inputTwoDisplay, false, "field2");
          }
  
          // Set isRefreshing to false after all operations are completed
          setIsRefreshing(false);
        }
      };
  
      runRefreshFunctions();
    }, 30000);
  
    return () => clearInterval(interval);
  }, [inputOne, inputTwo, tokenOne, tokenTwo, tokenBalance]);

  /**
  * This useEffect hook updates the token ratio and fetches the dollar 
  * value of each token in response to changes in selected tokens.
  **/
  useEffect(() => {
    getReservesRatio(); 
    fetchDollarValue(); 
  }, [tokenOne, tokenTwo]);

  /**
  * This useEffect hook updates the default tokens displayed
  * in response to changes in token information or selected tokens.
  **/
  useEffect(() => {
    tokenOptionsFunction();
  }, [tokenInfo, tokenOne, tokenTwo]);

  /**
  * This useEffect hook calculates the dollar value and percentage difference for each selected token
  * in response to user input changes or modifications in the dollar value of any selected token.
  **/
  useEffect(() => {
    computeInputDollarValues();
  }, [inputOne, inputTwo, tokenADollarValue, tokenBDollarValue]);

  /**
  * This useEffect hook triggers the handleFunction when a new token is selected or the swap button is clicked.
  **/
  useEffect(() => {
    if (triggerHandleChange.isRequired) {
      handleChange(triggerHandleChange.amountIn, false, triggerHandleChange.inputField);
      setTriggerHandleChange(prevState => ({
        ...prevState,
        isRequired: false,
        amountIn: ""
      }));
    }
  }, [triggerHandleChange]);

  /**
  * This useEffect hook manages the swapBtn rounding effect
  **/
  useEffect(() => {
    autoConvertionState.isLoading ? setSwapBtnEffect(true) : setSwapBtnEffect(false);
  }, [autoConvertionState]);


  // Functions

  /**
  * This function handles opening modal.
  **/
  const handleOpen = () => setShow(true);

  /**
  * This function handles closing modal.
  **/
  const handleClose = () => { setShow(false); };

  /**
  * This function processes token information and modifies the label property 
  * of each token to include an image and symbol, sets default selected 
  * tokens, and updates the tokens list state variable.
  **/
  const tokenOptionsFunction = async () => {
    const tokenList: TOKENS[] = JSON.parse(JSON.stringify(tokenInfo));
    const result: any = tokenList?.map((data: any) => {
      data.label = (
        <div className="token_option">
          <img src={data?.img} alt="token-icon" /> {data?.symbol}
        </div>
      );
      return data;
    });

    setDefaultSelectedTokens({
      token1: result?.find((data: any) => data?.symbol == tokenOne?.symbol),
      token2: result?.find((data: any) => data?.symbol == tokenTwo?.symbol),
    });
    setTokensList(result);
  };

  /**
  * This function resets the input fields, gets the Reserve ratio
  * and fetches the token balance of the user.
  **/
  const getBackToOriginalState = async () => {
    emptyValues();
    fetchData();
    getReservesRatio();
  };

  /**
  * This function empties the values of inputOne and inputTwo states 
  * by setting "convertedValue" and "inputValue" to empty strings.
  **/
  const emptyValues = async () => {
    setinputOne({ 
      convertedValue: BigInt(0), 
      inputValue: "", 
    });
    setinputTwo({ 
      convertedValue: BigInt(0), 
      inputValue: "", 
    });
    setInputOneDisplay("")
    setInputTwoDisplay("")
  };

  /**
   * This function computes the dollar values of each input field
   * and calculates the percentage difference between their dollar values.
   **/
  const computeInputDollarValues = () => {
    if (tokenADollarValue && tokenBDollarValue) {

      const TokenADollarValue = Number(tokenADollarValue) * Number(inputOne.inputValue);
      const TokenBDollarValue = Number(tokenBDollarValue) * Number(inputTwo.inputValue); 
      const TokensDiffPercentage = ((TokenBDollarValue /TokenADollarValue) - 1) * hundred

      setInputDollarValues({
        inputDollarValueTokenA: dynamicToDecimal(TokenADollarValue.toFixed(18), 2),
        inputDollarValueTokenB: dynamicToDecimal(TokenBDollarValue.toFixed(18), 2),
        inputDollarValuesDiffPercentage: TokensDiffPercentage.toFixed(2),
      });

    }
  };

  /**
  * This function fetches the dollar values for all the tokens and assigns 
  * the variables setTokenADollarValue" and "setTokenBDollarValue" to the chosen tokens.
  **/
  const fetchDollarValue = async () => {

    setFetchingDollarValue(true);
  
    const result: DOLLAR_VAL = await dispatch(
      callApiPostMethod("DOLLAR_VALUE", {}, false, false)
    );
  
    // Wrapping ETH //
    // Duplicate the row with ETH and replace "ETH" with "WETH"
    const newResult = result?.data?.map((value: any) => {
      if (value.assetName === "ETH") {
        const wethRow = { ...value, assetName: "WETH" };
        return [value, wethRow];
      }
      return value;
    }).flat();

    if (newResult) {
      await Promise.all(newResult.map(async (value: any) => {
        if (tokenOne?.symbol.toLowerCase() === value?.assetName.toLowerCase()) {
          await setTokenADollarValue(value?.price);
        } else if (tokenTwo?.symbol.toLowerCase() === value?.assetName.toLowerCase()) {
          await setTokenBDollarValue(value?.price);
        }
      }));
    }
  
    setFetchingDollarValue(false);
  };

  /**
  * This function gets the Reserve ratio for the selected tokens.
  **/
  const getReservesRatio = async () => {
    const data: GET_AMOUNTS_DATA = {
      token0Address: tokenOne?.address,
      token1Address: tokenTwo?.address,
      amountIn: BigInt(10) ** BigInt(tokenOne?.decimals), 
      dispatch 
    };

    setFetchingReserve(true);

    // Wrapping ETH //
    // Set Ratio 1:1 for WETH and ETH
    if (tokenOne?.symbol == "ETH" && tokenTwo?.symbol == "WETH" || tokenOne?.symbol == "WETH" && tokenTwo?.symbol == "ETH") {
      setReserveRatio({
        reserve1: 1,
        reserve2: 1
      });
      setFetchingReserve(false);
      return;
    } 

    //const reserveData: string | 0 | undefined = await getAmountsOutfunction(data);
    try {
      const reserveData = await LibfiService.getAmountsOut(data);
      setReserveRatio({
        reserve1: Number(data?.amountIn),
        reserve2: Number(reserveData[1]) / ( 10 ** tokenTwo?.decimals ) || 0
      });
    } catch(error) {
      setReserveRatio({
        reserve1: Number(data?.amountIn),
        reserve2: 0
      });
    }

    setFetchingReserve(false);

  };

  /**
  * This function is invoked when a new token is chosen from either of the two dropdown token lists.
  * If the input field of the newly selected token contains more decimal than specified in the token definition, it resets all fields to 0.
  * Otherwise, it triggers the "handleChange" function to calculate the value of the other field.
  **/
  const SelectTokens = async (item: TOKENS, selectedField: string) => {

    // This is used to manage the animation for the tokens balances
    setBalanceFieldsEffect(selectedField);

    if (selectedField == "field1") {
      setSelectedOption({
        optionOne: item,
        optionTwo: selectedOption?.optionTwo,
      });
      dispatch(settokenOne(item));

    } else if (selectedField == "field2") {
      setSelectedOption({
        optionOne: selectedOption?.optionOne,
        optionTwo: item,
      });
      dispatch(settokenTwo(item));

    }

    const amountIn = selectedField === "field1" ? inputOne.inputValue : inputTwo.inputValue;
    const decimalIndex = amountIn.indexOf('.');
    const numberOfDecimals = decimalIndex !== -1 ? amountIn.length - decimalIndex - 1 : 0;

    if (numberOfDecimals > (item.decimals ?? 0)) {
      emptyValues();
      return;
    }

    setNewTokenSelected(true);

    setTriggerHandleChange({
      isRequired: true,
      inputField: selectedField,
      amountIn: amountIn
    });

  };

  /**
  * Memoized selector to generate full token information with selection status.
  * It maps tokensList to include a boolean flag indicating if each token is selected.
  * Tokens are considered selected if their symbols match those of tokenOne or tokenTwo.
  */
  const tokensFullInfo: TOKENS[] = useMemo(() => {
    return tokensList?.map((data: any) => {
      if (
        data?.symbol?.toLowerCase() == tokenOne?.symbol?.toLowerCase() ||
        data?.symbol?.toLowerCase() == tokenTwo?.symbol?.toLowerCase()
      ) {
        data.isSelected = true;
      } else {
        data.isSelected = false;
      }
      return data;
    });
  }, [tokensList]);

  /**
  * If token1 is a native currency, it deduct a gas fee (0.001) from balance if possible, else sets balance to 0.
  * Then calls the "handleChange" function with adjusted balance and relevant data.
  */
  const handleMaximumFunction = async (data: string) => {
    
    if (data === "field1" && tokenBalance.token1BalanceConverted > BigInt(0)) {

      if (tokenDetails?.isToken0Native) {
        
        const gasFees = BigInt(10000000000000000); // 0.001 as estimated gas fees for native currency
        const newBalanceBig = tokenBalance.token1BalanceConverted - gasFees;
        const newBalance = newBalanceBig > BigInt(0) ? Number(newBalanceBig) / ( 10 ** tokenOne.decimals ) : 0;
        handleChange(newBalance, true, data);

        // Notify the user that a portion of the maximum token amount has been deducted for gas fees
        if (newBalance > 0) {
          toast.success((t('earnModelGasFeesDeduction')).replace('{{tokenName}}', tokenOne?.symbol+""), {
            id: "GasFeesRemoval",
            duration: 4000,
          })
        }

      } else {
        const newBalance = Number(tokenBalance.token1BalanceConverted) / ( 10 ** tokenOne.decimals );
        handleChange(newBalance.toString(), true, data);
      }

    }
  };

  /**
  * This function is called to auto-convert the other field
  * 1. It validates the input value based on the field and token decimals.
  * 2. It resets fields if one input is set to zero while the other has a non-zero value.
  * 3. It sets converted and original values based on token decimals.
  * 4. Performs auto-conversion for the other field and updates its values accordingly.
  */
  const handleChange = async (e: any, max: boolean, field: string) => {
    
    const values = validateInput(e, field, tokenOne?.decimals, tokenTwo?.decimals) !== null ? validateInput(e, field, tokenOne?.decimals, tokenTwo?.decimals) : null;
    let fieldCondition: boolean = field == "field1" ? true : false;
    setselectedField(field);

    const {
      token0Address,
      token1Address,
    }: { token0Address: string; token1Address: string } = tokenDetails;

    if (!values) {
      await emptyValues();

    } else {

      // This is used to refresh the right field during Refresh
      setRecentFieldInput(field);

      // When the user inputs a value equals to 0 into one field, it resets the latter
      // field and doesn't call "getAmountsOutfunction" or "getAmountsInfunction"
      if (parseFloat(values) === 0) {
        if (fieldCondition) {
          setinputOne({
            convertedValue: BigInt(0),
            inputValue: values,
          });
          setinputTwo({
            convertedValue: BigInt(0),
            inputValue: "",
          });
        } else if (!fieldCondition) {
          setinputOne({
            convertedValue: BigInt(0),
            inputValue: "",
          });
          setinputTwo({
            convertedValue: BigInt(0),
            inputValue: values,
          });
        }

        // Manage the display of each input fields
        setInputOneDisplay(fieldCondition ? values : "");
        setInputTwoDisplay(fieldCondition ? "" : values);

        return;
      }

      // When the user selects a new token or switch tokens, 
      // A specific logic is required for input displays
      if (isRefreshing || switchTokenCheck || newTokenSelected || max) {

        if (CountDecimalDigits(values) > 6) {
          setInputOneDisplay(fieldCondition ? dynamicToDecimal(values, 6) : null);
          setInputTwoDisplay(fieldCondition ? null : dynamicToDecimal(values, 6));
        }
        setSwitchTokenCheck(false);
        setNewTokenSelected(false);
    
      } else {
        fieldCondition ? setInputOneDisplay(values) : setInputTwoDisplay(values);
      }

      setAutoConvertionState({
        isLoading: true,
        inputField: field === "field1" ? "field2" : "field1"
      });

      let inputValue = values;

      let originalValue: string = inputValue;
      let convertedValue: bigint = (
        Big(inputValue)
          .times(Big(10)
          .pow(fieldCondition ? tokenOne?.decimals : tokenTwo?.decimals)).toFixed(0)
        ).toLocaleString("fullwide", {
          useGrouping: !1,
        })

      let inputSetter: React.Dispatch<
        React.SetStateAction<{
          convertedValue: bigint;
          inputValue: string;
        }>
      > = fieldCondition ? setinputOne : setinputTwo;
      inputSetter({
        convertedValue: convertedValue,
        inputValue: originalValue,
      });

      // Auto Convertion for the other field
      const data: GET_AMOUNTS_DATA = {
        token0Address: token0Address,
        token1Address: token1Address,
        amountIn: convertedValue,
        max: max,
        dispatch,
      };

      //console.log("TokenOne: " + JSON.stringify(tokenOne.symbol))
      //console.log("TokenTwo: " + JSON.stringify(tokenTwo.symbol))
      //console.log("Input - Value: " + originalValue)
      //console.log("Input - Converted Value: " + data.amountIn)

      try {
        const tokenValue = fieldCondition
          ? await LibfiService.getAmountsOut(data)
          : await LibfiService.getAmountsIn(data)

        //console.log("tokenValue: " + tokenValue)
          
        if (tokenValue.toString().includes("IDENTICAL_ADDRESSES")) {
          // Wrapping ETH //
          // Duplicate the value of the input field to the other field
          if ((tokenOne?.symbol == "ETH" && tokenTwo?.symbol == "WETH") || (tokenOne?.symbol == "WETH" && tokenTwo?.symbol == "ETH")) {
            if (fieldCondition) {
              setinputTwo({
                convertedValue: convertedValue,
                inputValue: originalValue,
              });
              setInputTwoDisplay(inputValue);
            } else {
              setinputOne({
                convertedValue: convertedValue,
                inputValue: originalValue,
              });
              setInputOneDisplay(inputValue);
            }
          }
        } else {

          const calculatedBalance: string | number =
            await convertUsingTokenDecimals(
              fieldCondition ? token1Address : token0Address,
              fieldCondition ? tokenValue[1] : tokenValue[0],
              dispatch
            );

          if (Number(calculatedBalance)) {
            fieldCondition
              ? setinputTwo({
                  convertedValue: tokenValue[1],
                  inputValue: calculatedBalance,
                })
              : setinputOne({
                  convertedValue: tokenValue[0],
                  inputValue: calculatedBalance,
                });
          }

          // Manage the display of the other field
          fieldCondition ? setInputTwoDisplay(dynamicToDecimal(calculatedBalance, 6)) : setInputOneDisplay(dynamicToDecimal(calculatedBalance, 6));
            
          //console.log("Output - Value: " + calculatedBalance)
          //console.log("Output - Converted Value: " + tokenValue.data[1])
        }   
      } catch (error) {
        console.log("error: " + error)
      }

      setAutoConvertionState({
        isLoading: false,
        inputField: ""
      });

    }
  };

  /**
  * This function is invoked when the "Switch" button is clicked.
  * It updates the token list labels with their corresponding symbols and images.
  * It updates tokenOne and tokenTwo states.
  * It sets the "inputOne" values with the "inputTwo" values and triggers "handleChange" to compute the new "inputTwo" values.
  **/
  const handleSwitch = async () => {

    // This is used to manage the animation for the tokens balances
    setBalanceFieldsEffect("both");

    const tokenList: TOKENS[] = JSON.parse(JSON.stringify(tokenInfo));
    const result: TOKENS[] = tokenList?.map((data: any) => {
      data.label = (
        <>
          <img src={data?.img} alt="token-icon" /> {data?.symbol}
        </>
      );
      return data;
    });
    const token1: TOKENS | undefined = result?.find(
      (data: any) => data?.symbol == tokenOne?.symbol
    );
    const token2: TOKENS | undefined = result?.find(
      (data: any) => data?.symbol == tokenTwo?.symbol
    );

    dispatch(settokenOne(token2));
    dispatch(settokenTwo(token1));

    setinputOne({
      convertedValue: inputTwo?.convertedValue,
      inputValue: inputTwo?.inputValue,
    });
    setInputOneDisplay(inputTwoDisplay);

    setSwitchTokenCheck(true);

    setTriggerHandleChange({
      isRequired: true,
      inputField: "field1",
      amountIn: inputTwo?.inputValue
    });

  };

  /**
  * This function executes the swap
  **/
  const handleSwap = async () => {

    addBreadcrumb('Swap', '[Swap] Button Clicked', 'info');
    addBreadcrumb('Swap', '[Swap] Process Started - ' + tokenOne?.symbol + ' => ' + tokenTwo?.symbol, 'info');

    // Wrapping ETH //
    // Toast Display
    if (tokenOne?.symbol == "ETH" && tokenTwo?.symbol == "WETH" || tokenOne?.symbol == "WETH" && tokenTwo?.symbol == "ETH") {
      toast.error(
        <div>
          <div style={{ textAlign: "center" }}>{t('tradeMarketWrappingServiceAvailableSoon')}</div>
          <div style={{ textAlign: "center" }}>{t('tradeMarketSwapOtherPairs')}</div>
        </div>,
        { id: "WrappingServices" }
       );
      return;
    }
    
    try {
      if (!primaryWallet?.connected) return;

      const swapHelperFunctionResponse = await newSwapHelperFunction(setModalData, setShow, {
        tokenDetails,
        input1: inputOne,
        input2: inputTwo,
        walletAddress: primaryWallet?.address,
        selectedField,
        t
      });

      getBackToOriginalState();

      if (swapHelperFunctionResponse) {
        dispatch(fetchRecentTrade(true));
      } else {
        dispatch(fetchRecentTrade(false));
        dismiss();
      }

    } catch (error) { 

      dismiss(); 

    }
  };

  /**
  * This function allows displaying the informative toast for Embedded Wallets
  **/
  const displayToast = () => {
    toast.loading(modalData.bodyText, {
      id: "SwapInfoToast",
      style: { fontSize: '16px' }
    })
  };

  /**
  * This function allows hiding the informative toast for Embedded Wallets
  **/
  const dismiss = () => toast.dismiss("SwapInfoToast")

  /**
  * Checks if both inputOne and inputTwo values are non-empty and have decimal parts.
  */
  const isFormValid: boolean | undefined =
    inputOne?.inputValue !== "" &&
    inputTwo.inputValue !== "" &&
    inputOne?.inputValue?.split(".")[1] !== "" &&
    inputTwo?.inputValue?.split(".")[1] !== "";

  /**
   * Checks if the token1 balance is sufficient for the inputOne converted value,
   * or if the inputOne converted value is zero and maximum value check is enabled,
   * while ensuring the wallet address is not empty.
   */
  const insufficientBalance: boolean =
    ( Number(tokenBalance?.token1BalanceConverted) <
      Number(inputOne?.convertedValue) ) &&
      primaryWallet?.address != "";

  /**
  * Checks if the user has enough native tokens to pay the estimated gas fees
  * Returns a boolean indicating whether there are sufficient funds for gas fees
  **/
  const insufficientGasFees: boolean =
      ( tokenDetails.isToken0Native && inputOne?.convertedValue != BigInt(0) &&
        Number(tokenBalance?.token1BalanceConverted) - Number(inputOne?.convertedValue) < 10000000000000000 &&
        Number(tokenBalance?.token1BalanceConverted) - Number(inputOne?.convertedValue) >= 0 )  &&
        primaryWallet?.address != "";



  return (
    <>
      <div className="market_data_sec">
        
        <div className="market-section">
          <div className="market-input-container">
            <div className="market-input-container-input">
              <input
                  placeholder="0.0"
                  type="text"
                  className={`amountInput ${autoConvertionState.inputField === "field1" ? 'input-greyedout' : ''}`}
                  maxLength={18}
                  value={inputOneDisplay}
                  onChange={(e) => {
                    e.preventDefault();
                    handleChange(e.target.value, false, "field1");
                  }}
                  inputMode="decimal"
                  name="fname"
                  autoComplete="off"
                />
            </div>
            <TokenSelect
                options={tokensFullInfo}
                dropIcon
                defaultValue={defaultSelectedTokens?.token1}
                selectedField="field1"
                selectedOption={selectedOption?.optionOne}
                SelectTokens={SelectTokens}
              />
          </div>
        
          <div className="market-data-container">
            <div className={`market-data-container-input-dollar-value ${autoConvertionState.isLoading ? 'input-greyedout' : ''}`}>
              {inputDollarValues.inputDollarValueTokenA !== 0 && (
                <p>
                  <span>$</span>
                  <span>{inputDollarValues.inputDollarValueTokenA}</span>
                </p>
              )}
            </div>
            <p className="balance_text">
              {t('tradeMarketBalance')}:
                <span className={`${fetchingBalance && ["field1", "both"].includes(balanceFieldsEffect) ? 'text-shadow disabled-button' : ''} ${ !fetchingBalance && ["field1", "both"].includes(balanceFieldsEffect) ? 'lineUp' : '' }`}>
                  { primaryWallet?.connected
                    ? cryptoDecimals(tokenBalance?.token1Balance)
                    : 0}
                </span>
                <ButtonCustom
                  title={t('tradeMarketButtonMax')}
                  className="max_btn"
                  onClick={() => handleMaximumFunction("field1")}
                />
              </p>
          </div>
        </div>


        <button className={`swapBtn ${swapBtnEffect ? 'active' : ''}`}  onClick={() => handleSwitch()}>
          <SwapIcon />
        </button>


        <div className="market-section">
          <div className="market-input-container">
            <div className="market-input-container-input">
              <input
                  placeholder="0.0"
                  type="text"
                  className={`amountInput ${autoConvertionState.inputField === "field2" ? 'input-greyedout' : ''}`}
                  maxLength={18}
                  value={inputTwoDisplay}
                  onChange={(e: any) => {
                    e.preventDefault();
                    handleChange(e.target.value, false, "field2");
                  }}
                  inputMode="decimal"
                  name="fname"
                  autoComplete="off"
                />
            </div>
            <TokenSelect
                options={tokensFullInfo}
                dropIcon
                defaultValue={defaultSelectedTokens?.token2}
                selectedField="field2"
                selectedOption={selectedOption?.optionTwo}
                SelectTokens={SelectTokens}
              />
          </div>
        
          <div className="market-data-container">
            <div className={`market-data-container-input-dollar-value ${autoConvertionState.isLoading ? 'input-greyedout' : ''}`}>
              {inputDollarValues.inputDollarValueTokenB !== 0 && (
                <p>
                  <span>$</span>
                  <span>{inputDollarValues.inputDollarValueTokenB}</span>
                  {Number(inputDollarValues.inputDollarValuesDiffPercentage) !== 0 && Number(inputDollarValues.inputDollarValuesDiffPercentage) >= -100 && Number(inputDollarValues.inputDollarValuesDiffPercentage) <= 100 && (
                      <Tooltip icon={
                          <span 
                            className={`${autoConvertionState.isLoading ? 'input-transparent' : ''}`}
                            style={{ 
                              color: 
                                  Number(inputDollarValues.inputDollarValuesDiffPercentage) > 0 ? 'green' : 
                                  Number(inputDollarValues.inputDollarValuesDiffPercentage) < -5 ? 'rgb(238, 89, 89)' : 
                                  Number(inputDollarValues.inputDollarValuesDiffPercentage) < -1 ? 'orange' : 
                                  'rgb(138, 138, 138)',
                          }}>
                            <span style={{color: 'inherit'}}>({inputDollarValues.inputDollarValuesDiffPercentage}</span>
                            <span style={{color: 'inherit'}}>%)</span>
                          </span>
                      }>
                          <p>{t('tradeMarketEstimatedAmountDiffText')}</p>
                      </Tooltip>
                  )}
                </p>
              )}
            </div>
            <p className="balance_text">
                {t('tradeMarketBalance')}:
                <span className={`${fetchingBalance && ["field2", "both"].includes(balanceFieldsEffect) ? 'text-shadow disabled-button' : ''} ${ !fetchingBalance && ["field2", "both"].includes(balanceFieldsEffect)  ? 'lineUp' : '' }`}>
                  { primaryWallet?.connected
                    ? cryptoDecimals(tokenBalance?.token2Balance)
                    : 0}
                </span>
              </p>
          </div>
        </div>


        <>
          { Number(inputTwo.inputValue) != 0 && (
            <div className="market-total-section">
              <p className="left-border"> { t('tradeMarketReceiveMessage').replace('{{fees}}', fees+"") }</p>
              <p className="right-border">
                {(() => {
                  const inputValue = Number(inputTwo.inputValue);
                  const feesAdjustedValue = inputValue * (1 - fees);
                  const decimals =
                    countDecimals(inputValue) === 0
                      ? 2
                      : Math.min(6, countDecimals(inputValue));
                  const result = feesAdjustedValue.toFixed(decimals);
                  return Number(result) < 0.000001 ? "< 0.000001" : result;
                })()} {tokenTwo?.symbol}
              </p>
            </div>
          )}
        </>


        <div className="market_data_sec_footer">
          <p>
            {" "}
            <Tooltip icon={<InfoIcon />} heading="" content={t('tradeMarketCurrentRate')} />
            {/* <Shimmer className="me-2" height={20} width={100} /> */}
            {/*<span className="ms-2">*/}
            <span className={`market_data_sec_footer_text ${fetchingReserve ? 'input-greyedout' : ''}`}>
              <span>1</span>
              <span>{tokenOne?.symbol}</span>
              <span>=</span>
              <span>{cryptoRoundedDecimals(reserveRatio.reserve2)}{" "}</span>
              <span>{tokenTwo?.symbol}</span>
              <span className={`${fetchingDollarValue && !tokenADollarValue ? 'input-transparent' : ''}`} >
                <span>{"($"}</span>
                <span>{Number(tokenADollarValue).toFixed(2)}</span>
                <span>{")"}</span>
              </span>
            </span>
          </p>

          <ButtonCustom
            onClick={() => handleSwap()}
            disabled={ !isFormValid || insufficientBalance || insufficientGasFees || !primaryWallet?.connected }
            title={
              <> 
                { insufficientBalance
                  ? `${(t('tradeMarketInsufficient')).replace('{{token}}', tokenOne?.symbol)}`
                  : insufficientGasFees
                    ? `${(t('tradeMarketInsufficientGasFees')).replace('{{token}}', tokenOne?.symbol)}`
                    : (tokenOne?.symbol === "ETH" && tokenTwo?.symbol === "WETH") || (tokenOne?.symbol === "WETH" && tokenTwo?.symbol === "ETH")
                      ? t('tradeMarketWrap')
                      : t('tradeMarketButtonSwap')}
              </>
            }
          />
        </div>
      </div>

      {show && (
        <>
          {primaryWallet?.connector.isEmbeddedWallet && modalData?.status === "pending" ? displayToast() : dismiss()}

          {(!primaryWallet?.connector.isEmbeddedWallet || modalData?.status !== "pending") && (
            <TransactionModal
              show={show}
              modalData={modalData}
              handleClose={handleClose}
              handleFunction={handleSwap}
            />
          )}
        </>
      )}

    </>
  );
};

export default Market;
