import JSBI from "jsbi";
import moment from "moment";
import Web3 from "web3";

/**CUTMIZE ADDRESS FOR SHOW */
export const custmizeAddress = (address: string) => {
  let firstFive = address.substring(0, 5);
  let lastFour = address.substring(address.length - 4);
  return firstFive + "..." + lastFour;
};

export function numberToString(arg: any) {
  if (typeof arg === "string") {
    if (!arg.match(/^-?[0-9.]+$/)) {
      throw new Error(
        "while converting number to string, invalid number value '" +
          arg +
          "', should be a number matching (^-?[0-9.]+)."
      );
    }
    return arg;
  } else if (typeof arg === "number") {
    return String(arg);
  } else if (
    typeof arg === "object" &&
    arg.toString &&
    (arg.toTwos || arg.dividedToIntegerBy)
  ) {
    if (arg.toPrecision) {
      return String(arg.toPrecision());
    } else {
      // eslint-disable-line
      return arg.toString(10);
    }
  }
  throw new Error(
    "while converting number to string, invalid number value '" +
      arg +
      "' type " +
      typeof arg +
      "."
  );
}

// Function to convert into wei
function toWei(input: any, unit: any) {
  var ether = numberToString(input); // eslint-disable-line
  var base = unit;
  var baseLength = base.length - 1 || 1;
  if (ether === ".") {
    throw new Error(
      "[ethjs-unit] while converting number " + input + " to wei, invalid value"
    );
  }

  // Is it negative?
  var negative = ether.substring(0, 1) === "-";

  if (negative) {
    ether = ether.substring(1);
  }
  // Split it into a whole and fractional part
  var comps = ether.split("."); // eslint-disable-line
  if (comps.length > 2) {
    throw new Error(
      "[ethjs-unit] while converting number " +
        input +
        " to wei,  too many decimal points"
    );
  }
  var whole = comps[0],
    fraction = comps[1]; // eslint-disable-line
  if (!whole) {
    whole = "0";
  }
  if (!fraction) {
    fraction = "0";
  }
  if (fraction.length > baseLength) {
    throw new Error(
      "[ethjs-unit] while converting number " +
        input +
        " to wei, too many decimal places"
    );
  }

  while (fraction.length < baseLength) {
    fraction += "0";
  }

  if (!parseInt(whole)) {
    return fraction.replace(/^0*(?=[1-9])/g, "");
  }

  if (negative) {
    return "-" + whole + fraction;
  }
  return whole + fraction;
}

export function fromWei(input: any, unit: any) {
  if (!input) return "0";

  let str = "";

  if (Math.sign(Number(input)) !== Math.sign(Number(unit))) str += "-";

  const numer = Math.abs(Number(input));
  const denom = Math.abs(unit);

  str += Math.floor(numer / denom);
  let rem = numer % denom;
  if (!rem) return str;
  str += ".";

  const map = new Map();

  while (rem !== 0) {
    map.set(rem, str.length);

    rem *= 10;
    str += Math.floor(rem / denom);
    rem %= denom;

    if (map.has(rem)) {
      const idx = map.get(rem);
      return str.slice(0, idx) + `(${str.slice(idx)})`;
    }
  }
  return str;
}

/** Divide with Decimal*/
export const divideWithDecimal = (value: any, decimal: any) => {
  const decimalBigN = JSBI.BigInt(decimal.toString());
  const convertedDecimal = JSBI.exponentiate(JSBI.BigInt(10), decimalBigN);
  return fromWei(value, String(convertedDecimal));
};

/**CONVERT NUMBER WITH DECIMALS FOR CONTRACT CALL */
export const convertWithDecimal = (value: any, decimal: any) => {
  const decimalBigN = JSBI.BigInt(decimal);
  const convertedDecimal = JSBI.exponentiate(JSBI.BigInt(10), decimalBigN);
  return toWei(String(value), String(convertedDecimal));
};

export const slicedValue = (value: any) => {
  if (value?.includes(".")) {
    return value.slice(0, value?.indexOf("."));
  } else {
    return value;
  }
};

/** Multiply with big numbers */
export const multiplyTwoBigDigits = (valueOne: any, valueTwo: any) => {
  const a = JSBI.BigInt(valueOne);
  const b = JSBI.BigInt(valueTwo);
  const result = JSBI.multiply(a, b);
  return String(result);
};

export const localeStringFunction = (value: any) => {
  return value.toLocaleString("fullwide", {
    useGrouping: !1,
  });
};

export const multiplyBigDigitsWithDecimals = (
  valueOne: string,
  valueTwo: string
) => {
  let a: any;
  let b: any;
  let decimalLengthA: any = 0;
  let decimalLengthB: any = 0;
  if (valueOne.includes(".")) {
    a = convertWithDecimal(valueOne, valueOne.split(".")[1].length);
    decimalLengthA = valueOne.split(".")[1].length;
  } else {
    a = valueOne;
  }
  if (valueTwo.includes(".")) {
    b = convertWithDecimal(valueTwo, valueTwo.split(".")[1].length);
    decimalLengthB = valueTwo.split(".")[1].length;
  } else {
    b = valueTwo;
  }
  let decimalLength = decimalLengthA + decimalLengthB;
  let result = multiplyTwoBigDigits(a, b);

  if (
    result.substring(0, result.length - decimalLength).length &&
    result.substring(result.length - decimalLength).length
  ) {
    result =
      result.substring(0, result.length - decimalLength) +
      "." +
      result.substring(result.length - decimalLength);
  } else if (!result.substring(0, result.length - decimalLength).length) {
    // eslint-disable-next-line
    result = "0" + "." + result.substring(result.length - decimalLength);
  }
  return result;
};

/**CHECK STRING IS NUMBER */
export const isInt = (n) => {
  return n % 1 === 0;
};

/**REMOVE e FORM BIG NUMBER */
export const toFixed = (x) => {
  if (Math.abs(x) < 1.0) {
    var e = parseInt(x.toString().split("e-")[1]);
    if (e) {
      x *= Math.pow(10, e - 1);
      x = "0." + new Array(e).join("0") + x.toString().substring(2);
    }
  } else {
    e = parseInt(x.toString().split("+")[1]);
    if (e > 20) {
      e -= 20;
      x /= Math.pow(10, e);
      x += new Array(e + 1).join("0");
    }
  }
  return x;
};

/**GET ERROR MESSAGE FORM ERROR OBJECT */
export const getError = (error: any) => {
  let errorMsg =
    error && error.message ? error.message : "Something went wrong";
  if (errorMsg.indexOf("execution reverted") > -1) {
    let msg = errorMsg;
    msg = msg =
      msg.indexOf("execution reverted:") > -1
        ? msg.split("execution reverted:")[1].split("{")[0]
        : msg;
    return msg;
  } else if (errorMsg.indexOf("INVALID_ARGUMENT") > -1) {
    return errorMsg.split("(")[0];
  } else if (errorMsg.indexOf("MetaMask Tx Signature") > -1) {
    let msg = errorMsg.replace("MetaMask Tx Signature:", "");
    return msg;
  } else {
    let err = errorMsg.split("*")[0].split(":")[1];
    if (err?.trim() === "insufficient funds for gas") {
      return err;
    } else {
      return errorMsg;
    }
  }
};

/**CREATE URL FOR API CALL WITH PARAMS */
export const formatUrl = (url, params) => {
  params =
    params && Object.keys(params).length > 0
      ? `?${new URLSearchParams(params).toString()}`
      : ``;
  return `${url}${params}`;
};

/**CREATE URL FOR ADMIN API CALL WITH PARAMS */
export const formatAdminUrl = (url, params) => {
  params =
    params && params.length > 0
      ? `/${params.join('/')}`
      : '';
  return `${url}${params}`;
};

/**ALLOW ONLY STRING */
export const allowOnlyString = (inputString) => {
  let res = /^[a-zA-Z]+$/.test(inputString);
  return res;
};

export const tofixFunctionSliced = (value: any) => {
  //let newValue = value?.toString();

  let newValue = value?.toString().includes("e-") ? parseFloat(value).toFixed(18).toString() : value?.toString();

  newValue = newValue?.includes(".")
    ? newValue.slice(0, newValue?.indexOf(".") + 18)
    : newValue;

  return dynamicToDecimal(newValue, 6);
};

export const dynamicToDecimal = (value: any, decimal: any) => {
  
  // Format Number to 6 Digits
  const numStr = value.toString();
          
  // Find the position of the decimal point
  const decimalIndex = numStr.indexOf('.');
      
  // Iterate through the string after the decimal point
  let position = 1;
  for (let i = decimalIndex + 1; i < numStr.length; i++) {
    if (numStr[i] !== '0') {
      position = i - decimalIndex;
      break; // Exit the loop once first non-zero digit is found
    }
  }

  value = Number(value).toFixed(position + decimal - 1)

  return value.toString();
};

/**COUNT THE NUMBER OF DECIMALS */
export const countDecimals = (number) => {
  const numStr = number.toString();
  const decimalPos = numStr.indexOf('.');
  if (decimalPos === -1) {
      return 0;
  }
  return numStr.length - decimalPos - 1;
}

/**COUNT THE NUMBER OF DECIMALS AFTER THE FIRST NON-ZERO */
export const CountDecimalDigits = (number) => {
  
  const numberStr = number.toString();
  const decimalIndex = numberStr.indexOf('.');

  // If there's no decimal point, return 0
  if (decimalIndex === -1) {
      return 0;
  }

  // Start counting digits after the decimal point
  let count = 0;
  for (let i = decimalIndex + 1; i < numberStr.length; i++) {
      if (numberStr[i] === '0') {
          count++;
      } else {
          break;
      }
  }

  return numberStr.length - decimalIndex - count - 1;
}

/**SHOW VALUE WITH ONLY SELECTED DECIMALS */
export const fixedToDecimal = (value, decimals = 4) => {
  value =
    value && parseFloat(value) > 0
      ? decimals === 2
        ? value.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
        : value.toString().match(/^-?\d+(?:\.\d{0,4})?/)[0]
      : 0;
  return value;
};

export const cryptoDecimals = (inValue: any) => {
  let value = Number(inValue);

  if (value == 0) {
    return 0.0;
  } else if ((value > 0 && value <= 9) || (value < 0 && value >= -9)) {
    return toCustomFixed(value, 5);
  } else if ((value > 9 && value <= 99) || (value < -9 && value >= -99)) {
    return toCustomFixed(value, 4);
  } else if ((value > 99 && value <= 999) || (value < -99 && value >= -999)) {
    return toCustomFixed(value, 3);
  } else if (
    (value > 999 && value <= 9999) ||
    (value < -999 && value >= -9999)
  ) {
    return toCustomFixed(value, 2);
  } else if (value > 9999 || value < -9999) {
    return toCustomFixed(value, 0);
  }
};

export const toCustomFixed = (num: any, fixed: number) => {
  const re = new RegExp("^-?\\d+(?:.\\d{0," + (fixed || -1) + "})?");
  return num.toString().match(re)[0];
};

export const cryptoRoundedDecimals = (inValue: any) => {
  let value = Number(inValue);
  // if (isNaN(value)) {
  //   console.error(`Invalid number: ${inValue}`);
  //   return 'Error';
  // }
  if (value == 0) {
    return 0.0;
  } else if ((value > 0 && value <= 9) || (value < 0 && value >= -9)) {
    return toCustomRoundedFixed(value, 5);
  } else if ((value > 9 && value <= 99) || (value < -9 && value >= -99)) {
    return toCustomRoundedFixed(value, 4);
  } else if ((value > 99 && value <= 999) || (value < -99 && value >= -999)) {
    return toCustomRoundedFixed(value, 3);
  } else if (
    (value > 999 && value <= 9999) ||
    (value < -999 && value >= -9999)
  ) {
    return toCustomRoundedFixed(value, 2);
  } else if (value > 9999 || value < -9999) {
    return toCustomRoundedFixed(value, 0);
  }
};

export const toCustomRoundedFixed = (num: any, fixed: number) => {
  const roundedValue = (Math.round(num * Math.pow(10, fixed)) / Math.pow(10, fixed)).toFixed(fixed);
  return Number(roundedValue).toString(); // Convert to string to ensure the result retains trailing zeros if needed
};

export const makeid = (length: any) => {
  let result = "";
  let characters = "0123456789";
  let charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const errorHelperContract = async (
  error: any,
  errorType: any,
  functionName: any
) => {
  if (errorType == "call") {
    let msg = error.message.split(",")[1];
    // toastMsg.error(error?.message?.includes("MetaMask") ? error?.message : msg);
    return;
  } else {
    console.log(`${functionName} : error`, error);
    // if (error.message?.includes("MetaMask")) {
    // sendTransactionLogsData("error", functionName, res?.transactionHash);
    // }

    console.log("msg", error.message);
    let msg = error.message.split(",")[1];
    // toastMsg.error(error?.message?.includes("MetaMask") ? error?.message : msg);
    return;
  }
};

export const toWeiConvert = (amount: any) => {
  return Web3.utils.toWei(amount, "ether");
};

export const fromWeiConvert = (amount: any) => {
  return Web3.utils.fromWei(amount, "ether");
};



/** ADD MAGNITUDE SUFFIX FOR BIG NUMBERS */
export function formatNumberWithMagnitudeSuffix(number) {
  number = parseFloat(number); // Convert to number if it's a string

  try {
    if (number >= 0) {

        const magnitude = Math.floor(Math.log10(Math.abs(number)) / 3);

        if (magnitude < -6) { // if number exceed e-18, it returned N/A
            return "0";
        } else if (magnitude >= -5 && magnitude <= -3) { // returns number in e-xx format
          const exponentialNotation = number.toExponential();
          const [coefficient, exponent] = exponentialNotation.split('e');
          const roundedCoefficient = parseFloat(coefficient).toFixed(2);
          return `${roundedCoefficient}e${exponent}`;
        } else if (magnitude >= -2 && magnitude < 0) { // 
          return number.toFixed(-magnitude * 3 + 1)
        } else if (magnitude >= 0 && magnitude <= 1){ // returns number with 2 decimals
          return number.toFixed(2);
        } else if (magnitude >= 2 && magnitude <= 3) { // returns number with a suffix and 2 decimals
          const suffixes = ["M", "B"]; 
          let formattedNumber = number / Math.pow(10, magnitude * 3);
          return formattedNumber.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + suffixes[magnitude - 2];
        } else {
            let formattedNumber = number / Math.pow(10, 12);
            return formattedNumber.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "T";
        }

    } else {
        return "0.000000";
    }
  } catch (error) {
    return "N/A";
  } 
}


export const validateInput = (e: any, field: string, decimalToken1: string, decimalToken2: string): string | null => {
  const maxLength: number = 26;

  if (e.length == 0) return null;

  const regexHere = field === "field1" ? new RegExp(`^(\\d+)?([.]?\\d{0,${decimalToken1}})?$`) : new RegExp(`^(\\d+)?([.]?\\d{0,${decimalToken2}})?$`);
  let cleanedInput = e.toString().replace(/,/g, '.');
  let isInputValid = regexHere.test(cleanedInput);

  if (!isInputValid) {
    cleanedInput = cleanedInput.slice(0, -1);
    isInputValid = regexHere.test(cleanedInput);
  }

  if (!isInputValid || cleanedInput.length > maxLength) return null;
  let values = cleanedInput;
  if (values?.length > 1 && Array.from(values)[0] === "0" && Array.from(values)[1] !== ".") values = values?.slice(1);
  if (values?.toString().charAt(0) === ".") values = "0" + values;
  return values;
};

export const dynamicDisplay = (value: any, decimal: any) => {

  console.log("value: " + value)
  if (parseFloat(value) == 0 ) {
    return "";
  } else {
    // Format Number to 6 Digits
    const numStr = value.toString();
          
    // Find the position of the decimal point
    const decimalIndex = numStr.indexOf('.');
      
    // Iterate through the string after the decimal point
    let position = 1;
    for (let i = decimalIndex + 1; i < numStr.length; i++) {
      if (numStr[i] !== '0') {
        position = i - decimalIndex;
        break; // Exit the loop once first non-zero digit is found
      }
    }
      
    value = Number(value).toFixed(position + decimal - 1)

    return value;
  }
};

/**
 * This will copy the entered data string into the clipboard
 * 
 * @param text Entered data string
 */
export const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text);
};

/**
 * Format the date into system default format
 * 
 * @param text Date in string format
 */
export const formatDate = (date: string) => {
  try {
    return moment(date).format("yyyy-MM-DD");  
  } catch (error) {
    return date;
  }
  
}

/**
 * Format the datetime into system default format
 * 
 * @param text Datetime in string format
 */
export const formatDateTime = (date: string) => { 
  try {
    return moment(date).format("yyyy-MM-DD hh:mm A");
  } catch (error) {
    return date;
  }
}
