import { commonDynamicSwapTokens } from "../../interfaces/commonInterfaces";
import LibfiService from "../libfiService";
import { handlePendingApproval, handleErrorNoRetryButton, handleSuccess, handleSuccessNoTxHash } from "./TransactionModalHelper";
import { addBreadcrumb } from './../../SentryContext';


export const newtokensAllowanceHelperFunction = async (setModalData, inputdata: commonDynamicSwapTokens) => {

    const {
        tokenDetails,
        input1,
        input2,
        walletAddress,
        selectedField,
        t
    } = inputdata;

    try {

        addBreadcrumb('AllowanceHelper', '[tokensAllowanceHelper] Input Data', 'info', { inputdata: inputdata })
        if ( !tokenDetails || !input1 || !input2|| !walletAddress || !selectedField ) throw new Error("Input Validation Failed...");
        addBreadcrumb('AllowanceHelper', '[tokensAllowanceHelper] Input Validation Successful', 'info');

        const tokenAddresses: { tokenName, tokenAddress; amount; }[] = [];

        if (tokenDetails.isToken0Native) {
            addBreadcrumb('AllowanceHelper', '[tokensAllowanceHelper] Allowance Not Required', 'info')
            return true;
        } else if (tokenDetails.isToken1Native) {
            tokenAddresses.push({ tokenName: tokenDetails.token0Name, tokenAddress: tokenDetails.token0Address, amount: input1.inputValue });
        } else {
            tokenAddresses.push({ tokenName: tokenDetails.token0Name, tokenAddress: tokenDetails.token0Address, amount: input1.inputValue }, 
                                    { tokenName: tokenDetails.token1Name, tokenAddress: tokenDetails.token1Address, amount: input2.inputValue });
        }

        /**
        * Iterate through tokenAddresses to get token allowances and get approvals if need be
        **/ 
        for (const tokenAddress of tokenAddresses) {

            const getTokenAllowance = await LibfiService.getTokenAllowance(walletAddress, tokenAddress.tokenAddress);
            
            if (Number(tokenAddress.amount) > Number(getTokenAllowance) ) {

                handlePendingApproval(setModalData, t('ApprovalHeadingText'), 
                    t('tradeMarketSwapApprovalConfirmText').replace('{{token0}}', tokenAddress.tokenName || ""))
               
                const getTokenApproval = await LibfiService.getTokenApproval(tokenAddress.tokenAddress);  
                
                addBreadcrumb('AllowanceHelper', '[tokensAllowanceHelper] Allowance Provided for ' + tokenAddress.tokenName, 'info');

                handleSuccess(setModalData, 'ApprovalHeadingText', 
                    t('tradeMarketSwapApprovalSuccessText1').replace('{{token}}', tokenAddress.tokenName || ""), "SUCCESS");

            } else {
                addBreadcrumb('AllowanceHelper', '[tokensAllowanceHelper] Sufficient Allowance for ' + tokenAddress.tokenName, 'info');
            }
        }

        return true;

    } catch (error) {
        handleErrorNoRetryButton(setModalData, 'ApprovalHeadingText', t('ApprovalFailText'));
        addBreadcrumb('[AllowanceHelper]', '[tokensAllowanceHelper] Error Occured', 'info',  { errorMessage: error });
        throw new Error(error instanceof Error ? error.message : 'Unknown Error Occurred');
    }
};


export const LPAllowanceHelper = async (setModalData, inputdata) => {
    try {

        const {
            pairAddress,
            walletAddress,
            userLPTokenstoRemove,
            t
        } = inputdata;

        addBreadcrumb('AllowanceHelper', '[LPAllowanceHelper] Input Data', 'info', { inputdata: inputdata })

        if ( !pairAddress || !userLPTokenstoRemove ) throw new Error("Input Validation Failed...");

        const getLPAllowance = await LibfiService.getLPAllowance(walletAddress, pairAddress);

        if (Number(userLPTokenstoRemove) > Number(getLPAllowance)) {

            handlePendingApproval(setModalData, t('ApprovalHeadingText'), t('ApprovalPendingText'))

            const getLPApproval = await LibfiService.getLPApproval(pairAddress);

            addBreadcrumb('AllowanceHelper', '[LPAllowanceHelper] LP Allowance Provided', 'info');

            handleSuccess(setModalData, 'ApprovalHeadingText', t('ApprovalSuccessText'), "SUCCESS");
            
        } else {
            addBreadcrumb('AllowanceHelper', '[LPAllowanceHelper] Sufficient LP Allowance', 'info');
        }

        return true;
    
    } catch (error) {
        handleErrorNoRetryButton(setModalData, 'ApprovalHeadingText', inputdata.t('ApprovalFailText'));
        addBreadcrumb('AllowanceHelper', '[LPAllowanceHelper] Error Occured', 'info',  { errorMessage: error });
        throw new Error(error instanceof Error ? error.message : 'Unknown Error Occurred');
    }
};