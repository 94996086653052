import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  walletAddress: "",
  removedWalletAddress: "",
  walletType: "Metamask",
  isEmbeddedWallet: false,
  userDetails: {},
  poolData: [],
  network: "",
  userOnboardingData: {},
  getRecentTrade: false,
  placedOrderSuccess: false,

  subscribed: false,
  tab: "Market",
  TransactionDeadline: 10,
  slippage: 1,
  pairChange: "",
  NetworkChainId: 356256156,
  isTokensSwitched: false,
  lock: "false",
};

/**USER DETAILS SLICE */
export const UserSlice = createSlice({
  name: "user",
  initialState,

  reducers: {
    userDetails: (state, param) => {
      const { payload } = param;
      state.userDetails = payload;
    },
    walletAddress: (state, param) => {
      const { payload } = param;
      state.walletAddress = payload;
    },
    removedWalletAddress: (state, param) => {
      const { payload } = param;
      state.removedWalletAddress = payload;
    },
    walletType: (state, param) => {
      const { payload } = param;
      state.walletType = payload;
    },
    isEmbeddedWallet: (state, param) => {
      const { payload } = param;
      state.isEmbeddedWallet = payload;
    },
    setUserOnboardingData: (state, param) => {
      const { payload } = param;
      state.userOnboardingData = payload;
    },
    setTokenSwitched: (state, param) => {
      const { payload } = param;
      state.isTokensSwitched = payload;
    },
    setGlobalPoolData: (state, param) => {
      const { payload } = param;
      state.poolData = payload;
    },
    setUserSubscribed: (state, param) => {
      const { payload } = param;
      state.subscribed = payload;
    },
    setUserPersonalisedLock: (state, param) => {
      const { payload } = param;
      state.lock = payload;
    },
    fetchRecentTrade: (state, param) => {
      const { payload } = param;
      state.getRecentTrade = payload;
    },
    setOrderPlaced: (state, param) => {
      const { payload } = param;
      state.placedOrderSuccess = payload;
    },

    logoutUser: (state) => {
      state.walletAddress = "";
      state.walletType = "";
      state.isEmbeddedWallet = false;
    },
    saveTabs: (state, param) => {
      state.tab = param.payload;
    },
    saveTransactionDeadline: (state, param) => {
      state.TransactionDeadline = param.payload;
    },
    saveSlippage: (state, param) => {
      state.slippage = param.payload;
    },
    updatePairChange: (state, param) => {
      state.pairChange = param.payload;
    },
    saveNetworkChainId: (state, param) => {
      state.NetworkChainId = param.payload;
    },
    reset: () => initialState,
  },
});

/**ACTIONS FOR SLICE*/
export const {
  userDetails,
  removedWalletAddress,
  walletAddress,
  walletType,
  isEmbeddedWallet,
  logoutUser,
  reset,
  setUserSubscribed,
  setGlobalPoolData,
  fetchRecentTrade,
  setUserOnboardingData,
  saveTabs,
  saveTransactionDeadline,
  saveSlippage,
  updatePairChange,
  saveNetworkChainId,
  setTokenSwitched,
  setUserPersonalisedLock,
  setOrderPlaced,
} = UserSlice.actions;
