import React, { useEffect, useMemo, useState } from "react";
import {
  CloseIcon,
  DownArrowCircle,
  QuestionMarkBlack,
  SettingsIcon,
  SwapIcon,
  WalletIcon,
  InfoIcon,
  InfiniteSpinner,
} from "../../../../Assets/Images/Icons/SvgIcons";
import { toast, Toaster } from "react-hot-toast";
import ShariahIcon from '../../../../Assets/Images/Icons/shariah.png'
import { Modal } from "react-bootstrap";
import Tooltip from "../../Tooltip/Tooltip";
import ButtonCustom from "../../Button/ButtonCustom";
import InputCustom from "../../FormInputs/Input/Input";
import { Link } from "react-router-dom";
import Slider from "rc-slider";
import {useFetchTokenBalance} from "../../../../LibfiServices/Hooks/usefetchTokenBalance";
import { useDispatch } from "react-redux";
import {
  cryptoDecimals,
  divideWithDecimal,
  dynamicToDecimal,
  toCustomFixed,
  toFixed,
  formatNumberWithMagnitudeSuffix,
  validateInput, 
} from "../../../../Services/common.service";
import { getReservesHelper, addLiquidityHelperFunction } from "../../../../LibfiServices/Helpers/EarnAMMHelper";
import { EXPLORER_LINK, hundred } from "../../../../Utils";
import { executeRemoveLiquidity } from "../../../../LibfiServices/Helpers/RedeemAMMHelper";
import TransactionModal from "../TransactionDone/TransactionModal";
import SettingModal from "../SettingModal/SettingModal";
import { TOKEN_DETAILS } from "../../../../interfaces/commonInterfaces";
import { callApiPostMethod } from "../../../../Redux/Actions/api.action";
import { POOLS, PORTFOLIO_GRAPH } from "../../../../Redux/Actions/apiResponseInterfaces";
import { useTranslation } from "react-i18next";
import LiquidityPoolGraph from "../../../Pages/graph/LiquidityPoolGraph";
import { useDynamicContext } from "@dynamic-labs/sdk-react-core";
import { addBreadcrumb } from './../../../../SentryContext';
import "./EarnModal.scss";



type EarnModal = {
  show: boolean;
  handleClose: Function;
  showTab: string;
  handlePools: Function;
  showSettingsModal: Function;
  selectedPool: POOLS["data"][1];
  poolData?: any;
  isRefreshing?: boolean;
};

const EarnModal = (props: EarnModal) => {

  // State variables
  const dispatch: any = useDispatch();
  const { primaryWallet } = useDynamicContext();
  const [show, setShow] = useState<boolean>(false);
  const [showConnect, setShowConnect] = useState<boolean>(false);
  const [settingModal, setSettingModal] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<boolean>();
  const [loader, setLoader] = useState<boolean>(false);
  const pool: POOLS["data"][1] = props?.selectedPool;
  const [graphData, setGraphData] = useState<PORTFOLIO_GRAPH["data"]>([]);
  const [withdrawValue, setWithdrawValue] = useState<any>(0);
  const [estimatedPoolShare, setEstimatedPoolShare] = useState(0)
  const [insufficientLp, setinsufficientLp] = useState<boolean>(false);
  const [maxValueCheck, setmaxValueCheck] = useState<boolean>(false);
  const [inputOne, setinputOne] = useState<{ convertedValue: bigint; inputValue: string; }>({ convertedValue: BigInt(0), inputValue: "" });
  const [inputTwo, setinputTwo] = useState<{ convertedValue: bigint; inputValue: string; }>({ convertedValue: BigInt(0), inputValue: "" });
  const [modalData, setModalData] = useState<{ heading: string; bodyText: string; hideCloseButton?: boolean; hideRetryButton?: boolean; status: string; txHash: string | null; }>({ heading: "", bodyText: "", hideCloseButton: false,  hideRetryButton: false, status: "", txHash: "", });
  const [estimatedMinimumReceiveTokens, setEstimatedMinimumReceiveTokens] = useState<{ token0Receive: number; token1Receive: number; }>({ token0Receive: 0, token1Receive: 0 });
  const [isLeftTokenInRatioToken0Name, setIsLeftTokenInRatioToken0Name] = useState(true);
  const [fetchingInputFields, setFetchingInputFields] = useState({ isLoadingInputField1: false, isLoadingInputField2: false, });
  const [recentFieldInput, setRecentFieldInput] = useState("");
  const [swapBtnEffect, setSwapBtnEffect] = useState(false);
  const { t } = useTranslation();


  // Hooks

  /**
  * This is a memoized function to get details of both tokens of the selected pool
  **/
  const tokenDetails: TOKEN_DETAILS = useMemo(() => {
    const token0: string = pool?.token0Address;
    const token1: string = pool?.token1Address;
    return {
      token0Address: token0,
      token1Address: token1,
      token0Name: pool?.token0Name,
      token1Name: pool?.token1Name,
      isToken0Native: token0 == "0x22a4c090bd7744aa94be47fe368240de5c000920" ? true : false,
      isToken1Native: token1 == "0x22a4c090bd7744aa94be47fe368240de5c000920" ? true : false,
    };
  }, [pool]);
  
  /**
  * This is a custom hook fetching the balance from the user's wallet
  **/
  const { tokenBalance, fetchData, fetchingBalance } = useFetchTokenBalance({
    dispatch,
    tokenDetails,
  });

  /**
  * This useEffect hook retrieves the token balances and calculates 
  * the input field value if any when the selected pool data is refreshed
  **/
  useEffect(() => {
    fetchData();
    getGraphData(); 

    if ( recentFieldInput == "field1" && Number(inputOne.inputValue) !== 0 ) {
      handleInputs(inputOne.inputValue, "field1", false);

      setFetchingInputFields({
        isLoadingInputField1: false,
        isLoadingInputField2: true,
      });

    } else if ( recentFieldInput == "field2" && Number(inputTwo.inputValue) !== 0 ) {
      handleInputs(inputTwo.inputValue, "field2", false);

      setFetchingInputFields({
        isLoadingInputField1: true,
        isLoadingInputField2: false,
      });

    }
  }, [pool]);

  /**
  * This useEffect hook checks whether the user has a LP balance or not
  **/
  useEffect(() => {
    pool.userLPBalance == 0 ? setinsufficientLp(true) : setinsufficientLp(false);
  }, [pool]);

  /**
  * This useEffect hook dynamically 
  * - Determines the amount of tokens to withdraw based on the withdrawal slider
  * - calculates the estimated pool share based on the withdrawal slider
  **/
  useEffect(() => {
    if (primaryWallet?.connected) {
      estimateTokenChangeOnWithdrawal();
      setEstimatedPoolShare(calculateEstimatedPoolShare(pool.totalLPAmount))
    }
  }, [pool, withdrawValue]);

  /**
  * This useEffect hook enables tracking of the active modal tab
  **/
  useEffect(() => {
    setActiveTab(props?.showTab == "earn" ? true : false);
  }, [props?.show, props?.showTab]);


  // Functions

  /**
  * This function clears the input boxes
  **/
  const emptyValues = async () => {
    setinputOne({
      convertedValue: BigInt(0),
      inputValue: "",
    });
    setinputTwo({
      convertedValue: BigInt(0),
      inputValue: "",
    });
  };

  /**
  * This function sets the show state to true
  **/
  const handleOpen = () => setShow(true);

  /**
  * This function resets and recalculates all values when a Transaction occurred
  **/
  const resetSelectPool = async () => {
    setWithdrawValue(0);
    emptyValues();
    props?.handlePools(); // Refresh All The Pools Data
  };

  /**
  * This function computes the estimated pool share of the user, displayed in the 'Redeem' tab
  */
  const calculateEstimatedPoolShare = (item) => {
    const estimatedPoolShare = Number(pool.userLPBalance) * (1 - withdrawValue / 100 ) * 100 / Number(item);
    return estimatedPoolShare;
  };

  /**
  * This function draws a graph showing the TVL and the volume of the selected pool for the last 30 days
  * NB: The graph does not refresh, in contrast to the other fields in the modal
  **/
  const getGraphData = async () => {
    if (props.show && (!graphData || graphData.length === 0 || graphData[0].pair0In1Out !== pool?.token0Name + "/" + pool?.token1Name)) {
      setLoader(true);
      const result: PORTFOLIO_GRAPH = await dispatch(
        callApiPostMethod(
          "GET_GRAPH",
          { pairAddress: pool?.pairAddress },
          false,
          false
        )
      );
      setGraphData(result?.data);
      setLoader(false);
    }
  };

  /**
  * This function checks whether the user has a LP balance or not 
  **/
  const estimateTokenChangeOnWithdrawal = async () => {

    const token0Receive: number =  pool.userToken0Balance * ( withdrawValue / hundred );
    const token1Receive: number = pool.userToken1Balance * ( withdrawValue / hundred );
    setEstimatedMinimumReceiveTokens({token0Receive: token0Receive, token1Receive: token1Receive})

  };

  /**
  * This function is called when user clicks on the 'Maximize Deposit' button
  **/
  const addMaxLiquidity = async () => {
    if (!primaryWallet?.connected) return;
    
    if ( Number(tokenBalance?.token1Balance) * pool.reserve1 / pool.reserve0 <= tokenBalance?.token2Balance ) {

      setFetchingInputFields({
        isLoadingInputField1: false,
        isLoadingInputField2: true,
      });

      handleMaximumFunction("field1");

    } else {

      setFetchingInputFields({
        isLoadingInputField1: true,
        isLoadingInputField2: false,
      });

      handleMaximumFunction("field2");
      
    }
  };

  /**
  * This function handles input fields when the user clicks on the 'max' button
  * @param data : Input field where the user has clicked the 'max' button
  **/
  const handleMaximumFunction = async (data: any) => {
    setmaxValueCheck(true);
  
    if (data == "field1" && tokenBalance?.token1BalanceConverted > 0) {
      if (tokenDetails?.isToken0Native) {

        // Deduct 0.001 as estimated gas fees for native currency
        const newBalance = toFixed(
          Number(tokenBalance?.token1BalanceConverted) - 10000000000000000
        ); 
        
        handleInputs(newBalance > 0 ? newBalance : 0, data, true);

      } else {

        handleInputs(tokenBalance?.token1BalanceConverted, data, true);

      }
    } else if (data == "field2" && tokenBalance?.token2BalanceConverted > 0) {
      if (tokenDetails?.isToken1Native) {

        // Deduct 0.001 as estimated gas fees for native currency
        const newBalance = toFixed(
          Number(tokenBalance?.token2BalanceConverted) - 10000000000000000
        ); 

        handleInputs(newBalance > 0 ? newBalance : 0, data, true);

      } else {

        handleInputs(tokenBalance?.token2BalanceConverted, data, true);
        
      }
    }
  };

  /**
  * This function handles both input fields
  * @param e : Value that the user has entered
  * @param field : Field in which user has entered the value
  * @param max : Boolean value indicating  whether user has clicked on the 'max' button or not
  **/
  const handleInputs = async (e: any, field: string, max: boolean) => {
    try {

      const inputValue = validateInput(e, field, pool.decimals0.toString(), pool.decimals1.toString());

      let fieldCondition = field == "field1" ? true : false;

      if (!inputValue && !inputValue?.toString().includes(".")) {

        setmaxValueCheck(false);
        setinputOne({
          convertedValue: BigInt(0),
          inputValue: "",
        });
        setinputTwo({
          convertedValue: BigInt(0),
          inputValue: "",
        });
        setTimeout(() => {
          emptyValues();
        }, 100);

      } else {

        setRecentFieldInput(field);
        setSwapBtnEffect(true);
        
        const decimals = fieldCondition
                        ? pool.decimals0
                        : pool.decimals1;
        
        let convertedValue: bigint = max
          ? BigInt(inputValue)
          : BigInt( (Number(inputValue) * 10 ** decimals).toLocaleString("fullwide", { useGrouping: !1 }) );       

        let originalValue: string = max
          ? cryptoDecimals(Number(inputValue) / 10 ** decimals).toLocaleString("fullwide", { useGrouping: false }) 
          : inputValue;

        fieldCondition
          ? setinputOne({
              convertedValue: convertedValue,
              inputValue: originalValue,
            })
          : setinputTwo({
              convertedValue: convertedValue,
              inputValue: originalValue,
            });

        // Manage the loading effect for the other input field
        setFetchingInputFields(prevState => ({
          ...prevState,
          ...(fieldCondition ? { isLoadingInputField1: false } : { isLoadingInputField2: false })
        }));
        
        // Notify the user that a portion of the maximum token amount has been deducted for gas fees
        if (max && ( fieldCondition ? tokenDetails?.isToken0Native : tokenDetails?.isToken1Native ) ) {
          toast.success((t('earnModelGasFeesDeduction')).replace('{{tokenName}}', tokenDetails?.isToken0Native ? tokenDetails?.token0Name+"" : tokenDetails?.token1Name+"" ), {
              id: "GasFeesRemoval",
              duration: 3000,
          });
        }

        const data = {
          pairAddress: pool.pairAddress,
          tokenOneAddress: tokenDetails?.token0Address,
          decimals0: pool.decimals0,
          tokenTwoAddress: tokenDetails?.token1Address,
          decimals1: pool.decimals1,
          input1: fieldCondition ? Number(inputValue) : 0,
          input2: fieldCondition ? 0 : Number(inputValue),
          selectedField: fieldCondition ? "field1" : "field2",
          max: max,
        };

        const reserveData: any = await getReservesHelper(data);
        
        let calculatedBalance = divideWithDecimal(reserveData, fieldCondition ? pool.decimals1 : pool.decimals0).toString();
        calculatedBalance = calculatedBalance?.includes("e-") ? parseFloat(calculatedBalance).toFixed(Number(decimals)).toString() : calculatedBalance?.toString();

        if (Number(calculatedBalance) === 0) {
          fieldCondition
            ? setinputTwo({
                convertedValue: reserveData,
                inputValue: "",
              })
            : setinputOne({
                convertedValue: reserveData,
                inputValue: "",
              });
        } else if (Number(calculatedBalance)) {
          fieldCondition
            ? setinputTwo({
                convertedValue: reserveData,
                inputValue: dynamicToDecimal(calculatedBalance, 6),
              })
            : setinputOne({
                convertedValue: reserveData,
                inputValue: dynamicToDecimal(calculatedBalance, 6),
              });
        }

        setSwapBtnEffect(false);

        setFetchingInputFields(prevState => ({
          ...prevState,
          ...(!fieldCondition ? { isLoadingInputField1: false } : { isLoadingInputField2: false })
        }));

      }
    } catch (error) {
      console.error("Error in handleInputs:", error);
      console.error("Error handling input:", error);
    }
  };

  /**
  * This Function Manages the Addition of Liquidity from the Selected Pool
  **/
  const handleAddLiquidity = async () => {
    try {
      
      if (!primaryWallet?.connected) return;

      addBreadcrumb('Add Liquidity', 'Add Liquidity Button Clicked', 'info')

      const addLiquidityResult: boolean | undefined =
        await addLiquidityHelperFunction(
          tokenDetails,
          inputOne,
          inputTwo,
          primaryWallet.address,
          handleOpen,
          setModalData,
          t
        );

        resetSelectPool();

    } catch (error) {

      //console.log("Liquidity Addition Failed...")

    }
  };

  /**
  * This Function Manages the Removal of Liquidity from the Selected Pool
  **/
  const withdrawLiquidity = async () => {
    try {

      if (!primaryWallet?.connected) return;

      addBreadcrumb('LiquidityRemoval', '[LiquidityRemoval] Button Clicked', 'info')
      addBreadcrumb('LiquidityRemoval', '[LiquidityRemoval] Process Started - ' + tokenDetails?.token0Name + '/' + tokenDetails.token0Name, 'info');

      let userLiquiditytoRemove: any = withdrawValue == 100 ? pool.userLPBalance
                                                   : Number(pool.userLPBalance) * ( withdrawValue / hundred );

      if (userLiquiditytoRemove > 0) {

        const walletAddress = primaryWallet?.address;

        const removeLiquidityResult: boolean | void = await executeRemoveLiquidity({
          pairAddress: pool.pairAddress,
          withdrawValue,
          walletAddress,
          tokenDetails,
          handleOpen,
          setModalData,
          tokenA: tokenDetails.token0Name || "",
          tokenB: tokenDetails.token1Name || "",
          t
        });

        resetSelectPool();
      
      }
      
    } catch (error) {

      //console.log("Liquidity Removal Failed...")
    
    }
  };

  /**
  * This function is called when the user interacts with the slider in the 'Withdraw' tab
  * If the user has no LP token, the slider will be disabled and a toast will popup
  **/
  const SliderDisabledToast = () => {
    if (insufficientLp) toast.error(t('earnModelSliderDisabledError'), { id: "SliderDisabled" })
  };

  /**
  * This function is called when the user clicks on the 'double arrows' button of the token ratio
  * This toggles the state of the variable 'isLeftTokenInRationToken0Name'
  **/
  const handleClick = () => {
    setIsLeftTokenInRatioToken0Name(prevValue => !prevValue);
  };

  /**
  * This function allows displaying the informative toast for Embedded Wallets
  **/
  const displayToast = () => {
    toast.loading(modalData.bodyText, {
      id: "PoolInfoToast",
      style: { fontSize: '16px' }
    })
  };

  /**
  * This function allows hiding the informative toast for Embedded Wallets
  **/
  const dismiss = () => toast.dismiss("PoolInfoToast")

  /**
  * Checks whether the input fields are filled and correctly formatted
  * Returns a boolean value indicating the validity of the form.
  **/
    const isFormValid: boolean | undefined =
    inputOne?.inputValue !== "" &&
    inputTwo?.inputValue !== "" &&
    inputOne?.inputValue?.split(".")[1] !== "" &&
    inputTwo?.inputValue?.split(".")[1] !== "";

  /**
  * Checks if the input values exceed available balances
  * Returns a boolean indicating whether there are sufficient funds
  **/
  const insufficientFunds =
    Number(tokenBalance?.token1BalanceConverted) <
      Number(inputOne?.convertedValue) ||
    Number(tokenBalance?.token2BalanceConverted) <
      Number(inputTwo?.convertedValue) ||
    (maxValueCheck && Number(inputOne?.convertedValue) == 0) ||
    (maxValueCheck && Number(inputTwo?.convertedValue) == 0);

  /**
  * Checks if the user has enough native tokens to pay the estimated gas fees
  * Returns a boolean indicating whether there are sufficient funds for gas fees
  **/
  const insufficientGasFees = () => {
    if (
      (tokenDetails.isToken0Native && 
        Number(tokenBalance?.token1BalanceConverted) - Number(inputOne?.convertedValue) < 10000000000000000 &&
        Number(tokenBalance?.token1BalanceConverted) - Number(inputOne?.convertedValue) >= 0 &&
        Number(tokenBalance?.token2BalanceConverted) > Number(inputTwo?.convertedValue)) ||
      (tokenDetails.isToken1Native && 
        Number(tokenBalance?.token2BalanceConverted) - Number(inputTwo?.convertedValue) < 10000000000000000 &&
        Number(tokenBalance?.token2BalanceConverted) - Number(inputTwo?.convertedValue) >= 0 &&
        Number(tokenBalance?.token1BalanceConverted) > Number(inputOne?.convertedValue))
    ) {
        return true;
    }
    return false;
  };


  return (
    /**
    * pool is used to display "constant" data like pairAddress, token0Address, token1Address, token0Name, token1Name, ... 
    * poolInformation is used to display "dynamic" data like totalLPAmount, dollarValue0, dollarValue1, ...
    **/
    <>
      <Modal
        backdropClassName="earnModalBg"
        centered
        show={props?.show}
        className="earn_modal"
      >
        <button
          className="modal_close_btn"
          onClick={() => {
            emptyValues();
            setWithdrawValue(0);
            props?.handleClose();
          }}
        >
          <CloseIcon />
        </button>
        <Modal.Body>
          <div className="earn_modal_data">
            <div className="d-flex">
              <ButtonCustom
                transparent={!activeTab}
                title={t('earnModelEarnButton')}
                className={activeTab ? "bordered-green" : ""}
                onClick={() => setActiveTab(true)}
              />
              <ButtonCustom
                transparent={activeTab}
                className={!activeTab ? "bordered-green" : ""}
                title={t('earnModelRedeemButton')}
                onClick={() => {
                  setActiveTab(false);
                }}
              />
              <button
                className="settings_btn"
                onClick={() => {
                  setSettingModal(true);
                }}
              >
                <SettingsIcon />
              </button>
              <SettingModal
                show={settingModal}
                handleClose={() => setSettingModal(false)}
              />
            </div>
            {activeTab ? (
              <>
                <div className="earn-details">
                  <div className="amount-details-container">
                    <div className="amount-details-box-container">
                        <InputCustom
                          // shimmer={shimmerState == "field2" ? true : false}
                          className={`${fetchingInputFields.isLoadingInputField1 ? 'greyedout' : ''}`}
                          placeholder={"0.00"}
                          name="value1"
                          leftIcon={
                            <>
                              <img src={pool?.token0URI} alt="" />{" "}
                              {pool?.token0Name}
                            </>
                          }
                          type="text"
                          maxLength={18}
                          onChange={(e: any) => {
                            e.preventDefault();
                            handleInputs(e?.target?.value, "field1", false);

                            setFetchingInputFields({
                              isLoadingInputField1: false,
                              isLoadingInputField2: true,
                            });

                          }}
                          value={ inputOne?.inputValue }
                        />
                      <div className="amount-details-box-details">
                        {/* <p> */}
                        <p className={`${props.isRefreshing ? 'greyedout' : ''}`}>  
                          <span>$</span>
                          <span>{formatNumberWithMagnitudeSuffix(pool.token0DollarValue || 0)}</span>
                        </p>
                        <p>
                          {t('earnModelBalance')}:{" "}
                          {/* <span> */}
                          <span className={`${fetchingBalance ? 'text-shadow' : ''} ${ !fetchingBalance ? 'lineUp' : '' }`}>
                            {formatNumberWithMagnitudeSuffix(tokenBalance?.token1Balance)}
                          </span>{" "}
                        </p>
                      </div>
                    </div>
                    <button className={`amount-details-box-swap-btn ${swapBtnEffect ? 'active' : ''}`}>{/* <PlusI /> */}+</button>
                    <div className="amount-details-box-container">
                      <InputCustom
                        // shimmer={shimmerState == "field1" ? true : false}
                        className={`${fetchingInputFields.isLoadingInputField2 ? 'greyedout' : ''}`}
                        placeholder={"0.00"}
                        name="value1"
                        leftIcon={
                          <>
                            <img src={pool?.token1URI} alt="" />{" "}
                            {pool?.token1Name}
                          </>
                        }
                        type="text"
                        maxLength={18}
                        onChange={(e: any) => {
                          e.preventDefault();
                          handleInputs(e?.target?.value, "field2", false);

                          setFetchingInputFields({
                            isLoadingInputField1: true,
                            isLoadingInputField2: false,
                          });
                        }}
                        value={ inputTwo?.inputValue }
                      />
                      <div className="amount-details-box-details">
                        {/* <p> */}
                        <p className={`${props.isRefreshing ? 'greyedout' : ''}`}>  
                          <span>$</span>
                          <span>{formatNumberWithMagnitudeSuffix(pool.token1DollarValue)}</span>
                        </p>
                        <p>
                          {t('earnModelBalance')}:{" "}
                          {/* <span> */}
                          <span className={`${fetchingBalance ? 'text-shadow' : ''} ${ !fetchingBalance ? 'lineUp' : '' }`}>
                            {formatNumberWithMagnitudeSuffix(tokenBalance?.token2Balance)}
                          </span>{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  {primaryWallet?.connected ? (
                    <ButtonCustom
                      title={t('earnModelMaximizeDeposit')}
                      fluid
                      onClick={() => {
                        addMaxLiquidity();
                      }}
                      disabled={ fetchingBalance || tokenBalance?.token1Balance == 0 || tokenBalance?.token2Balance == 0 }
                    />
                  ) : null}
                  <div className="user-assets-container">
                    <h4 className="user-assets-title">{t('earnModelYourPoolHolding')}</h4>
                    <table className="user-assets-table">
                      <tbody>
                        <tr>
                          <td className={`user-assets-table-column1 ${props.isRefreshing ? 'greyedout' : ''}`}>
                            <p>{(t('earnModelLPTokens')).replace('{{balance}}', formatNumberWithMagnitudeSuffix(pool.userLPBalance))}</p>
                          </td>
                          <td className="user-assets-table-column2">
                            <button className="user-assets-table-down-arrow-btn">
                              <DownArrowCircle />
                            </button>
                          </td>
                          <td>
                            
                          <div className="user-assets-table-tokens-breakdown">
                            <img src={pool?.token0URI} alt="" />
                              {/* <p> */}
                              <p className={`${props.isRefreshing ? 'greyedout' : ''}`}>
                                {formatNumberWithMagnitudeSuffix(pool.userToken0Balance)} {pool?.token0Name}
                              </p>
                          </div>
                          <div className="user-assets-table-tokens-breakdown">
                            <img src={pool?.token1URI} alt="" />
                              {/* <p> */}
                              <p className={`${props.isRefreshing ? 'greyedout' : ''}`}>
                                {formatNumberWithMagnitudeSuffix(pool.userToken1Balance)} {pool?.token1Name}
                              </p>
                          </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <div className="user-assets-pool-share">
                      <p>{t('earnModelPoolShare')}:</p>
                      {/* <p> */}
                      <p className={`${props.isRefreshing ? 'greyedout' : ''}`}>  
                        <span>{`${pool.userShare ? toCustomFixed(pool.userShare, 2) : "0.00"} `} </span>
                        <span>%</span>
                      </p>
                    </div>
                  </div>

                  {primaryWallet?.connected ? (
                    <ButtonCustom
                      title={
                        //walletAddress && !insufficientFunds 
                        //  ? t('earnModelAddLiquidity')
                        //  : t('earnModelInsufficientFund')
                        primaryWallet?.connected && !insufficientFunds && !insufficientGasFees()
                          ? t('earnModelAddLiquidity')
                          : insufficientGasFees()
                            ? (t('earnModelInsufficientGasFees')).replace('{{tokenName}}', tokenDetails.isToken0Native ? pool?.token0Name : pool?.token1Name ) 
                            : t('earnModelInsufficientFund')
                      }
                      fluid
                      onClick={() => handleAddLiquidity()}
                      disabled={!isFormValid || insufficientFunds || insufficientGasFees()}
                    />
                  ) : (
                    <ButtonCustom
                      fluid
                      title={
                        <>
                          <WalletIcon /> {t('connectWallet')}
                        </>
                      }
                      show={showConnect}
                      setShow={setShowConnect}
                      onClick={() => setShowConnect(true)}
                    />
                  )}
                </div>
              </>
            ) : (
              <>
                <div className="redeem-details">
                  <h2>
                    <span>{withdrawValue}</span>
                    <span>%</span>
                  </h2>
                  {/* <p className="lp_text">
                    LP Tokens: {formatNumberWithMagnitudeSuffix(lpTokensToBurn)} / {formatNumberWithMagnitudeSuffix(divideWithDecimal(userLpBalance, 18))}
                  </p> */}

                  <div onClick={SliderDisabledToast}>
                    <Slider
                      max={100}
                      min={0}
                      // dots
                      step={1}
                      onChange={(value: any) => {
                        SliderDisabledToast();
                        setWithdrawValue(value);
                        //setFetchingWithdrawTokensDetails(true);
                        //setFetchingWithdrawEstimatedPoolShare(true);
                        }}
                      disabled={insufficientLp}
                      value={withdrawValue}
                      reverse={document.body.dir === 'rtl'}
                      dotStyle={{
                        width: "8px",
                        height: "8px",
                        border: "1px solid #4169EF",
                        backgroundColor: "#241F47",
                      }}
                      activeDotStyle={{
                        width: "15px",
                        height: "15px",
                        backgroundColor: " #4169EF",
                      }}
                      marks={document.body.dir === "rtl" ?
                        { 0: "%0", 25: "%25", 50: "%50", 75: "%75", 100: "%100" }
                        : { 0: "0%", 25: "25%", 50: "50%", 75: "75%", 100: "100%" }
                      }
                    />
                  </div>

                  <button className="redeem-down-arrow-btn">
                    <DownArrowCircle />
                  </button>
                  <div className="redeem-balance-container">
                    <table className="redeem-balance-table">
                      <tbody>
                        <tr>
                          <td></td>
                          <td><h4>{t('earnModelWithdrawing')}</h4></td>
                          <td><h4>{t('earnModelTotalHeld')}</h4></td>
                        </tr>
                        <tr>
                          <td>
                            <h4 className="token-data">
                              <img className="redeem-balance-table-token-image" src={pool?.token0URI} alt="" />
                                <p className="redeem-balance-table-token">{pool?.token0Name}</p>
                                <sup>
                                  <Tooltip icon={<img className="redeem-balance-table-shariah-image" src={ShariahIcon} />}>
                                    <p>{t('earnModelApprovedBySharishBoard')}</p>
                                  </Tooltip>
                                </sup>
                            </h4>
                          </td>
                          <td>
                            {/* <p> */}
                            <p className={`${props.isRefreshing ? 'greyedout' : ''}`}>
                              {formatNumberWithMagnitudeSuffix(cryptoDecimals(
                                estimatedMinimumReceiveTokens?.token0Receive
                              )) || 0}
                            </p>
                          </td>
                          <td>
                            {/* <p> */}
                            <p className={`${props.isRefreshing ? 'greyedout' : ''}`}>
                              {formatNumberWithMagnitudeSuffix(pool.userToken0Balance)}
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h4 className="token-data">
                              <img className="redeem-balance-table-token-image" src={pool?.token1URI} alt="" />
                              <p className="redeem-balance-table-token">{pool?.token1Name}</p>
                              {/*<Tooltip icon={<QuestionMarkBlack />} />*/}
                            </h4>
                          </td>
                          <td>
                            {/* <p> */}
                            <p className={`${props.isRefreshing ? 'greyedout' : ''}`}>
                              {formatNumberWithMagnitudeSuffix(cryptoDecimals(
                                estimatedMinimumReceiveTokens?.token1Receive
                              )) || 0}
                            </p>
                          </td>
                          <td>
                            {/* <p> */}
                            <p className={`${props.isRefreshing ? 'greyedout' : ''}`}>
                              {formatNumberWithMagnitudeSuffix(pool.userToken1Balance)}
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <p className="hyphen-text"></p>
                  {/*
                    <ul className="slippage_text">
                        <li>
                          <h4>Slippage Tolerance</h4>
                          <p>{slippage} %</p>
                        </li>``
                      <li>
                        <h4>Trading Fee</h4>
                        <p>-</p>
                      </li>
                    </ul>
                  */}
                  <div className="user-assets-pool-share">
                      <p>{t('earnModelEstPoolShare')}:</p>
                      {/* <p> */}
                      <p className={`${props.isRefreshing ? 'greyedout' : ''}`}>  
                        <span>{`${estimatedPoolShare ? toCustomFixed(estimatedPoolShare, 2) : "0.00"} `} </span>
                        <span>%</span>
                      </p>
                  </div>
                  {primaryWallet?.connected ? (
                    <ButtonCustom
                      title={
                        !insufficientLp ? t('earnModelWithdraw') : t('earnModelInsufficientLPTokens')
                      }
                      className="wallet_btn"
                      fluid
                      onClick={() => withdrawLiquidity()}
                      disabled={
                        !withdrawValue || !primaryWallet?.connected || insufficientLp
                      }
                    />
                  ) : (
                    <ButtonCustom
                      title={
                        <>
                              <WalletIcon /> {t('connectWallet')}
                        </>
                      }
                      className="wallet_btn"
                      show={showConnect}
                      fluid
                      setShow={setShowConnect}
                      onClick={() => setShowConnect(true)}
                    />
                  )}
                </div>
              </>
            )}
          </div>
            <>
              <div className="modal-earn-chart">
                <Link
                  to={`${EXPLORER_LINK}address/${pool?.pairAddress}`}
                  target="blank"
                  className="view_link"
                >
                  {t('earnModelViewPoolTrx')}
                </Link>
                <div className="chart_img">
                  {/* <img src={Graphimg} alt="" /> */}
                  {loader ? (
                    // <Shimmer height={272} fluid />
                    <InfiniteSpinner />
                  ) : (
                    <LiquidityPoolGraph data={graphData} />
                  )}
                </div>
                <div className="pool-information-container">
                  <h3 className="pool-information-title">{t('earnModelPoolInformation')}</h3>

                  <div className="pool-information-current-price-container">
                    <div className="pool-information-current-price-title">
                      <p>{t('earnModelCurrentPrice')}</p>
                    </div>
                    {/* <div className="pool-information-current-price"> */}
                    <div className={`pool-information-current-price ${props.isRefreshing ? 'greyedout' : ''}`}>
                        {isLeftTokenInRatioToken0Name ? (
                          <p>
                            <>
                              <span>1</span>
                              <span>{pool?.token0Name}</span>
                              <span>=</span>
                              <span>{formatNumberWithMagnitudeSuffix( pool.reserve1 / pool.reserve0 )}</span>
                              <span>{pool?.token1Name}</span>
                            </>
                          </p>
                        ) : (
                          <p>
                            <>
                              <span>1</span>
                              <span>{pool?.token1Name}</span>
                              <span>=</span>
                              <span>{formatNumberWithMagnitudeSuffix( pool.reserve0 / pool.reserve1 )}</span>
                              <span>{pool?.token0Name}</span>
                            </>
                          </p>
                          )}
                        <button className="swapBtn" onClick={handleClick}>
                            <SwapIcon />
                        </button>
                    </div>
                  </div>

                  <div className="pool-information-trading-fee-container">
                    <div className="pool-information-trading-fee-title">
                      <p>{t('earnModelTradingFee')}</p>
                      <Tooltip icon={<InfoIcon />}>
                        <p>{t('earnModelTradingFeeDescription')}</p>
                     </Tooltip>
                    </div>
                    <div className="pool-information-trading-fee">
                      <p>
                        <span>0.25</span>
                        <span>%</span>
                      </p>
                    </div>
                  </div>

                  <div className="pool-information-table-container">     
                    <div className="pool-information-Token-TVL-container">
                      <div className="pool-information-Token-TVL-row">
                        <div className="pool-information-Token-TVL-row-Token">
                          {/* <span className="green_text">+0.00%</span> */}
                          <div className="circleimg">
                            <img src={pool.token0URI} alt="" />{" "}
                            {/* <h3> */}
                            <h3 className={`${props.isRefreshing ? 'greyedout' : ''}`}>
                              <span>{formatNumberWithMagnitudeSuffix(pool.reserve0)}</span>
                              <span>{pool?.token0Name}</span>
                              <span>({Math.round(pool.token0Ratio * 100) / 100}</span>
                              <span>%)</span>
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div className="pool-information-Token-TVL-row">
                        <div className="pool-information-Token-TVL-row-TVL-Volume">
                          <h3 className="faded">{t('earnModelTVL')}</h3>
                          {/* <p> */}
                          <p className={`${props.isRefreshing ? 'greyedout' : ''}`}> 
                            <span>$</span>
                            <span>{formatNumberWithMagnitudeSuffix(pool.TVL)}</span>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="pool-information-Token-TVL-container">
                      <div className="pool-information-Token-TVL-row">
                        <div className="pool-information-Token-TVL-row-Token">
                          {/* <span className="green_text">+0.00%</span> */}
                          <div className="circleimg">
                            <img src={pool?.token1URI} alt="" />{" "}
                              {/* <h3> */}
                              <h3 className={`${props.isRefreshing ? 'greyedout' : ''}`}>
                              <span>{formatNumberWithMagnitudeSuffix(pool.reserve1)}</span>
                              <span>{pool?.token1Name}</span>
                              <span>({Math.round(pool.token1Ratio * 100) / 100}</span>
                              <span>%)</span>
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div className="pool-information-Token-TVL-row">
                        <div className="pool-information-Token-TVL-row-TVL-Volume">
                          <h3 className="faded">{t('earnModelVolume24H')}</h3>
                          {/* <p> */}
                          <p className={`${props.isRefreshing ? 'greyedout' : ''}`}> 
                            {pool?.volumeInUSD ? (
                              <>
                                <span>{"$ "}</span>
                                <span>{formatNumberWithMagnitudeSuffix(pool?.volumeInUSD)}</span>
                                </>
                            ) : (
                              "0.00"
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>  

                </div>
              </div>
            </>
        </Modal.Body>
      </Modal>

      {show && (
        <>
          {primaryWallet?.connector.isEmbeddedWallet && modalData?.status === "pending" ? displayToast() : dismiss()}

          {(!primaryWallet?.connector.isEmbeddedWallet || modalData?.status !== "pending") && (
            <TransactionModal
              show={show}
              modalData={modalData}
              handleClose={() => setShow(false)}
              handleFunction={activeTab ? handleAddLiquidity : withdrawLiquidity}
            />
          )}
        </>
      )}

    </>
  );
};

export default EarnModal;
