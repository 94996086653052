import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Tooltip from "../../../Common/Tooltip/Tooltip";
import ButtonCustom from "../../../Common/Button/ButtonCustom";
import { ArrowCircleDownIcon, ArrowCircleUpIcon, DoubleTickIcon, Facebook, Linkedin, Telegram, Twitter } from "../../../../Assets/Images/Icons/SvgIcons";
import { useSocialAccounts, useDynamicContext, useIsLoggedIn } from "@dynamic-labs/sdk-react-core";
import { handleCopy } from "../../../../Services/common.service";
import { ProviderEnum } from '@dynamic-labs/types';
import InputCustom from "../../../Common/FormInputs/Input/Input";
import { useLocation, useNavigate } from "react-router-dom";
import toast from 'react-hot-toast';
import "./MawhibaOverview.scss";


const MawhibaOverview = () => {

  const [showMoreConnectionsTable, setShowMoreConnectionsTable] = useState(false);
  const [showMoreTasksTable, setShowMoreTasksTable] = useState(false);
  const { t } = useTranslation();

  const { user } = useDynamicContext();
  const isLoggedIn = useIsLoggedIn();
  const navigate = useNavigate()

  const {
    error,
    linkSocialAccount,
    unlinkSocialAccount,
    isProcessing,
    isLinked,
    getLinkedAccountInformation,
  } = useSocialAccounts();

  const discordProvider = ProviderEnum.Discord;
  const isDiscordLinked = isLinked(discordProvider);
  const connectedDiscordAccountInfo = getLinkedAccountInformation(discordProvider);

  const twitterProvider = ProviderEnum.Twitter;
  const isTwitterLinked = isLinked(twitterProvider);
  const connectedTwitterAccountInfo = getLinkedAccountInformation(twitterProvider);

  const googleProvider = ProviderEnum.Google;
  const isGoogleLinked = isLinked(googleProvider);
  const connectedGoogleAccountInfo = getLinkedAccountInformation(googleProvider);


  const connectionsTableContent = [
    { 
      id: 1, 
      content: [
        'Connect your Discord account', 
        <ButtonCustom onClick={() => manageSocialAccount(isDiscordLinked, discordProvider)} title={isDiscordLinked ? "Unlink" : "Connect"} className={`mawhiba-overview-button ${isDiscordLinked ? "" : ""}`}/>,
        <span className={`points ${isDiscordLinked ? "completed" : ""}`}>10,000</span>, 
        '/once' 
      ]
    },
    { 
      id: 2, 
      content: [
        'Connect your Twitter account', 
        <ButtonCustom onClick={() => manageSocialAccount(isTwitterLinked, twitterProvider)} title={isTwitterLinked ? "Unlink" : "Connect"} className={`mawhiba-overview-button ${isTwitterLinked ? "" : ""}`}/>,
        <span className={`points ${isTwitterLinked ? "completed" : ""}`}>10,000</span>, 
        '/once' 
      ]
    },
    { 
      id: 3, 
      content: [
        'Connect your Google account', 
        <ButtonCustom onClick={() => manageSocialAccount(isGoogleLinked, googleProvider)} title={isGoogleLinked ? "Unlink" : "Connect"} className={`mawhiba-overview-button ${isGoogleLinked ? "" : ""}`}/>,
        <span className={`points ${isGoogleLinked ? "completed" : ""}`}>10,000</span>, 
        '/once' 
      ]
    },
  ];

  const tasksTableContent = [
    { 
      id: 1, 
      content: [
        'Trade on Dex', 
        <ButtonCustom onClick={() => navigate("/trade/simple/market")} title="Go To Dex" className="mawhiba-overview-button"/>,
        <span className="earned">250</span>, 
        <span className="points">500</span>, 
        <>daily<br /><span className="sub-text">(50 points/action)</span></> 
      ]
    },
    { 
      id: 2, 
      content: [
        'Add Liquidity', 
        <ButtonCustom onClick={() => navigate("/amm-pools")} title="Go to AAM Pools" className="mawhiba-overview-button"/>,
        <span className="earned">350</span>, 
        <span className="points">500</span>, 
        <>daily<br /><span className="sub-text">(50 points/action)</span></> 
      ]
    },
    { 
      id: 3, 
      content: [
        'Remove Liquidity', 
        <ButtonCustom onClick={() => navigate("/amm-pools")} title="Go to AAM Pools" className="mawhiba-overview-button"/>,
        <span className="earned">150</span>, 
        <span className="points">500</span>, 
        <>daily<br /><span className="sub-text">(50 points/action)</span></> 
      ]
    },
    { 
      id: 4, 
      content: [
        'Get whitelisted on Firoza Finance', 
        <ButtonCustom onClick={() => console.log("whitelist for Firoza")} title="Whitelisted" className="mawhiba-overview-button connected"/>,
        <span className="earned">10000</span>, 
        <span className="points completed">10000</span>, 
        '/once' 
      ]
    },
    { 
      id: 5, 
      content: [
        'Onboard New Users', 
        <ButtonCustom onClick={() => shareOnTwitter()} title="Tweet Now" className="mawhiba-overview-button"/>,
        <span className="earned"></span>, 
        <span className="points">1000</span>, 
        '/Successful Onboarding' 
      ]
    }
  ];

  /**
   * Only the first 5 elements of the array 'connectionsTableContent' are showm when there are more than 5.
   * This helps manage the state of the button used to expand or collapse the Connection table.
   */
  const handleShowMoreConnectionsTable = () => {
    setShowMoreConnectionsTable(!showMoreConnectionsTable);
  };

  /**
  * Only the first 5 elements of the array 'tasksTableContent' are showm when there are more than 5.
  * This helps manage the state of the button used to expand or collapse the Tasks table.
  */
  const handleShowMoreTasksTable = () => {
    setShowMoreTasksTable(!showMoreTasksTable);
  };

  /**
  * This useEffect hook redirects to 
  * the onboarding if the user is not connected
  **/
  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/mawhiba/onboarding");
    }
  }, [isLoggedIn]);

  /**
  * This useEffect hook handles potential errors 
  * that may occur when linking social accounts
  **/
  useEffect(() => {
    if (error) {
      if (error.code == "account_already_linked_to_different_profile") {
        toast.error("This account is already linked to another account", {id: "accountAlreadyLinked", duration: 4000, style: { fontSize: '16px' } });
      }
    }
  }, [error]);

  /**
  * This function handles the Discord Linking/Unlinking User Account
  **/
  const manageSocialAccount = async (isLinked, provider) => {
    if (isLinked) {
      console.log("Initiating Account Unlinking...");
      const unlinkSocialAccountResponse = await unlinkSocialAccount(provider);
    } else {
      console.log("Initiating Account Linking...")
      const linkSocialAccountResponse = await linkSocialAccount(provider);
    }
  };

  
  const COPIED_ICON_RESET_IN_SECONDS = 5;
  const [referralLink, setReferralLink] = useState('');
  const [copyReferralLink, setCopyReferralLink] = useState<boolean>(false);

  enum mawhibaProgramStatus {
    Inactive = "Inactive",
    Active = "Active",
    Banned = "Banned"
  }

  const [userProgramStatus, setUserProgramStatus] = useState<mawhibaProgramStatus>(mawhibaProgramStatus.Inactive);

  const statusClasses = {
    [mawhibaProgramStatus.Inactive]: "inactive",
    [mawhibaProgramStatus.Banned]: "banned",
  };

  useEffect(() => {
    if (user?.userId?.length) {
      generateReferralLink(user.userId)
        .then(referralLink => {
            setReferralLink("http://localhost:3000/mawhiba/onboarding?ref=" + referralLink);
            console.error("Referral Link Generation Complete...");
        })
        .catch(error => {
            console.error("Referral Link Generation Failed...");
        });
    }
  }, [user]);

  /**
  * These functions handle sharing the referral link and a message to the supported social media platforms.
  */
  const shareMessage = "Don't miss out on the $1M airdrop from Liberty Finance! Join now using my referral link! "

  const shareOnTwitter = () => {
    const twitterUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(referralLink)}&text=${encodeURIComponent(shareMessage)}`;
    window.open(twitterUrl, '_blank');
  };

  const shareOnFacebook = () => {
    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(referralLink)}&quote=${encodeURIComponent(shareMessage)}`;
    window.open(facebookUrl, '_blank');
  };

  const shareOnLinkedin = () => {
    const linkedinUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(referralLink)}&summary=${encodeURIComponent(shareMessage)}`;
    window.open(linkedinUrl, '_blank');
  };

  const shareOnTelegram = () => {
    const telegramUrl = `https://t.me/share/url?url=${encodeURIComponent(referralLink)}&text=${encodeURIComponent(shareMessage)}`;
    window.open(telegramUrl, '_blank');
  };

  /**
  * This function generates a referral link
  */
  const generateReferralLink = async (userId, length = 12) => {
    // Encode the user ID as a UTF-8 string
    const encoder = new TextEncoder();
    const data = encoder.encode(userId);

    // Generate a SHA-256 hash of the user ID
    const hashBuffer = await crypto.subtle.digest('SHA-256', data);

    // Convert the hash to a hex string
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');

    // Take the first 'length' characters of the hash
    const referralLink = hashHex.slice(0, length);

    return referralLink;
  };

  /**
  * This useEffect hook handles the Info Toast
  **/
  useEffect(() => {
    userProgramStatus === mawhibaProgramStatus.Inactive ? displayToast() : dismiss();
  }, [userProgramStatus]);

  /**
  * This function allows displaying the informative toast for Embedded Wallets
  **/
  const displayToast = () => {
    toast.loading("Connect At Least 2 Social Accounts To Activate The Program", {
      id: "programInfoToast",
      style: { fontSize: '16px' }
    })
  };

  /**
  * This function allows hiding the informative toast for Embedded Wallets
  **/
  const dismiss = () => toast.dismiss("programInfoToast");

  /**
  * This useEffect hook checks whether the conditions are met to have the program activated
  * TO BE MODIFIED - A CHECK SHOULD BE DONE IN DB IF BANNED
  **/
  useEffect(() => {
    const linkedCount = [isGoogleLinked, isTwitterLinked, isDiscordLinked].filter(Boolean).length;
    linkedCount >= 2 ? setUserProgramStatus(mawhibaProgramStatus.Active) : setUserProgramStatus(mawhibaProgramStatus.Inactive);
  }, [isGoogleLinked, isTwitterLinked, isDiscordLinked]);

  /**
  * This function is used to copy the content to the clipboard and manage the icons of the copy
  * 
  * @param field field on which copy function is called
  */
  const copy = () => {
    handleCopy(referralLink || "");
    // Set flag to true to indicate the reference is copied and need to display copied icon
    setCopyReferralLink(true);
    // Set flag to false to reset copy icon
    setTimeout(() => {
        setCopyReferralLink(false);
    }, COPIED_ICON_RESET_IN_SECONDS * 1000);
  }

  return (

    <>
    <div className="mawhiba-overview-container">
      <main className="main-content">
        <header>
          <h1>Mawhiba Incentive Program</h1>
          <p>Unlock the Gift of Possibilities in Islamic Finance with libfi.io</p>
        </header>

        <section className="stats-bar">
          <div className="stat-item">
            <h2>My Points</h2>
            <div className="stat-value">
              58,350
              <Tooltip icon={""} >
                <p className="stat-details">
                  Action Points: 47,350<br />Referral Points: 11,000
                </p>
              </Tooltip>
            </div>
          </div>
          <div className="stat-item">
            <h2>My Rank</h2>
            <div className="stat-value">3rd</div>
          </div>
          <div className="stat-item stat-item-hide">
            <h2>Referrals</h2>
            <div className="stat-value">20</div>
          </div>
          <div className="stat-item">
            <h2>Status</h2>
            <div className={`stat-value ${statusClasses[userProgramStatus] || ""}`}>{userProgramStatus}</div>
            </div>
        </section>


        {userProgramStatus !== mawhibaProgramStatus.Banned && (
          <>
          {userProgramStatus === mawhibaProgramStatus.Active && (
            <section className="referral">
              <h2>My Referral Link</h2>
              <div className="referral-link-container">
                <InputCustom value={referralLink} readOnly />
                <ButtonCustom onClick={() => copy()} className="copy-button">
                  <div className="copy-icon">{copyReferralLink ? <DoubleTickIcon /> : ""}</div>
                  {copyReferralLink ? 'Copied!' : 'Copy'}
                </ButtonCustom>
              </div>
              <div className="referral-share-container">
                <ButtonCustom onClick={shareOnTwitter} className="share-button">
                  <div className="share-icon"><Twitter /></div>
                  Twitter
                </ButtonCustom>
                <ButtonCustom onClick={shareOnFacebook} className="share-button">
                  <div className="share-icon"><Facebook /></div>
                  Facebook
                </ButtonCustom>
                <ButtonCustom onClick={shareOnLinkedin} className="share-button">
                  <div className="share-icon"><Linkedin /></div>
                  Linkedin
                </ButtonCustom>
                <ButtonCustom onClick={shareOnTelegram} className="share-button">
                  <div className="share-icon"><Telegram /></div>
                  Telegram
                </ButtonCustom>
              </div>
            </section>
          )}

          <section className="connections">
            <div className="section-header">
              <h2>My Social Network Connections</h2>
              {connectionsTableContent.length > 5 && (
                <div onClick={handleShowMoreConnectionsTable} className="showMore">
                  <span className="collapse-all">
                    {showMoreConnectionsTable ? 'Collapse' : 'Show All'}
                    {showMoreConnectionsTable ? <ArrowCircleUpIcon /> : < ArrowCircleDownIcon />}
                  </span>
                </div>
              )}
            </div>
            <div className="data-table-container">
              <table className="data-table">
                <thead>
                  <tr>
                    <th>Task Description</th>
                    <th>Action</th>
                    <th>Points</th>
                    <th>Frequency</th>
                  </tr>
                </thead>
                <tbody>
                  {connectionsTableContent.map((row, index) => (
                    <tr key={row.id} className={index >= 5 && !showMoreConnectionsTable ? 'hidden' : ''}>
                      {row.content.map((cell, cellIndex) => (
                        <td key={cellIndex}>{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </section>


          <section className="tasks">
            <div className="section-header">
              <h2 className={`${userProgramStatus !== mawhibaProgramStatus.Active ? 'blurred' : ''}`}>My Tasks</h2>
              {tasksTableContent.length > 5 && (
                <div onClick={handleShowMoreTasksTable} className="showMore">
                  <span className="collapse-all">
                    {showMoreTasksTable ? 'Collapse' : 'Show All'}
                    {showMoreTasksTable ? <ArrowCircleUpIcon /> : < ArrowCircleDownIcon />}
                  </span>
                </div>
              )}
            </div>
            <div className={`data-table-container ${userProgramStatus !== mawhibaProgramStatus.Active ? 'blurred' : ''}`}>
              <table className="data-table">
                <thead>
                  <tr>
                    <th>Task Description</th>
                    <th>Action</th>
                    <th>Earned Today</th>
                    <th>Points</th>
                    <th>Frequency</th>
                  </tr>
                </thead>
                <tbody>
                  {tasksTableContent.map((row, index) => (
                    <tr key={row.id} className={index >= 5 && !showMoreTasksTable ? 'hidden' : ''}>
                      {row.content.map((cell, cellIndex) => (
                        <td key={cellIndex}>{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </section>

          {/*
          <section className="referrals">
            <div className="section-header">
              <h2>My Referrals</h2>
              {connectionsTableContent.length > 3 && (
                <div onClick={handleShowMoreTasksTable} className="viewAll">
                  <span className="collapse-all">
                    {showMoreTasksTable ? 'Collapse' : 'Show All'}
                    {showMoreTasksTable ? <ArrowCircleUpIcon /> : < ArrowCircleDownIcon />}
                  </span>
                </div>
              )}
            </div>
          </section>
          */}
          </>
        )}
      
      </main>
    </div>

    </>
  );
}

export default MawhibaOverview;
