import React, { SetStateAction, useEffect } from "react";
import "./Sidebar.scss";
import {
  SmallLogo,
  TradeIcon,
  PortfolioIcon,
  EarnIcon,
  OnOffRampIcon,
  GovernanceIconTwo,
  AnalyticsIcon,
  EducationIcon,
  SupportIcon,
  LockIcon,
  OpenLock,
  GlowingBulb,
  BulbOffIcon,
  MawhibaIcon,
} from "../../../Assets/Images/Icons/SvgIcons";
import IslamIcon from '../../../Assets/Images/Icons/islam.png'
import { Link, NavLink, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { changeTheme } from "../../../Redux/Slices/theme.slice";
import { callApiPostMethod } from "../../../Redux/Actions/api.action";
import { Dispatch } from "redux";
import { useTranslation } from "react-i18next";
import { useDynamicContext } from '@dynamic-labs/sdk-react-core';


const Sidebar = ({ lock, setLock, setShowSidebar, setLockVisitor }: { lock?: any; setLock: any, setShowSidebar?: any, setLockVisitor?: any, }) => {
  
  // State variables
  
  const dispatch: Dispatch<any> = useDispatch();
  const { user } = useDynamicContext();
  const { t } = useTranslation();
  const theme: any = useSelector((state: any) => state?.theme);

  const navigationLinks = [
    {
      id: 1,
      path: "/trade/simple/market",
      icon: <TradeIcon />,
      label: t("leftNavTrade"),
    },
    {
      id: 2,
      path: "/portfolio",
      icon: <PortfolioIcon />,
      label: t("leftNavPortfolio"),
    },
    {
      id: 3,
      path: "/earn/overview",
      icon: <EarnIcon />,
      label: t("leftNavEarn"),
    },
    {
      id: 4,
      path: "/on-off-ramp/trade/buy",
      icon: <OnOffRampIcon />,
      label: t("leftNavCryptoBuySell"),
    },
    {
      id: 5,
      path: "/",
      icon: <GovernanceIconTwo />,
      label: t("leftNavGovernance")
    },
    {
      id: 6,
      path: "/",
      icon: <AnalyticsIcon />,
      label: t("leftNavAnalytics")
    },
    {
      id: 7,
      path: "/",
      icon: <EducationIcon />,
      label: t("leftNavEducation")
    },
    {
      id: 8,
      path: "/shariah/tokens",
      icon: <img src={IslamIcon} style={{ height: 20, width: 20 }} />,
      label: t('leftNavShariah')
    },
    {
      id: 9,
      path: "/mawhiba/onboarding",
      icon: <MawhibaIcon />,
      label: t('leftNavMawhiba')
    },
    {
      id: 10,
      path: "/",
      icon: <SupportIcon />,
      label: t("leftNavSupport")
    },
  ];


  // Funtions

  /**
  * This Function Updates the side bar lock preference in the User Profile
  **/
  const updateUserLockStatus = async (value) => {
    setLock(value);
    setLockVisitor(value);
    const result: any = await dispatch(
      callApiPostMethod(
        "USER_ONBOARDING",
        {
          userId: user?.userId,
          lock: value.toString(),
        },
        false,
        false
      )
    );
  };

  
  return (
    <aside className={`Sidebar ${lock ? "locked" : ""}`}>
      <Link
        to= "/"
        className="sidebar_logo"
      >
        <SmallLogo />
        <span className="sidebar_logo_text">{t('leftNavLogo')}</span>
      </Link>
      <ul className="navigation_link">
        {navigationLinks?.map((item) => (
          <li key={item?.id}>
            <NavLink to={item?.path} className="nav_item" onClick={() => setShowSidebar(false)}>
              <span className="nav_item_icon">{item?.icon}</span>
              <span className="nav_item_text">{item?.label}</span>
            </NavLink>
          </li>
        ))}
      </ul>
      <ul className="sidebar_action">
        <li className="d-md-block d-none">
          <button
            onClick={() => {
              updateUserLockStatus(!lock);
            }}
          >
            {lock ? <LockIcon /> : <OpenLock />}
          </button>
        </li>
        <li className="d-none">
          <button
            onClick={() =>
              dispatch(changeTheme(theme?.theme === "light" ? "dark" : "light"))
            }
          >
            {theme?.theme === "light" ? <GlowingBulb /> : <BulbOffIcon />}
          </button>
        </li>
      </ul>
    </aside>
  );
};

export default Sidebar;
