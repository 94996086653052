import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import MyPortfolio from "../../../Common/Cards/MyPortfolio/MyPortfolio";
import LiquidityCard from "../../../Common/Cards/LiquidityCard/LiquidityCard";
import RevenueCard from "../../../Common/Cards/RevenueCard/RevenueCard";
import MyAssets from "../../../Common/Cards/MyAssets/MyAssets";
import ButtonCustom from "../../../Common/Button/ButtonCustom";
import { WalletIcon } from "../../../../Assets/Images/Icons/SvgIcons";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CommonTiles from "../TilesCard/CommonTiles";
import { useTranslation } from "react-i18next";
import { getPoolData } from "../../../../LibfiServices/Hooks/useFetchPoolData";
import { MY_ASSETS, TOKENS, TOKEN_DETAILS_SINGLE } from "../../../../interfaces/commonInterfaces";
import { dynamicContractDetails, tokenCollection } from "../../../../Services/dynamicContractDetails";
import { FETCH_DOLLAR_VALUE_CHANGES } from "../../../../Redux/Actions/apiRequestInterfaces";
import { callApiPostMethod } from "../../../../Redux/Actions/api.action";
import { getLPBalance } from "../../../../Services/contractCallService";
import { divideWithDecimal } from "../../../../Services/common.service";
import { fetchTokenBalance } from "../../../../LibfiServices/Hooks/usefetchTokenBalance";
import { DOLLAR_VAL } from "../../../../Redux/Actions/apiResponseInterfaces";
import { useDynamicContext, DynamicWidget, useIsLoggedIn } from '@dynamic-labs/sdk-react-core';
import "./PortfolioPage.scss";
import LibfiService from "../../../../LibfiServices/libfiService";


const PortfolioPage = () => {

  // State variables
  const navigate = useNavigate();
  const dispatch: any = useDispatch();
  const { t } = useTranslation();
  const { primaryWallet, setShowDynamicUserProfile } = useDynamicContext();
  const isLoggedIn = useIsLoggedIn();
  const [show, setShow] = useState<boolean>(false);
  const [assets, setAssets] = useState<MY_ASSETS[]>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);

  window?.addEventListener("popstate", function (event) {
    navigate("/portfolio");
  });

  useEffect(() => {
    if (primaryWallet?.connected) {
      const fetchData = async () => { 
        setLoader(true);
        if (primaryWallet) {
          const poolData = await getPoolData({ dispatch, walletAddress: primaryWallet?.address });
          const dollarValues = await fetchDollarValue();
          if (dollarValues && poolData) { 
            await fetchAssetData(dollarValues, poolData);
          }
        }
        setLoader(false);
      }
      
      fetchData();

      const interval = setInterval(() => {
        const runRefreshFunctions = async () => {
          if (primaryWallet?.connected) {
            setIsRefreshing(true); 
            await fetchData(); 
            setIsRefreshing(false); 
          }
        };

        runRefreshFunctions();
      }, 30000);
    
      return () => clearInterval(interval);
    }
  }, [primaryWallet]);


  const getTokenImage = (tokenAddress) => {
    return dynamicContractDetails?.find(
      (info: any) =>
        info?.address?.toLowerCase() ==
        tokenAddress?.toLowerCase()
    )?.img;
  }

  const get24HourChangeFromData = (data, timestampFieldName, valueFieldName) => { 
    if (!data) return 0;
    const sortedData = [...data].sort((a, b) => a[timestampFieldName] - b[timestampFieldName]);

      // Get the latest timestamp and its liquidity
      const latestEntry = sortedData[sortedData.length - 1];
      const latestTimestamp = latestEntry[timestampFieldName];
      const latestLiquidity = latestEntry[valueFieldName];

      // Calculate the timestamp for 24 hours before the latest timestamp
      const twentyFourHoursInMs = 24 * 60 * 60 * 1000;
      const twentyFourHoursAgoTimestamp = latestTimestamp - twentyFourHoursInMs;

      // Find the closest timestamp before or equal to 24 hours ago
      let closestEntry: any = null;
      let closestDiff = Infinity;

      for (let entry of sortedData) {
        const timestamp = entry.timestamp;
        if (timestamp <= twentyFourHoursAgoTimestamp) {
          const diff = twentyFourHoursAgoTimestamp - timestamp;
          if (diff < closestDiff) {
            closestDiff = diff;
            closestEntry = entry;
          }
        }
      }

      if (closestEntry) {
        const closestLiquidity = closestEntry[valueFieldName];
        const liquidityChangeValue = latestLiquidity - closestLiquidity;
        return (liquidityChangeValue / closestLiquidity) * 100;
      } else {
        return 0;
      }
  }

  const get24HourChangeForToken = async (token: string) => { 
    const endTimestamp = Date.now();
    const startTimestamp = endTimestamp - 10 * 24 * 60 * 60 * 1000;
    const groupBy = "day";
    const requestData: FETCH_DOLLAR_VALUE_CHANGES = {
      startTimestamp, token, groupBy, endTimestamp
    }
    const result: any = await dispatch(
      callApiPostMethod("DOLLAR_VALUE_CHANGES", requestData, false, false)
    );
    return get24HourChangeFromData(result?.data?.[0]?.prices, "timestamp", "price");
  }

  const get24HourChangeForPool = async (pairAddress: string) => {
    const result = await dispatch(
      callApiPostMethod(
        "GET_GRAPH",
        { pairAddress },
        false,
        false
      )
    );

    return get24HourChangeFromData(result?.data, "timestamp", "liquidity");
  }
  
  const fetchLPTokens = async (poolData) => {
    if (primaryWallet?.connected) {
      const result = poolData.map(async (pool) => {
        const balance = await LibfiService.getLPBalance(primaryWallet?.address, pool.pairAddress);
        const decimal = await LibfiService.getLPDecimals(pool.pairAddress);
        const convertedBalance = divideWithDecimal(balance, decimal);
        if (Number(convertedBalance) <= 0) {
          return {
            token0Name: pool.token0Name,
            token1Name: pool.token1Name,
            price: 0,
            balance: 0,
            value: 0,
            change: 0,
            isLPToken: true
          }
        }
        const change = await get24HourChangeForPool(pool.pairAddress);
        const tvl = calculateTVL(pool);
        return {
          token0Name: pool.token0Name,
          token1Name: pool.token1Name,
          price: tvl / Number(convertedBalance),
          balance: convertedBalance,
          value: tvl,
          change,
          img0: getTokenImage(pool.token0Address),
          img1: getTokenImage(pool.token1Address),
          isLPToken: true
        };
      });
      return await Promise.all(result);
    }
  }

  const fetchAssetData = async (dollarValues, poolData) => {
    if (!tokenCollection || tokenCollection.length === 0) return;

    let result: MY_ASSETS[] = await Promise.all(tokenCollection.map(async (asset) => {
      const price = dollarValues.find((dv) => dv.assetName === asset.symbol)?.price || 0;

      const tokenDetails: TOKEN_DETAILS_SINGLE = {
        tokenAddress: getTokenDetails(asset)?.tokenAddress || "",
        isTokenNative: getTokenDetails(asset)?.isTokenNative || false,
      };

      const change = await get24HourChangeForToken(asset?.symbol || "");
      const walletAddress = primaryWallet?.address;
      const tokenBalance = await fetchTokenBalance({ walletAddress, tokenDetails });

      return {
        token0Name: asset?.symbol || "",
        price,
        balance: tokenBalance?.tokenBalance || 0,
        value: (price * (tokenBalance?.tokenBalance || 0)) || 0,
        change,
        img0: asset?.img,
        isShariahCompliant: asset?.shariahCompliant
      };
    }));

    const lpTokens = await fetchLPTokens(poolData);

    if (lpTokens) result = [...result, ...lpTokens]
    result = result.filter(r => r.value > 0);
    setAssets(result);
  };

  const getTokenDetails = (asset: TOKENS) => {
    let returnData: TOKEN_DETAILS_SINGLE | undefined;

    const tokenData: TOKENS | undefined = dynamicContractDetails.find(
      (a) => a.symbol == "ETH"
    );
    returnData = {
      tokenAddress: asset?.address || '',
      isTokenNative: tokenData?.symbol === asset?.symbol ? true : false
    };
    return returnData;
  };

  const fetchDollarValue = async () => {
    const result: DOLLAR_VAL = await dispatch(
      callApiPostMethod("DOLLAR_VALUE", {}, false, false)
    );

    // Wrapping ETH //
    // Duplicate the row with ETH and replace "ETH" with "WETH"
    return result?.data?.map((value: any) => {
      if (value.assetName === "ETH") {
        const wethRow = { ...value, assetName: "WETH" };
        return [value, wethRow];
      }
      return value;
    }).flat();
  };

  /**
  * function to get total LP tokens in circulation for TVL
  */
  const calculateTVL = (item) => {
    const tvl = (Number(item.reserve1) * Number(item.dollarValue0)) + (Number(item.reserve2) * Number(item.dollarValue1));
    return tvl;
  };


  return (
    <div className="PortfolioPage">
      {!isLoggedIn ? (
        <div className="wallet_not_connected portfolio_wallet_not_connected">
          <p>{t('welcomeLIBFI')}</p>
          <DynamicWidget variant='modal' />
        </div>
      ) : (
        <>
          {isLoggedIn && !primaryWallet?.address ? (
            <div className="wallet_not_connected portfolio_wallet_not_connected">
              <p>{t('welcomeLIBFI')}</p>
              <ButtonCustom
                title={
                  <>
                    <WalletIcon /> 
                    Select A Wallet
                  </>
                }
                show={show}
                setShow={setShow}
                onClick={() => setShowDynamicUserProfile(true)}
              />
            </div>
          ) : (
            <>
              <CommonTiles page={"portfolio"} assets={assets} loader={assets.length > 0 ? false : loader} />
              <Row className="portfolio_details_row">
                <Col lg={4} sm={6}>
                  <MyPortfolio />
                </Col>
                <Col lg={4} sm={6}>
                  <LiquidityCard />
                </Col>
                <Col lg={4} sm={6}>
                  <RevenueCard />
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <MyAssets page="portfolio" assets={assets} loader={loader} isRefreshing={isRefreshing} />
                </Col>
              </Row>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default PortfolioPage;
