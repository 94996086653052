import React, { useEffect, useState } from "react";
import CommonCard from "../../../Common/Cards/CommonCard/CommonCard";
import ButtonCustom from "../../../Common/Button/ButtonCustom";
import CustomTable from "../../../Common/Table/Table";
import { LeftArrow, SearchIconTwo } from "../../../../Assets/Images/Icons/SvgIcons";
import Select from "react-select";
import InputCustom from "../../../Common/FormInputs/Input/Input";
import { useLocation, useNavigate } from "react-router-dom";
import EarnModal from "../../../Common/Modals/EarnModal/EarnModal";
import { useDispatch, useSelector } from "react-redux";
import { setGlobalPoolData, setUserSubscribed } from "../../../../Redux/Slices/user.slice";
import SettingModal from "../../../Common/Modals/SettingModal/SettingModal";
import { cryptoDecimals, toCustomFixed } from "../../../../Services/common.service";
import Tooltip from "../../../Common/Tooltip/Tooltip";
import { formatNumberWithMagnitudeSuffix } from "../../../../Services/common.service";
import { POOLS } from "../../../../Redux/Actions/apiResponseInterfaces";
import {
  SwapIcon,
  TableViewIcon,
  PanelViewIcon,
  PanelSwitchIcon,
} from "../../../../Assets/Images/Icons/SvgIcons";
import { useTranslation } from "react-i18next";
import { getPoolData } from "../../../../LibfiServices/Hooks/useFetchPoolData";
import { useDynamicContext } from "@dynamic-labs/sdk-react-core";
import "./EarnAMMPools.scss";


const EarnAMMPools = () => {

  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const { primaryWallet } = useDynamicContext();
  const globalPoolData = useSelector((state: any) => state?.user?.poolData);
  const [ModalShow, setModalShow] = useState(false);
  const isSubscribed = useSelector((state: any) => state?.user?.subscribed);
  const [show, setShow] = useState<boolean>(false);
  const [showTab, setShowTab] = useState<string>("earn");
  const [poolData, setPoolData] = useState<any>({});

  const [searchQuery, setSearchQuery] = useState<String>("");
  const [searchResults, setSearchResults] = useState<POOLS["data"]>([]);
  const [notLoaded, setNotLoaded] = useState<boolean>(false);
  const [sorting, setSorting] = useState<{ field: String; upOrDown: String }>({
    field: 'myPoolShares',
    upOrDown: "up",
  });
  const [Loader, setLoader] = useState<boolean>(false);
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
  const assetName = location?.state?.assetName;
  const [buttonPanelClicked, setButtonPanelClicked] = useState(false);
  const [displayValues, setDisplayValues] = useState({});
  const [showUpdatedContent, setShowUpdatedContent] = useState(Array(searchResults.length).fill(false));

  /**
  * This useEffect hook initializes the Liquidity Pools panels/table
  **/
  useEffect(() => {
    if (primaryWallet?.connected) {
      handlePools();
    }
  }, [primaryWallet]);

  /**
  * This useEffect hook resets the Liquidity Pools panels/table & Modal
  **/
  useEffect(() => {
    if (!primaryWallet?.connected) {
      // Close modal if Opened and Restore default settings
      if (show) {
        setShow(!show);
        setShowTab("earn");
        setPoolData({});
      }
      // Reset Pools Information
      if (searchResults.length > 0) {
        setLoader(false);
        setSearchResults([]);
      }
    }
  }, [primaryWallet]);

  /**
  * This useEffect hook allows refreshing the Liquidity Pools panels/table
  * every 30s if no action has been performed by the user
  **/
  useEffect(() => {
    const interval = setInterval(() => {
      const runRefreshFunctions = async () => {
        if (primaryWallet?.connected) {
          setIsRefreshing(true); 
          await handlePools(); 
          setIsRefreshing(false); 
        }
      };
  
      runRefreshFunctions();
    }, 60000);

    return () => clearInterval(interval);
  }, [primaryWallet?.connected]);

  /**
  * This useEffect hook allows updating the data 
  * within the selected pool modal when a refresh occurs
  **/
  useEffect(() => {
    if (globalPoolData && poolData) {
      const filteredSelectedPool: POOLS["data"][1] = globalPoolData?.find( 
        (pool: POOLS["data"][1]) => pool?.token0Name === poolData?.token0Name && pool?.token1Name === poolData?.token1Name );

      if (show) setPoolData(filteredSelectedPool);
    }
  }, [globalPoolData]);

  /**
  * This useEffect hook triggers the filtering function when navigating from the Pool Overview Page.
  **/
  useEffect(() => {
    if (isSubscribed) {
      handleSearchWhenSubscribed();
    }
  }, [isSubscribed]);

  /**
  * This function filters the pools to include only those that
  *  contain the token selected by the user on the Pool Overview Page
  **/
  const handleSearchWhenSubscribed = () => {
    dispatch(setUserSubscribed(false));
    setSearchQuery(assetName);
  };

  /**
  * This function fetches all the information for the available pools (tokens, TVL, ...)
  */
  const handlePools = async () => {
    setLoader(true);
    if (sorting) {
      const value: string | null =
        sorting.field === 'lockedLPTokens'
          ? "totalLPAmount"
          : sorting.field === 'volume'
            ? "volume"
            : null;

      const walletAddress = primaryWallet?.address
      if (walletAddress) {
        const poolsData = await getPoolData({
          dispatch,
          walletAddress,
          sortBy: value || "",
          reverse: sorting.upOrDown === "up"
        });

        dispatch(setGlobalPoolData(poolsData));   

        if (!poolsData || poolsData.length === 0) { 
          setNotLoaded(true);
        } else {
          if (assetName?.length) {
            await handleSearch(poolsData, assetName);
          } else { 
            setSearchResults(poolsData);
          }
        }
      }
    }
    setLoader(true);
  }

  /**
  * This function filters the pools based on the value entered in the Search bar
  * @param data Total pools in the platform
  * @param searchStringData Pool name to be searched
  **/
  const handleSearch = async (data: POOLS["data"], searchStringData: any) => {
    setSearchQuery(searchStringData);
    const filteredResults: POOLS["data"] = data?.filter(
      (item: POOLS["data"][1]) =>
        item?.token0Name
          ?.toLowerCase()
          ?.includes(searchStringData?.toLowerCase()) ||
        item?.token1Name
          ?.toLowerCase()
          ?.includes(searchStringData?.toLowerCase())
    );
    setSearchResults(filteredResults);
  };

  /**
  * This function manages the display of the respective modals
  * @param show boolean value to show modal or not
  * @param tabToShow which modal to show earn or redeem
  * @param userSelectedPool which pool to show
  **/
  const handleShow = (
    show: boolean,
    tabToShow: string,
    userSelectedPool: POOLS["data"][1]
  ) => {
    setShow(show);
    setShowTab(tabToShow);
    setPoolData(userSelectedPool);
  };

  /**
  * This function allows the data to be sorted accordingly to the user's preference
  **/
  const updateSortedData = (data: string, sortType: string) => {
    setSorting({ field: data, upOrDown: sortType });
  };

  /**
  * This function manages the view selected by the user (table view vs panel view)
  * @param view 'True' refers to the Panel view and 'False' refers to the Table view
  **/
  const handleView = (view: boolean) => {
    setButtonPanelClicked(view);
  };

  /**
  * This function manages the tokens switch button in the Panel view
  **/ 
  const handleClick = (index) => {
    setDisplayValues(prev => ({
      ...prev,
      [index]: !prev[index]
    }));
  };

  /**
  * This function manages which container 
  * to display for each pool in the Panel view
  **/
  const handleButtonClick = (index) => {
    const newShowUpdatedContent = [...showUpdatedContent];
    newShowUpdatedContent[index] = !newShowUpdatedContent[index];
    setShowUpdatedContent(newShowUpdatedContent);
  };

  //////////////////////////////////// DROPDOWN ////////////////////////////////////
  /**
  * This manages the ordering criteria in the Dropdown
  **/
  const options = [
    { value: "volume", label: (t('ammPoolsSortByField')).replace('{{fieldName}}', "Volume") },
    { value: "tvl", label: (t('ammPoolsSortByField')).replace('{{fieldName}}', "TVL") },
    { value: "apr", label: (t('ammPoolsSortByField')).replace('{{fieldName}}', "APR") }
  ]

  /**
  * This manages the style of the Dropdown
  **/
  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent",
      borderRadius: '16px',
      border: '1.5px solid #E5F7FF',
      boxShadow: "none",
      boxSizing: 'border - box',
      wordWrap: 'break-word',
      color: 'white',
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#181431",
      color: 'white',
      width: `${menuWidth}px`,
      marginTop: 2,
      borderRadius: '16px',
      border: '1.5px solid #FFFFFF',
      borderShadow: 'none',
    }),
    menuList: (provided, state) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
    }),
    option: (styles, { isFocused, isSelected }) => {
      return {
        ...styles,
        borderRadius: '16px',
        backgroundColor: isSelected ? "blue" : isFocused ? "rgb(65, 105, 239)" : undefined
      };
    },
    singleValue: (provided) => ({
      ...provided,
      color: 'white',
    }),
    dropdownIndicator: base => ({
      ...base,
      color: 'white'
    })
  };

  /**
  * This ensures that each ordering criteria in the Dropdown fits on one line
  **/
  const menuWidth = options.reduce((maxWidth, option) => {
    const labelWidth = option.label.length * 8;
    return labelWidth > maxWidth ? labelWidth : maxWidth;
  }, 0);

  /**
  * This function stores the ordering criteria selected by the user in the dropdown
  **/
  const [selectedOption, setSelectedOption] = useState(options[0]);

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  /**
  * This useEffect hook updates the translation of the selected ordering criteria in the dropdown
  **/
  useEffect(() => {
    const selectedOp = options.find(o => o.value === selectedOption.value);
    setSelectedOption({ value: selectedOption.value, label: selectedOp?.label || "" });
  }, [t]);

  /**
  * This function handles sorting the pools according to the user's selection from the dropdown.
  **/
  const sortResults = (criteria) => {
    return [...searchResults].sort((a, b) => {
      if (criteria === 'volume') {
        return Number(b.volumeInUSD) - Number(a.volumeInUSD);
      } else if (criteria === 'tvl') {
        return Number(b?.TVL) - Number(a?.TVL);
      } //else if (criteria === 'apr') {
      //return b.apr - a.apr;
      //}
      return 0;
    });
  };

  //////////////////////////////////// END OF DROPDOWN ////////////////////////////////////

  
  const sortedResults = selectedOption ? sortResults(selectedOption.value) : searchResults;
  const combinedResults = [...new Set(sortedResults.concat(searchResults))];
    
  window?.addEventListener("popstate", function (event) {
    navigate("/earn/overview");
  });


  //////////////////////////////////// TABLE EFFECT ////////////////////////////////////
  /**
  * This section manages the table's effect when it does not fully fit within the screen.
  **/
  const [isLastColumnVisible, setIsLastColumnVisible] = useState(true);

  const tableRef = React.useCallback(node => {
    if (node !== null) {
      const lastColumn = node.querySelector('th:last-child');
      const handleResize = () => {
        if (lastColumn) {
          const rect = lastColumn.getBoundingClientRect();
          setIsLastColumnVisible(rect.right <= window.innerWidth);
        }
      };
      handleResize();
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, []);

  useEffect(() => {
    if (!isLastColumnVisible) {
      document.documentElement.style.setProperty('--VerticalLine', 'linear-gradient(90deg, rgba(242, 242, 242, 0) 0%, rgb(18, 18, 18) 100%)');
    } else {
      document.documentElement.style.setProperty('--VerticalLine', 'none');
    }
  }, [isLastColumnVisible]);

  //////////////////////////////////// END OF TABLE EFFECT ////////////////////////////////////

  
  return (
    <section className="EarnAmmPools">
      <div className="EarnAmmPoolsHead">
        <h3>
          <button onClick={() => navigate("/earn/overview")}>
            <LeftArrow />
          </button>{" "}
          {t('ammPoolsLiquidityPools')}
        </h3>

        { primaryWallet?.connected && (
          <div className="EarnAmmPoolsHead-options">
            <div className="EarnAmmPoolsHead-options-display">
              {!buttonPanelClicked && (
                <Select className="EarnAmmPoolsHead-options-ordering"
                  value={selectedOption}
                  hideSelectedOptions={true}
                  options={options}
                  styles={customStyles}
                  isSearchable={false}
                  onChange={handleChange}
                />
              )}
              <button className="EarnAmmPoolsHead-options-display-button"
                style={{ color: buttonPanelClicked ? '#4169ef' : 'white' }}
                onClick={() => handleView(true)} >
                <TableViewIcon />
              </button>
              <button className="EarnAmmPoolsHead-options-display-button"
                style={{ color: buttonPanelClicked ? 'white' : '#4169ef' }}
                onClick={() => handleView(false)} >
                <PanelViewIcon />
              </button>
            </div>
            <InputCustom
              leftIcon={<SearchIconTwo />}
              placeholder={t('earnPoolSearchPlaceholder')}
              onChange={(e: any) => {
                e.preventDefault();
                handleSearch(globalPoolData, e?.target?.value);
              }}
              value={searchQuery}
            />
          </div>
        )};
      </div>


      {/*  Code start for panel view */}
      {!buttonPanelClicked && (

        <div className={`liquidity-pools-section${ ( Loader && combinedResults.length == 0 ) || notLoaded || combinedResults.length === 0 ? '' : ' grid'}`}>
          {!primaryWallet?.connected && combinedResults.length === 0 &&
            <div style={{ height: '20px' }}>
              <CommonCard />
            </div>
          }

          {!Loader && !notLoaded || combinedResults.length !== 0
            ? combinedResults?.map((item: POOLS["data"][1], index) => {
              //const itemId = item.id;
              //console.log("itemId: " + itemId)
              //console.log("index: " + index)

              //for (let key in item) {
              //  console.log("item - " + key + ':', item[key]);
              //}
              return (

                <div>
                  {/* Code start for "view pool" */}
                  {!showUpdatedContent[index] && (
                    <CommonCard
                    >
                      <div className="liquidity-pool-container" key={index}>
                        <div className="liquidity-pool-sub-container-panel-1">
                          <div className="liquidity-pool-header">
                            <span className="poolIcons">
                              <img
                                className="poolIconOne"
                                src={item?.token0URI}
                                alt=""
                              />
                              <img
                                className="poolIconTwo"
                                src={item?.token1URI}
                                alt=""
                              />
                            </span>
                            <span>
                              <span>{item?.token0Name}</span>
                              <span>/</span>
                              <span>{item?.token1Name}</span>
                            </span>

                          </div>
                          <div className="liquidity-pool-id-container">
                            <a href={`https://sepolia.arbiscan.io/address/${item?.pairAddress}`} target="_blank">
                              {`${item?.pairAddress.substring(0, 6)}...${item?.pairAddress.substring(item?.pairAddress.length - 4)}`}
                            </a>
                          </div>
                          <div className="liquidity-pool-apr-title-container">
                            <div className="liquidity-pool-apr-title-sub-container">
                              <div className="liquidity-pool-apr-title">
                                <div className="liquidity-pool-apr-title-text">
                                  {t('ammPoolsAvgAPR')}
                                </div>
                                <Tooltip>
                                  <p>{t('ammPoolsAvgAPRToolTip')}</p>
                                </Tooltip>
                              </div>
                            </div>
                          </div>
                          <div className="liquidity-pool-apr-data-container">
                            <div className="liquidity-pool-apr-data">
                              <span>2.01</span>
                              <span>%</span>
                            </div>
                          </div>
                          <div className="liquidity-pool-details-container">
                            <table className="liquidity-pool-details-table">
                              <tbody>
                                <tr>
                                  <td className="liquidity-pool-details-table-column1">{t('ammPoolsVolumeColumn')}</td>
                                  <td className="liquidity-pool-details-table-column2">{t('ammPoolsTVLColumn')}</td>
                                </tr>
                                <tr>
                                  <td className={`liquidity-pool-details-table-column1 ${isRefreshing ? 'text-shadow' : ''}`}>                     
                                    <p>
                                      {item?.volumeInUSD ? (
                                        <Tooltip
                                          className="wallet_text_tooltip"
                                          heading={item?.volume.toString()}
                                          icon={document.body.dir === "rtl" ? `${formatNumberWithMagnitudeSuffix(item?.volumeInUSD)}$` : `$${formatNumberWithMagnitudeSuffix(item?.volumeInUSD)}`}
                                        />
                                      ) : (
                                        "0.00"
                                      )}
                                    </p>
                                  </td>
                                  <td className={`liquidity-pool-details-table-column2 ${isRefreshing ? 'text-shadow' : ''}`}>
                                    <p>
                                      <span>$</span>
                                      <span>{formatNumberWithMagnitudeSuffix(item?.TVL)}</span>
                                    </p>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="liquidity-pool-separator"></div>
                          <div className="liquidity-pool-details-container">
                            <table className="liquidity-pool-details-table">
                              <tbody>
                                <tr>
                                  <td className="liquidity-pool-details-table-column1">{t('ammPoolsLockedAssetValueColumn')}</td>
                                  <td className="liquidity-pool-details-table-column2">{t('ammPoolsMyPoolShareColumn')}</td>
                                </tr>
                                <tr>
                                  <td className={`liquidity-pool-details-table-column1 ${isRefreshing ? 'text-shadow' : ''}`}>
                                    <p>
                                      <span>$</span>
                                      <span>{formatNumberWithMagnitudeSuffix(item?.userShareDollarValue)}</span>
                                    </p>
                                  </td>
                                  <td className={`liquidity-pool-details-table-column2 ${isRefreshing ? 'text-shadow' : ''}`}>
                                    <p>
                                      <span>{`${item?.userShare ? toCustomFixed(item?.userShare, 2) : "0.00"
                                        } `}</span>
                                      <span>%</span>
                                    </p>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="liquidity-pool-buttons">
                            <td>
                              <ButtonCustom
                                className="bordered-blue"
                                title={t('ammPoolsEarnButton')}
                                onClick={() => {
                                  handleShow(true, "earn", item);
                                }}
                              />
                              <ButtonCustom
                                className="bordered-blue"
                                title={t('ammPoolsRedeemButton')}
                                onClick={() => {
                                  handleShow(true, "redeem", item);
                                }}
                              />
                            </td>
                          </div>
                          <div role="button" className="liquidity-pool-switch-panel-container" onClick={() => handleButtonClick(index)}>
                            <span className="liquidity-pool-switch-panel-img">
                              <PanelSwitchIcon />
                            </span>
                            <div className="liquidity-pool-switch-panel-text">
                              {t('ammPoolsViewPoolDetails')}
                            </div>
                          </div>
                        </div>
                      </div>
                    </CommonCard>
                  )}

                  {/* Code start for "view pool details" */}
                  {showUpdatedContent[index] && (
                    <CommonCard>
                      <div className="liquidity-pool-container" key={index}>
                        <div className="liquidity-pool-sub-container-panel-2">
                          <div className="liquidity-pool-header">
                            <span className="poolIcons">
                              <img
                                className="poolIconOne"
                                src={item?.token0URI}
                                alt=""
                              />
                              <img
                                className="poolIconTwo"
                                src={item?.token1URI}
                                alt=""
                              />
                            </span>
                            <span>
                              <span>{item?.token0Name}</span>
                              <span>/</span>
                              <span>{item?.token1Name}</span>
                            </span>
                          </div>

                          <div className="liquidity-pool-assets-container">
                            <div className="liquidity-pool-assets-container-image">
                              <img
                                className="poolIconOne"
                                src={item?.token0URI}
                                alt=""
                              />
                            </div>
                            <div className="liquidity-pool-assets-token-1-container">
                              <div className="liquidity-pool-assets-token-1">
                                {`${item?.token0Name}`}
                              </div>
                              <div className={`liquidity-pool-assets-token-1-bis ${isRefreshing ? 'text-shadow' : ''}`}>
                                {formatNumberWithMagnitudeSuffix(item?.reserve0)}
                              </div>
                              <div className={`liquidity-pool-assets-token-1-bis ${isRefreshing ? 'text-shadow' : ''}`}>
                              <span style={{ color: 'rgb(169, 169, 169)' }}>{Math.round(item.token0Ratio * 100) / 100}%</span>
                              </div>
                            </div>
                            <div className="liquidity-pool-assets-token-2-container">
                              <div className="liquidity-pool-assets-token-2">
                                {`${item?.token1Name}`}
                              </div>
                              <div className={`liquidity-pool-assets-token-2-bis ${isRefreshing ? 'text-shadow' : ''}`}>
                                {formatNumberWithMagnitudeSuffix(item?.reserve1)}
                              </div>
                              <div className={`liquidity-pool-assets-token-2-bis ${isRefreshing ? 'text-shadow' : ''}`}>
                                <span style={{ color: 'rgb(169, 169, 169)' }}>{Math.round(item.token1Ratio * 100) / 100}%</span>
                              </div>
                            </div>
                            <div className="liquidity-pool-assets-container-image">
                              <img
                                className="poolIconTwo"
                                src={item?.token1URI}
                                alt=""
                              />
                            </div>
                          </div>

                          <div className="liquidity-pool-current-price-container">
                            <div className="liquidity-pool-current-price-title">
                              <p>
                                {t('ammPoolsCurrentPrice')}
                              </p>
                            </div>
                            <div className="liquidity-pool-current-price">
                              {displayValues[index] ? (
                                <p className={`liquidity-pool-current-price-text ${isRefreshing ? 'text-shadow' : ''}`}>
                                  <span>1</span>
                                  <span>{item?.token0Name}</span>
                                  <span>=</span>
                                  <span>{ item?.token1DollarValue && item?.token0DollarValue 
                                            ? formatNumberWithMagnitudeSuffix(item?.reserve1 / item?.reserve0)
                                            : "0.000000"
                                        }</span>
                                  <span>{item?.token1Name}</span>
                                </p>
                              ) : (
                                <p className={`liquidity-pool-current-price-text ${isRefreshing ? 'text-shadow' : ''}`}>
                                  <span>1</span>
                                  <span>{item?.token1Name}</span>
                                  <span>=</span>
                                  <span>{ item?.token1DollarValue && item?.token0DollarValue 
                                            ? formatNumberWithMagnitudeSuffix(item?.reserve0 / item?.reserve1)
                                            : "0.000000"
                                          }</span>
                                  <span>{item?.token0Name}</span>
                                </p>
                              )}

                              {/*
                              <button className="swapBtn" onClick={handleClick}>
                                <SwapIcon />
                              </button>
                              */}

                              <button className="swapBtn" onClick={() => handleClick(index)}>
                                <SwapIcon />
                              </button>
                            </div>
                          </div>

                          <div className="liquidity-pool-details-container">
                            <table className="liquidity-pool-details-table">
                              <tbody>
                                <tr>
                                  <td className="liquidity-pool-details-table-column1">{t('ammPoolsLockedAssetValueColumn')}</td>
                                  <td className="liquidity-pool-details-table-column2">{t('ammPoolsMyPoolShareColumn')}</td>
                                </tr>
                                <tr>
                                  <td className={`liquidity-pool-details-table-column1 ${isRefreshing ? 'text-shadow' : ''}`}>
                                    <p>
                                      <span>$</span>
                                      <span>{formatNumberWithMagnitudeSuffix(item?.userShareDollarValue)}</span>
                                    </p>
                                  </td>
                                  <td className={`liquidity-pool-details-table-column2 ${isRefreshing ? 'text-shadow' : ''}`}>
                                    <p>
                                      <span>{`${item?.userShare ? toCustomFixed(item?.userShare, 2) : "0.00"} `}</span>
                                      <span>%</span>
                                    </p>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="liquidity-pool-buttons">
                            <td>
                              <ButtonCustom
                                className="bordered-blue"
                                title={t('ammPoolsEarnButton')}
                                onClick={() => {
                                  handleShow(true, "earn", item);
                                }}
                              />
                              <ButtonCustom
                                className="bordered-blue"
                                title={t('ammPoolsRedeemButton')}
                                onClick={() => {
                                  handleShow(true, "redeem", item);
                                }}
                              />
                            </td>
                          </div>
                          <div role="button" className="liquidity-pool-switch-panel-container" onClick={() => handleButtonClick(index)}>
                            <span className="liquidity-pool-switch-panel-img">
                              <PanelSwitchIcon />
                            </span>
                            <div className="liquidity-pool-switch-panel-text">
                              {t('ammPoolsViewPool')}
                            </div>
                          </div>
                        </div>
                      </div>
                    </CommonCard>
                  )}

                </div>

              );


            })
            : notLoaded ? (
              <p>{t('failedloading')}</p>
            ) : (
              <p>{t('loading')}</p>
            )
          }

        </div>
      )}


      {/*  Code start for table view */}
      {buttonPanelClicked && (
        <CommonCard>
          <CustomTable
            sortIcons
            fields={[
              {
                key: "pool",
                label: t('ammPoolsPool')
              },
              {
                key: "myPoolShares",
                label: t('ammPoolsPositions')
              },
              {
                key: "lockedLPTokens",
                label: t('ammPoolsLiquidity')
              },
              {
                key: "volume",
                label: t('ammPoolsVolume')
              },
              {
                key: "apr",
                label: t('ammPoolsAPR')
              },
              {
                key: "",
                label: ""
              },
            ]}
            updateSortedData={updateSortedData}
            loader={searchResults.length !== 0 ? false : Loader}
            scrollingBar={true}
            tableRef={tableRef}
          >

            { !Loader && !notLoaded || searchResults.length !== 0
              ? searchResults?.map((item: POOLS["data"][1]) => {
                return (
                  <tr>
                    <td>
                      <span className="poolIcons">
                        <img
                          className="poolIconOne"
                          src={item?.token0URI}
                          alt=""
                        />
                        <img
                          className="poolIconTwo"
                          src={item?.token1URI}
                          alt=""
                        />
                      </span>
                      <span>
                        <span>{item?.token0Name}</span>
                        <span>/</span>
                        <span>{item?.token1Name}</span>
                      </span>
                    </td>
                    <td className={`text-decoration ${isRefreshing ? 'text-shadow' : ''}`}>
                      <span>{`${item?.userShare ? toCustomFixed(item?.userShare, 2) : "0.00"} `}</span>
                      <span>%</span>
                    </td>
                    <td className={`text-decoration ${isRefreshing ? 'text-shadow' : ''}`}>
                      {item?.totalLPAmount ? (
                        <Tooltip
                          className="wallet_text_tooltip"
                          heading={item?.totalLPAmount.toString()}
                          icon={cryptoDecimals(item?.totalLPAmount)}
                        />
                      ) : (
                        "0.00"
                      )}
                    </td>
                    <td className={`${isRefreshing ? 'text-shadow' : ''}`}>
                      {item?.volumeInUSD ? (
                        <Tooltip
                          className="wallet_text_tooltip"
                          heading={item?.volumeInUSD.toString()}
                          icon={cryptoDecimals(item?.volumeInUSD)}
                        />
                      ) : (
                        "0.00"
                      )}
                    </td>
                    <td className={`text-decoration ${isRefreshing ? 'text-shadow' : ''}`}>2.01%</td>
                    <td>
                      <ButtonCustom
                        className="bordered-blue"
                        title={t('ammPoolsEarnButton')}
                        onClick={() => {
                          handleShow(true, "earn", item);
                        }}
                      />
                      <ButtonCustom
                        className="bordered-blue"
                        title={t('ammPoolsRedeemButton')}
                        onClick={() => {
                          handleShow(true, "redeem", item);
                        }}
                      />
                    </td>
                  </tr>
                );
              })
              : t('dataFetching')}
          </CustomTable>
        </CommonCard>
      )}
      {/*  Code end for table view */}

      <EarnModal
        show={show}
        handleClose={() => setShow(false)}
        showTab={showTab}
        handlePools={handlePools}
        showSettingsModal={() => {
          setModalShow(true);
        }}
        selectedPool={poolData || ""}
        isRefreshing={isRefreshing}
      />
      <SettingModal
        show={ModalShow}
        handleClose={() => {
          setModalShow(false);
        }}
      />
    </section>
  );
};

export default EarnAMMPools;