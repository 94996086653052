export enum Status {
  Pending = 'pending',
  Complete = 'complete',
}

export type StatusCheck = Status | "";

export interface GET_USER {
  message: string;
  status: number;
  error: boolean;
  walletStatus: string;
  data: {
    createdAt: string;
    deadlineInMin: number | null;
    image: string | null;
    label: string | null;
    lock: boolean | null;
    name: string | null;
    firstname: string | null;
    lastname: string | null;
    birthDate: Date | null;
    embeddedWallet: boolean;
    kyc: { isCompleted: boolean | null, id: string | null, documents?: { documentCheck?: { id?: string, status?: StatusCheck, outcome?: string }, enhancedIdCheck?: { id?: string, status?: StatusCheck, outcome?: string } } };
    passkey: boolean;
    signature: string | null;
    slippage: string | null;
    termsncond: boolean;
    theme: string | null;
    updatedAt: string;
    value: number | null;
    walletAddress: string;
    _id: string;
    emailAddress?: string;
    userId?: string;
    balance?: { name: string; token: string; balance: string }[];
  };
}
export interface DATA_INSERTED {
  error: boolean;
  message: string;
  status: number;
}
export interface ORDER {
  pairAddress: string;
  assetIn: string;
  assetOut: string;
  amountsIn: string;
  amountsOut: string;
  userAddress: string;
  rate: string;
  salt: number;
  expiryTime: number;
  callData: string;
  signature: string;
  dispatch: any;
  message: string;
  chainId: number;
}
export interface USER_ASSETS {
  message: string;
  status: number;
  error: boolean;
  data?: {
    assetAddress: string;
    assetName: string;
    balance: number;
    change: number;
    marketPrice: number;
    openPrice: number;
  }[];
}
export type PORTFOLIO_GRAPH = {
  message: string;
  status: number;
  error: boolean;
  count: number;
  data: {
    amount: number;
    finalTimestamp: number;
    initialTimestamp: number;
    pair0In1Out: string;
  }[];
};

export type NEWS = {
  date: string;
  id: string;
  sourceName: string;
  title: string;
  url: string;
}[];

export interface ORDER_BOOK {
  message: string;
  status: number;
  error: boolean;
  data?: any;
  latestTrade?: any;
}

export interface DOLLAR_VAL {
  message: string;
  status: number;
  error: boolean;
  data: {
    assetAddress: string;
    assetName: string;
    price: number;
  }[];
}
export interface RECENT_TRNX {
  message: string;
  status: number;
  error: boolean;
  count: number;
  data: {}[];
}
export interface WHATS_HOT {
  pair: string,
  image: string,
  vol: number,
  analyst: string,
  link: string,
  percentage: number
}

export interface TOP_LIQUIDITY_POOL {
  name: string,
  image: string,
  description: string,
  type: string,
  apy: number,
  volume: number,
  link: string
}

export interface SHARIAH_TOKEN {
  _id: string;
  id: string;
  token_name: string;
  token_erc_address: string;
  token_symbol: string;
  token_image: string;
  about: string;
  website: string;
  twitter: string;
  telegram: string;
  discord: string;
  github: string;
  speedometer: number,
  price: number;
  volume: number;
  variation: number;
  supply: number;
  circulation: number;
  fundamental_score: number;
  function_score: number;
  transparency_score: number;
  reputation_score: number;
  volatility_score: number;
  fundamental_overview: string;
  function_overview: string;
  transparency_overview: string;
  reputation_overview: string;
  volatility_overview: string;
  shariah_review: string;
  updated_at: string;
  created_at: string;
}

export interface POOLS {
  message: string;
  status: number;
  error: boolean;
  data:
    | {
        blockNumber: number;
        pairAddress: string;
        token0Address: string;
        token0Name: string;
        decimals0: number;
        token0URI: string;
        reserve0: number;
        token0Ratio: number;
        token0DollarValue: number;
        token1Address: string;
        token1Name: string;
        decimals1: number;
        token1URI: string;
        reserve1: number;
        token1Ratio: number;
        token1DollarValue: number;
        totalLPAmount: number;
        LPDecimals: number;
        TVL: number;
        volume: number;
        volumeInUSD: number;
        userLPBalance: number;
        userShare: number;
        userShareDollarValue: number;
        userToken0Balance: number;
        userToken1Balance: number;
      }[];
}

export const DATA = {
  blockNumber: 0,
  pairAddress: "",
  token0Address: "",
  token0Name: "",
  decimals0: 0,
  token0URI: "",
  reserve0: 0,
  token0Ratio: 0,
  token0DollarValue: 0,
  token1Address: "",
  token1Name: "",
  decimals1: 0,
  token1URI: "",
  reserve1: 0,
  token1Ratio: 0,
  token1DollarValue: 0,
  totalLPAmount: 0,
  LPDecimals: 0,
  TVL: 0,
  volume: 0,
  volumeInUSD: 0,
  userLPBalance: 0,
  userShare: 0,
  userShareDollarValue: 0,
  userToken0Balance: 0,
  userToken1Balance: 0
};

export interface ORDER_HISTORY {
  message: string;
  status: number;
  error: boolean;
  data: {}[];
}
export interface CANCEL_ORDER {
  message: string;
  status: number;
  error: boolean;
  data: [];
}
export interface FETCH_USER_DATA {
  message: string;
  status: number;
  error: boolean;
  data: {
    last7DaysTransactionCount: number
  };
}
