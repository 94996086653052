import {
  newRemoveLiquiditySig,
  permitSignature
} from "../interfaces/contractCallInterfaces";
import { GET_AMOUNTS_DATA } from "../interfaces/commonInterfaces";
import { Hex, isHex, toBytes, pad, toHex, encodeFunctionData, isAddress, TransactionReceipt, SignTypedDataParameters } from "viem";
import { ethers } from 'ethers';
import DynamicABI from "../Abi/DynamicABI.json";
import IcoAbi from "../Abi/Ico.ABI.json";
import FactoryABI from "../Abi/FactoryABI.json";
import RouterABI from "../Abi/RouterABI.json";
import PairABI from "../Abi/PairABI.json";
import LimitABI from "../Abi/LimitOrderABI.json";
import { fromWeiConvert, divideWithDecimal, toCustomFixed } from "../Services/common.service";
import { dynamicContractDetails } from "../Services/dynamicContractDetails";
import { estimateGas, readContract, writeContract, simulateContract, getBalance, getChainId, signTypedData, waitForTransactionReceipt } from '@wagmi/core'
import { WagmiConfig} from "../WagmiConfig";
import { storeInstance } from "../Services/axios.service";
import { MAX_APPROVAL, hundred, ZERO_ADDRESS } from "../Utils";
import { splitSignature as ethersSplitSignature } from 'ethers/lib/utils';
import { BigNumber } from 'ethers';
import { GAS_LIMIT_BUFFER } from "../Utils";
import moment from "moment";
import { addBreadcrumb } from './../SentryContext';


const routerAddress: string | undefined = dynamicContractDetails?.find(
  (data) => data.symbol == "router"
)?.address;

// Slippage
const { TransactionDeadline } : { slippage: number, TransactionDeadline: number } = storeInstance?.getState()?.user;

export function getDeadline() {
    const currentTime = moment().unix();
    const deadline = currentTime + (TransactionDeadline * 60);
    return deadline;
  }

export function timeoutPromise<T>(promise: Promise<T>, timeout: number): Promise<T> {
    return Promise.race([
        promise,
        new Promise<T>((_, reject) =>
            setTimeout(() => reject(new Error('Promise timed out')), timeout)
        ),
    ]);
}

export const handleWriteContractForETH = async (method, amountWithSlippageTolerance, data): Promise<TransactionReceipt> => {
    try {
        // Input Validation
        addBreadcrumb('handleWriteContractForETH', '[handleWriteContractForETH] Input Data', 'info', { method: method, data: data, amountWithSlippageTolerance: amountWithSlippageTolerance })
        if (BigInt(data.amountIn) <= 0n || BigInt(parseInt(amountWithSlippageTolerance)) <= 0n) {
            throw new Error("Amount Validation Failed...");
        }

        if (!data.path || !Array.isArray(data.path) || data.path.length < 2) {
            throw new Error("Token Path Validation Failed...");
        }

        const currentTimestamp = Math.floor(Date.now() / 1000);
        if (data.deadLine <= currentTimestamp) {
            throw new Error("Deadline Validation Failed...");
        }
        addBreadcrumb('handleWriteContractForETH', '[handleWriteContractForETH] Input Validation Successful', 'info');

        // Estimate gas
        const encodedData = encodeFunctionData({
            abi: RouterABI,
            functionName: method,
            args: [
                BigInt(parseInt(amountWithSlippageTolerance)),
                data.path,
                data.to,
                data.deadLine
            ],
        });

        const gasEstimate = await LibfiService.getEstimatedGas(routerAddress as Hex, encodedData, BigInt(Number(data.amountIn)));

        // Compute transaction gasLimit
        const gasLimit = BigNumber.from(gasEstimate).mul(BigNumber.from(GAS_LIMIT_BUFFER)).div(BigNumber.from(100)).toBigInt();
        addBreadcrumb('handleWriteContractForETH', '[handleWriteContractForETH] Computed gasLimit', 'info', { gasBuffer: GAS_LIMIT_BUFFER, gasLimit: gasLimit });

        addBreadcrumb('handleWriteContractForETH', 
            '[handleWriteContractForETH] simulateContract Input Data', 
            'info',
            {
                address: routerAddress as Hex, 
                //abi: RouterABI,
                functionName: method,
                args: [
                    BigInt(parseInt(amountWithSlippageTolerance)),
                    data.path,
                    data.to,
                    data.deadLine
                ],
                value: BigInt(Number(data.amountIn)),
                gas: gasLimit
            }
        );

        // Simulate contract
        const preparedTx = await simulateContract(WagmiConfig, {
            address: routerAddress as Hex, 
            abi: RouterABI,
            functionName: method,
            args: [
                BigInt(parseInt(amountWithSlippageTolerance)),
                data.path,
                data.to,
                data.deadLine
            ],
            value: BigInt(Number(data.amountIn)),
            gas: gasLimit
        });
        addBreadcrumb('handleWriteContractForETH', '[handleWriteContractForETH] simulateContract Output Data', 'info', { preparedTxOutput: preparedTx });

        // Execute transaction
        const result = await writeContract(WagmiConfig, preparedTx.request);
        addBreadcrumb('handleWriteContractForETH', '[handleWriteContractForETH] Transaction Submitted', 'info', { Output: result });

        // Wait For Transaction Execution
        const waitForTransactionReceipt = await LibfiService.waitForTransactionReceipt(result);

        return waitForTransactionReceipt;

    } catch (error) {
        addBreadcrumb('handleWriteContractForETH', '[handleWriteContractForETH] Error Occured', 'info', { errorMessage: error });
        throw new Error(error instanceof Error ? error.message : 'Unknown Error Occurred');

    }
};


export const handleWriteContractForTokens = async (method, amountWithSlippageTolerance, data): Promise<TransactionReceipt> => {
    try {
        // Input Validation
        addBreadcrumb('handleWriteContractForTokens', '[handleWriteContractForTokens] Input Data', 'info', { method: method, data: data, amountWithSlippageTolerance: amountWithSlippageTolerance })
        if (BigInt(data.amountIn) <= 0n || BigInt(parseInt(amountWithSlippageTolerance)) <= 0n) {
            throw new Error("Amount Validation Failed...");
        }

        if (!data.path || !Array.isArray(data.path) || data.path.length !== 2) {
            throw new Error("Token Path Validation Failed...");
        }

        const currentTimestamp = Math.floor(Date.now() / 1000);
        if (data.deadLine <= currentTimestamp) {
            throw new Error("Deadline Validation Failed...");
        }
        addBreadcrumb('handleWriteContractForTokens', '[handleWriteContractForTokens] Input Validation Successful', 'info');


        // Estimate gas
        const encodedData = encodeFunctionData({
            abi: RouterABI,
            functionName: method,
            args: [
                BigInt(data.amountIn),
                BigInt(parseInt(amountWithSlippageTolerance)),
                data.path,
                data.to,
                data.deadLine
            ]
        });

        const gasEstimate = await LibfiService.getEstimatedGas(routerAddress as Hex, encodedData);

        // Compute transaction gasLimit
        const gasLimit = BigNumber.from(gasEstimate).mul(BigNumber.from(GAS_LIMIT_BUFFER)).div(BigNumber.from(100)).toBigInt();
        addBreadcrumb('handleWriteContractForTokens', '[handleWriteContractForTokens] Computed gasLimit', 'info', { gasBuffer: GAS_LIMIT_BUFFER, gasLimit: gasLimit });

        addBreadcrumb('handleWriteContractForTokens', 
            '[handleWriteContractForTokens] simulateContract Input Data', 
            'info',
            {
                address: routerAddress as Hex,
                //abi: RouterABI,
                functionName: method,
                args: [
                    BigInt(data.amountIn),
                    BigInt(parseInt(amountWithSlippageTolerance)),
                    data.path,
                    data.to,
                    data.deadLine
                ],
                gas: gasLimit
            }
        );

        // Simulate contract
        const preparedTx = await simulateContract(WagmiConfig, {
            address: routerAddress as Hex,
            abi: RouterABI,
            functionName: method,
            args: [
                BigInt(data.amountIn),
                BigInt(parseInt(amountWithSlippageTolerance)),
                data.path,
                data.to,
                data.deadLine
            ],
            gas: gasLimit
        });

        addBreadcrumb('handleWriteContractForTokens', '[handleWriteContractForTokens] simulateContract Output Data', 'info', { preparedTxOutput: preparedTx });

        // Execute transaction
        const result = await writeContract(WagmiConfig, preparedTx.request);
        addBreadcrumb('handleWriteContractForTokens', '[handleWriteContractForTokens] Transaction Submitted', 'info', { Output: result });

        // Wait For Transaction Execution
        const waitForTransactionReceipt = await LibfiService.waitForTransactionReceipt(result);

        return waitForTransactionReceipt;

    } catch (error) {
        addBreadcrumb('handleWriteContractForTokens', '[handleWriteContractForTokens] Error Occured', 'info', { errorMessage: error });
        throw new Error(error instanceof Error ? error.message : 'Unknown Error Occurred');
    }
};


export const getTotalReservesOfTokensInPool = async (pairAddress, token0Address, token1Address): Promise<{ _reserve0: number; _reserve1: number }> => {
    if (pairAddress.toLowerCase() === ZERO_ADDRESS?.toLowerCase()) {
        return { _reserve0: 0, _reserve1: 0 };
    }

    try {
        const [
            reserves,
            tokens,
            decimals0,
            decimals1
        ] = await Promise.all([
            timeoutPromise(LibfiService.getReserves(pairAddress), 10000),
            timeoutPromise(LibfiService.getTokensThroughPairAddress(pairAddress), 10000),
            timeoutPromise(LibfiService.getDecimals(token0Address), 10000),
            timeoutPromise(LibfiService.getDecimals(token1Address), 10000)
        ]);
  
        const reserve0 = Number(reserves[0]) / 10 ** decimals0;
        const reserve1 = Number(reserves[1]) / 10 ** decimals1;
  
        if (token0Address?.toLowerCase() == tokens.token0.toString().toLowerCase()) {
            return { _reserve0: reserve0, _reserve1: reserve1 };
        } else {
            return { _reserve0: reserve1, _reserve1: reserve0 };
        }

    } catch (error) {
        return { _reserve0: 0, _reserve1: 0 };
    }
};


export const getRemoveLiquiditySignature = async (data: newRemoveLiquiditySig): Promise<permitSignature> => {
    const { walletAddress, pairAddress, liquidity, deadLine } = data;
  
    const owner = walletAddress;
    const spender = routerAddress;
    const value = liquidity;
    let chainId = getChainId(WagmiConfig)

    try {
        // Input Validation
        addBreadcrumb('LiquidityRemoval', '[getRemoveLiquiditySignature] Input Data', 'info', { walletAddress: walletAddress, pairAddress: pairAddress, liquidity: liquidity, routerAddress: spender, chainId: chainId, deadLine: deadLine });
        if (BigInt(value) < 0n) {
            throw new Error("Amount Validation Failed...");
        }

        if (!isAddress(walletAddress) || !isAddress(pairAddress)) {
            throw new Error("Address Validation Failed...");
        }

        if (!spender) {
            throw new Error("Router Address Validation Failed...");
        }

        if (!chainId) {
            throw new Error("chainId Validation Failed...");
        }

        const currentTimestamp = Math.floor(Date.now() / 1000);
        if (deadLine <= currentTimestamp) {
            throw new Error("Deadline Validation Failed...");
        }
        addBreadcrumb('LiquidityRemoval', '[getRemoveLiquiditySignature] Input Validation Successful', 'info');
  
    
        const nonce = await LibfiService.getPairNonces(walletAddress, pairAddress);
          
        // Domain Definition
        const domain = {
            name: "Libfi V2",
            version: "1",
            chainId,
            verifyingContract: pairAddress,
        };
        
        // Types definition
        const types: SignTypedDataParameters['types'] = {
            EIP712Domain: [
              { name: 'name', type: 'string' },
              { name: 'version', type: 'string' },
              { name: 'chainId', type: 'uint256' },
              { name: 'verifyingContract', type: 'address' },
            ],
            Permit: [
              { name: 'owner', type: 'address' },
              { name: 'spender', type: 'address' },
              { name: 'value', type: 'uint256' },
              { name: 'nonce', type: 'uint256' },
              { name: 'deadline', type: 'uint256' },
            ],
          };

        // Message Definition
        const message = {
            owner,
            spender,
            value,
            nonce: nonce,
            deadline: deadLine
        };
        
        addBreadcrumb('LiquidityRemoval', '[signTypedData] Input Data', 'info', { domain, types, primaryType: "Permit", message });
        
        const signature = await signTypedData(WagmiConfig, {
            domain,
            types,
            primaryType: "Permit",
            message
        });
        
        addBreadcrumb('LiquidityRemoval', '[signTypedData] Output Data', 'info', { signature });

        const splits = await splitSignature(signature);
        return splits;

    } catch (error) {
        addBreadcrumb('LiquidityRemoval', '[getRemoveLiquiditySignature] Error Occured', 'info', { errorMessage: error });
        throw new Error(error instanceof Error ? error.message : 'Unknown Error Occurred');
    }
};

const splitSignature = (signature: string): permitSignature => {
    try {
        // Input Validation
        addBreadcrumb('LiquidityRemoval', '[splitSignature] Input Data', 'info', { signature: signature });
        if (!isHex(signature) || signature.length !== 132) {
            throw new Error("Invalid signature string; must be 65 bytes");
        }
        addBreadcrumb('LiquidityRemoval', '[splitSignature] Input Validation Successful', 'info');

        const { r, s, v } = ethersSplitSignature(signature);

        const recoveryParam = v < 27 ? v : 1 - (v % 2);

        const sBytes = toBytes(s);
        if (recoveryParam) {
            sBytes[0] |= 0x80;
        }

        const _vs = toHex(pad(sBytes, { size: 32 }));

        addBreadcrumb('LiquidityRemoval', '[splitSignature]  Output Data', 'info', { r: r, s: s, v: v, recoveryParam: recoveryParam, _vs: _vs });
        return { r, s, v, recoveryParam, _vs };

    } catch (error) {
        addBreadcrumb('LiquidityRemoval', '[splitSignature] Error Occured', 'info', { errorMessage: error });
        throw new Error(error instanceof Error ? error.message : 'Unknown Error Occurred');
    }
};



const LibfiService = {

    getEstimatedGas: async (to, encodedData, value?): Promise<bigint> => {
        try {
            // Input Validation
            addBreadcrumb('EstimateGas', '[getEstimatedGas] Input Data', 'info', { to: to, encodedData: encodedData, value: value });
            if (!isAddress(to)) {
                throw new Error("Address Validation Failed...");
            }

            if (!encodedData) {
                throw new Error("encodedData Validation Failed...");
            }

            if (value && BigInt(value) < 0n) {
                throw new Error("Amount Validation Failed...");
            }
            addBreadcrumb('EstimateGas', '[getEstimatedGas] Input Validation Successful', 'info');

            addBreadcrumb(
                'getEstimatedGas', 
                'estimatedGas Input Data', 
                'info', 
                { 
                    to: to as Hex,
                    data: encodedData, 
                    ...(value !== undefined && { value: value })
                }
            );

            const estimatedGas = await estimateGas(WagmiConfig, {
                to: to as Hex,
                data: encodedData, 
                ...(value !== undefined && { value: value })
            });

            addBreadcrumb('EstimateGas', '[getEstimatedGas] Output Data', 'info', { Output: estimatedGas });

            // Output Validation
            if (typeof estimatedGas !== 'bigint') {
                throw new Error("Unexpected Return From Contract");
            }

            return estimatedGas;

        } catch (error) {
            addBreadcrumb('EstimateGas', '[getEstimatedGas] Error Occured', 'info',  { errorMessage: error });
            throw new Error(error instanceof Error ? error.message : 'Unknown Error Occurred');
        }
    }, 
   
    waitForTransactionReceipt: async (txHash): Promise<TransactionReceipt> => {
        try {
            // Input Validation
            addBreadcrumb('TransactionConfirmation', '[waitForTransactionReceipt] Input Data', 'info', { txHash: txHash });
            if (!ethers.utils.isHexString(txHash, 32)) {
                throw new Error("Address Validation Failed...");
            }
            addBreadcrumb('TransactionConfirmation', '[waitForTransactionReceipt] Input Validation Successful', 'info');

            addBreadcrumb('TransactionConfirmation', '[waitForTransactionReceipt] transactionReceipt Input Data', 'info', { hash: txHash as Hex });
            const transactionReceipt = await waitForTransactionReceipt(WagmiConfig, {
                hash: txHash as Hex,
            })
            addBreadcrumb('TransactionConfirmation', '[waitForTransactionReceipt] waitForTransactionReceipt Output Data', 'info', { Output: transactionReceipt });

            return transactionReceipt;

        } catch (error) {
            addBreadcrumb('TransactionConfirmation', '[waitForTransactionReceipt] Error Occured', 'info',  { errorMessage: error });
            throw new Error(error instanceof Error ? error.message : 'Unknown Error Occurred');
        }
    }, 

    getDecimals: async (tokenAddress): Promise<number>  => {
        try {
            // Input Validation
            if (!isAddress(tokenAddress)) {
                throw new Error("Address Validation Failed...");
            }

            const decimals = await readContract(WagmiConfig, {
                address: tokenAddress as Hex, 
                abi: DynamicABI,
                functionName: "decimals",
                args: [],
              });
              
            // Output Validation
            if (typeof decimals !== 'number') {
                throw new Error("Unexpected Return From Contract");
            }

            //console.log('getDecimals Result:', decimals);
            return decimals;

        } catch (error) {
            //console.error('Error getDecimals:', error instanceof Error ? error.message : 'Unknown Error Occurred');
            throw new Error(error instanceof Error ? error.message : 'Unknown Error Occurred');
        }
    }, 

    getNativeTokenBalance: async (walletAddress): Promise<{ res: bigint, calculatedBalance: Number }> => {
        try {
            // Input Validation
            if (!isAddress(walletAddress)) {
                throw new Error("Address Validation Failed...");
            }

            const result = await getBalance(WagmiConfig, {
                address: walletAddress as `0x${string}`,
                unit: 'ether', 
            });
            
            const nativeTokenBalance = result.value;

            // Output Validation
            if (typeof nativeTokenBalance !== 'bigint') {
                throw new Error("Unexpected Return From Contract");
            }

            const calculatedBalance: Number = toCustomFixed(fromWeiConvert(nativeTokenBalance), 4);
            
            //console.log('getNativeTokenBalance Result:', { res: Number(nativeTokenBalance), calculatedBalance });
            return { res: nativeTokenBalance, calculatedBalance };

        } catch (error) {
            //console.error('Error getNativeTokenBalance:', error instanceof Error ? error.message : 'Unknown Error Occurred');
            throw new Error(error instanceof Error ? error.message : 'Unknown Error Occurred');
        }
    },  

    getTokenBalance: async (walletAddress, tokenAddress): Promise<{ res: bigint, calculatedBalance: Number }> => {
        try {
            // Input Validation
            if (!isAddress(walletAddress) || !isAddress(tokenAddress)) {
                throw new Error("Address Validation Failed...");
            }

            const tokenBalance = await readContract(WagmiConfig, {
                address: tokenAddress as Hex, 
                abi: DynamicABI,
                functionName: "balanceOf",
                args: [ walletAddress as Hex, ]
            });
            
            // Output Validation
            if (typeof tokenBalance !== 'bigint') {
                throw new Error("Unexpected Return From Contract");
            }

            const decimals = await LibfiService.getDecimals(tokenAddress)
            const calculatedBalance: Number = toCustomFixed(divideWithDecimal(tokenBalance, decimals), 4);

            //console.log('getTokenBalance Result:', { res: Number(tokenBalance), calculatedBalance });
            return { res: tokenBalance, calculatedBalance };

        } catch (error) {
            console.error('Error getTokenBalance:', error instanceof Error ? error.message : 'Unknown Error Occurred');
            throw new Error(error instanceof Error ? error.message : 'Unknown Error Occurred');
        }
    },  

    getAmountsOut: async (data: GET_AMOUNTS_DATA): Promise<[bigint,bigint]> => {
        try {
            const { amountIn, token0Address, token1Address } = data;

            // Input Validation
            if (!isAddress(token0Address) || !isAddress(token1Address)) {
                throw new Error("Address Validation Failed...");
            }

            if (BigInt(amountIn) < 0n) {
                throw new Error("Amount Validation Failed...");
            }

            const path: string[] = [token0Address, token1Address];

            const amountsOut = await readContract(WagmiConfig, {
                address: routerAddress as Hex, 
                abi: RouterABI,
                functionName: "getAmountsOut",
                args: [amountIn, path]
            });

            if (!Array.isArray(amountsOut) || amountsOut.length !== 2 || typeof amountsOut[0] !== 'bigint' || typeof amountsOut[1] !== 'bigint') {
                throw new Error("Unexpected Return From Contract");
            }
 
            return [amountsOut[0], amountsOut[1]];

        } catch (error) {
            //console.error('Error getAmountsOut:', error instanceof Error ? error.message : 'Unknown Error Occurred');
            throw new Error(error instanceof Error ? error.message : 'Unknown Error Occurred');
        }
    },  

    getAmountsIn: async (data: GET_AMOUNTS_DATA): Promise<[bigint,bigint]> => {
        try {
            const { amountIn, token0Address, token1Address } = data;

            // Input Validation
            if (!isAddress(token0Address) || !isAddress(token1Address)) {
                throw new Error("Address Validation Failed...");
            }

            if (BigInt(amountIn) < 0n) {
                throw new Error("Amount Validation Failed...");
            }

            const path: string[] = [token0Address, token1Address];

            const amountsIn = await readContract(WagmiConfig, {
                address: routerAddress as Hex, 
                abi: RouterABI,
                functionName: "getAmountsIn",
                args: [amountIn, path]
            });

            if (!Array.isArray(amountsIn) || amountsIn.length !== 2 || typeof amountsIn[0] !== 'bigint' || typeof amountsIn[1] !== 'bigint') {
                throw new Error("Unexpected Return From Contract");
            }
 
            return [amountsIn[0], amountsIn[1]];

        } catch (error) {
            console.error('Error getAmountsIn:', error instanceof Error ? error.message : 'Unknown Error Occurred');
            throw new Error(error instanceof Error ? error.message : 'Unknown Error Occurred');
        }
    },  

    getTokenAllowance: async (walletAddress, tokenAddress): Promise<bigint> => {
        try {
            // Input Validation
            addBreadcrumb('TokenAllowance', '[getTokenAllowance] Input Data', 'info', { walletAddress: walletAddress, tokenAddress: tokenAddress });
            if (!isAddress(walletAddress) || !isAddress(tokenAddress) ) {
                throw new Error("Address Validation Failed...");
            }
            addBreadcrumb('TokenAllowance', '[getTokenAllowance] Input Validation Successful', 'info');


            addBreadcrumb('TokenAllowance', '[getTokenAllowance] Input Data', 'info', 
                {
                    address: tokenAddress as Hex, 
                    //abi: DynamicABI,
                    functionName: "allowance",
                    args: [
                        walletAddress as Hex,
                        routerAddress as Hex,
                    ]
                }
            );

            const result = await readContract(WagmiConfig, {
                address: tokenAddress as Hex, 
                abi: DynamicABI,
                functionName: "allowance",
                args: [
                    walletAddress as Hex,
                    routerAddress as Hex,
                ]
            });
            addBreadcrumb('TokenAllowance', '[getTokenAllowance] Data Output', 'info', { Output: result });

            // Output Validation
            if (typeof result !== 'bigint') {
                throw new Error("Unexpected Return From Contract");
            }

            const tokenAllowance = BigInt(fromWeiConvert(result).split(".")[0]);
            addBreadcrumb('TokenAllowance', '[getTokenAllowance] Converted Data Output', 'info', { tokenAllowance: tokenAllowance });

            return tokenAllowance;

        } catch (error) {
            addBreadcrumb('TokenAllowance', '[getTokenAllowance] Error Occured', 'info',  { errorMessage: error });
            throw new Error(error instanceof Error ? error.message : 'Unknown Error Occurred');
        }
    },

    getTokenApproval: async (tokenAddress): Promise<TransactionReceipt> => {
        try {
            // Input Validation
            addBreadcrumb('TokenApproval', '[getTokenApproval] Input Data', 'info', { tokenAddress: tokenAddress });
            if (!isAddress(tokenAddress)) {
                throw new Error("Address Validation Failed...");
            }
            addBreadcrumb('TokenApproval', '[getTokenApproval] Input Validation Successful', 'info');

            addBreadcrumb('TokenApproval', '[getTokenApproval] simulkateContract Input Data', 'info', 
                {
                    address: tokenAddress as Hex, 
                    //abi: DynamicABI,
                    functionName: "approve",
                    args: [
                        routerAddress as Hex,
                        MAX_APPROVAL
                    ],
                }
            );

            const preparedTx = await simulateContract(WagmiConfig, {
                address: tokenAddress as Hex, 
                abi: DynamicABI,
                functionName: "approve",
                args: [
                    routerAddress as Hex,
                    MAX_APPROVAL
                ],
            });

            addBreadcrumb('TokenApproval', '[getTokenApproval] simulkateContract Output Data', 'info', { preparedTxOutput: preparedTx });

            // Execute transaction
            const result = await writeContract(WagmiConfig, preparedTx.request);
            addBreadcrumb('TokenApproval', '[getTokenApproval] Transaction Submitted', 'info', { Output: result });
            
            // Wait For Transaction Execution
            const waitForTransactionReceipt = await LibfiService.waitForTransactionReceipt(result);

            return waitForTransactionReceipt;

        } catch (error) {
            addBreadcrumb('TokenApproval', '[getTokenApproval] Error Occured', 'info',  { errorMessage: error });
            throw new Error(error instanceof Error ? error.message : 'Unknown Error Occurred');
        }
    },

    // AAM Pools
    getTokensThroughPairAddress: async (pairAddress): Promise<{ token0: string, token1: string }> => {
        try {
            // Input Validation
            if (!isAddress(pairAddress)) {
                throw new Error("Address Validation Failed...");
            }

            const [token0, token1] = await Promise.all([

                readContract(WagmiConfig, {
                    address: pairAddress as Hex,
                    abi: PairABI,
                    functionName: "token0",
                    args: []
                }),
                
                readContract(WagmiConfig, {
                    address: pairAddress as Hex,
                    abi: PairABI,
                    functionName: "token1",
                    args: []
                })

            ]);

            // Output Validation
            if (typeof token0 !== 'string' || typeof token1 !== 'string') {
                throw new Error("Unexpected Return From Contract");
            }

            const result = token0 && token1 
                    ? { token0, token1 } 
                    : { token0: ZERO_ADDRESS, token1: ZERO_ADDRESS };

            //console.log('getTokensThroughPairAddress result:', result);
            return result;

        } catch (error) {
            //console.error('Error getTokensThroughPairAddress:', error instanceof Error ? error.message : 'Unknown Error Occurred');
            throw new Error(error instanceof Error ? error.message : 'Unknown Error Occurred');
        }
    },  

    getTotalSupply: async (pairAddress): Promise<bigint> => {
        try {
            // Input Validation
            if (!isAddress(pairAddress)) {
                throw new Error("Address Validation Failed...");
            }

            const totalSupply = await readContract(WagmiConfig, {
                address: pairAddress as Hex, 
                abi: PairABI,
                functionName: "totalSupply",
                args: []
            });   

            // Output Validation
            if (typeof totalSupply !== 'bigint') {
                throw new Error("Unexpected Return From Contract");
            }

            //console.log('getTotalSupply Result:', totalSupply);
            return totalSupply;

        } catch (error) {
            //console.error('Error getTotalSupply:', error instanceof Error ? error.message : 'Unknown Error Occurred');
            throw new Error(error instanceof Error ? error.message : 'Unknown Error Occurred');
        }
    },  

    getReserves: async (pairAddress): Promise<[bigint, bigint]> => {
        try {
            // Input Validation
            if (!isAddress(pairAddress)) {
                throw new Error("Address Validation Failed...");
            }
    
            const reserves = await readContract(WagmiConfig, {
                address: pairAddress as Hex, 
                abi: PairABI,
                functionName: "getReserves",
                args: []
            });
    
            // Check if reserves is an array with at least 3 elements
            if (!Array.isArray(reserves) || reserves.length !== 3) {
                throw new Error("Unexpected Return From Contract");            
            }

            // Check if the first element is a bigint
            if (typeof reserves[0] !== 'bigint' || typeof reserves[1] !== 'bigint' || typeof reserves[1] !== 'bigint' || typeof reserves[2] !== 'number') {
                throw new Error("Unexpected Return From Contract");
            }
    
            return [reserves[0], reserves[1]];
    
        } catch (error) {
            throw new Error(error instanceof Error ? error.message : 'Unknown Error Occurred');
        }
    },

    getLPDecimals: async (pairAddress): Promise<number> => {
        try {
            // Input Validation
            if (!isAddress(pairAddress)) {
                throw new Error("Address Validation Failed...");
            }

            const decimals = await readContract(WagmiConfig, {
                address: pairAddress as Hex, 
                abi: PairABI,
                functionName: "decimals",
                args: [],
            });

            // Output Validation
            if (typeof decimals !== 'number') {
                throw new Error("Unexpected Return From Contract");
            }
            
            //console.log('getLPDecimals Result:', decimals);
            return decimals;

        } catch (error) {
            //console.error('Error getLPDecimals:', error instanceof Error ? error.message : 'Unknown Error Occurred');
            throw new Error(error instanceof Error ? error.message : 'Unknown Error Occurred');
        }
    }, 

    getLPBalance: async (walletAddress, pairAddress): Promise<bigint> => {
        try {
            // Input Validation
            if (!isAddress(walletAddress) || !isAddress(pairAddress)) {
                throw new Error("Address Validation Failed...");
            }

            const LpTokenBalance = await readContract(WagmiConfig, {
                address: pairAddress as Hex, 
                abi: PairABI,
                functionName: "balanceOf",
                args: [ walletAddress as Hex, ]
            });

            // Output Validation
            if (typeof LpTokenBalance !== 'bigint') {
                throw new Error("Unexpected Return From Contract");
            }

            //console.log('getLPBalance Result:', LpTokenBalance);
            return LpTokenBalance;

        } catch (error) {
            //console.error('Error getLPBalance:', error instanceof Error ? error.message : 'Unknown Error Occurred');
            throw new Error(error instanceof Error ? error.message : 'Unknown Error Occurred');
        }
    },

    addETHLiquidity: async (data): Promise<TransactionReceipt> => {

        const { inputOne, inputTwo, NonNativeTokenAddress,amountTokenMin, amountTokenETHMin, deadLine, walletAddress } = data;

        try {
            // Input Validation
            addBreadcrumb('LiquidityAddition', '[addETHLiquidity] Input Data', 'info', { data: data });
            if (BigInt(inputOne) < 0n || BigInt(inputTwo) < 0n || BigInt(amountTokenMin) < 0n || BigInt(amountTokenETHMin) < 0n) {
                throw new Error("Amount Validation Failed...");
            }

            if (!isAddress(walletAddress) || !isAddress(NonNativeTokenAddress)) {
                throw new Error("Address Validation Failed...");
            }

            const currentTimestamp = Math.floor(Date.now() / 1000);
            if (deadLine <= currentTimestamp) {
                throw new Error("Deadline Validation Failed...");
            }
            addBreadcrumb('LiquidityAddition', '[addETHLiquidity] Input Validation Successful', 'info');


            addBreadcrumb('LiquidityAddition', '[addETHLiquidity] simulateContract Input Data', 'info', {             
                address: routerAddress as Hex, 
                //abi: RouterABI,
                functionName: "addLiquidityETH",
                args: [
                    NonNativeTokenAddress as Hex,
                    BigInt(inputTwo),
                    BigInt(amountTokenMin),
                    BigInt(amountTokenETHMin),
                    walletAddress as Hex,
                    deadLine,
                ],
                value: BigInt(Number(inputOne))
            });

            const preparedTx = await simulateContract(WagmiConfig, {
                address: routerAddress as Hex, 
                abi: RouterABI,
                functionName: "addLiquidityETH",
                args: [
                    NonNativeTokenAddress as Hex,
                    BigInt(inputTwo),
                    BigInt(amountTokenMin),
                    BigInt(amountTokenETHMin),
                    walletAddress as Hex,
                    deadLine,
                ],
                value: BigInt(Number(inputOne))
            });

            addBreadcrumb('LiquidityAddition', '[addETHLiquidity] simulateContract Output Data', 'info', { preparedTxOutput: preparedTx });

            // Execute transaction
            const result = await writeContract(WagmiConfig, preparedTx.request);
            addBreadcrumb('LiquidityAddition', '[addETHLiquidity] Transaction Submitted', 'info', { Output: result });

            // Wait For Transaction Execution
            const waitForTransactionReceipt = await LibfiService.waitForTransactionReceipt(result);

            return waitForTransactionReceipt;

        } catch (error) {
            addBreadcrumb('LiquidityAddition', '[addETHLiquidity] Error Occured', 'info',  { errorMessage: error });
            throw new Error(error instanceof Error ? error.message : 'Unknown Error Occurred');
        }
    },

    addLiquidity: async (data): Promise<TransactionReceipt> => {

        const { tokenOneAddress, tokenTwoAddress, amountTokenAMin, amountTokenBMin , amountTokenADesired, amountTokenBDesired, deadLine, walletAddress } = data;

        try {
            // Input Validation
            addBreadcrumb('LiquidityAddition', '[addLiquidity] Input Data', 'info', { data: data });
            if (BigInt(amountTokenADesired) < 0n || BigInt(amountTokenBDesired) < 0n || BigInt(amountTokenAMin) < 0n || BigInt(amountTokenBMin) < 0n) {
                throw new Error("Amount Validation Failed...");
            }

            if (!isAddress(walletAddress) || !isAddress(tokenOneAddress) || !isAddress(tokenTwoAddress)) {
                throw new Error("Address Validation Failed...");
            }

            const currentTimestamp = Math.floor(Date.now() / 1000);
            if (deadLine <= currentTimestamp) {
                throw new Error("Deadline Validation Failed...");
            }
            addBreadcrumb('LiquidityAddition', '[addLiquidity] Input Validation Successful', 'info');


            addBreadcrumb('LiquidityAddition', '[addLiquidity] simulateContract Input Data', 'info', {             
                address: routerAddress as Hex,
                //abi: RouterABI,
                functionName: "addLiquidity",
                args: [
                    tokenOneAddress as Hex,
                    tokenTwoAddress as Hex,
                    BigInt(amountTokenADesired),
                    BigInt(amountTokenBDesired),
                    BigInt(amountTokenAMin),
                    BigInt(amountTokenBMin),
                    walletAddress as Hex,
                    deadLine
                ],
            });

            const preparedTx = await simulateContract(WagmiConfig, {
                address: routerAddress as Hex,
                abi: RouterABI,
                functionName: "addLiquidity",
                args: [
                    tokenOneAddress as Hex,
                    tokenTwoAddress as Hex,
                    BigInt(amountTokenADesired),
                    BigInt(amountTokenBDesired),
                    BigInt(amountTokenAMin),
                    BigInt(amountTokenBMin),
                    walletAddress as Hex,
                    deadLine
                ],
            });

            addBreadcrumb('LiquidityAddition', '[addLiquidity] simulateContract Output Data', 'info', { preparedTxOutput: preparedTx });

            // Execute transaction
            const result = await writeContract(WagmiConfig, preparedTx.request);
            addBreadcrumb('LiquidityAddition', '[addLiquidity] Transaction Submitted', 'info', { Output: result });

            // Wait For Transaction Execution
            const waitForTransactionReceipt = await LibfiService.waitForTransactionReceipt(result);

            return waitForTransactionReceipt;

        } catch (error) {
            addBreadcrumb('LiquidityAddition', '[addLiquidity] Error Occured', 'info',  { errorMessage: error });
            throw new Error(error instanceof Error ? error.message : 'Unknown Error Occurred');
        }
    },

    getPairNonces: async (walletAddress, pairAddress): Promise<bigint> => {
        try {
            // Input Validation
            addBreadcrumb('Nonce', '[getPairNonces] Input Data', 'info', { walletAddress: walletAddress, pairAddress: pairAddress });
            if (!isAddress(walletAddress) || !isAddress(pairAddress)) {
                throw new Error("Address Validation Failed...");
            }
            addBreadcrumb('Nonce', '[getPairNonces] Input Validation Successful', 'info');


            addBreadcrumb(
                'nonce', 
                'nonce Input Data', 
                'info', 
                {
                    address: pairAddress as Hex, 
                    //abi: PairABI,
                    functionName: "nonces",
                    args: [ walletAddress as Hex, ]
                }
            );

            const nonce = await readContract(WagmiConfig, {
                address: pairAddress as Hex, 
                abi: PairABI,
                functionName: "nonces",
                args: [ walletAddress as Hex, ]
            });

            // Output Validation
            if (typeof nonce !== 'bigint') {
                throw new Error("Unexpected Return From Contract");
            }

            addBreadcrumb('Nonce', '[getPairNonces] Output Data', 'info', { Output: nonce });

            return nonce;

        } catch (error) {
            addBreadcrumb('Nonce', '[getPairNonces] Error Occured', 'info',  { errorMessage: error });
            throw new Error(error instanceof Error ? error.message : 'Unknown Error Occurred');
        }
    },

    removeLiquidityETHWithPermit: async (data): Promise<TransactionReceipt> => {

        const { NonNativeTokenAddress, LPTokensToRemove, walletAddress, signature, deadLine } = data;
        const { v, r, s } = signature;

        try {
            // Input Validation
            addBreadcrumb('removeLiquidityETHWithPermit', '[removeLiquidityETHWithPermit] Input Data', 'info', { NonNativeTokenAddress: NonNativeTokenAddress, LPTokensToRemove: LPTokensToRemove,  walletAddress: walletAddress, signature: signature, deadLine: deadLine });
            if (BigInt(LPTokensToRemove) < 0n) {
                throw new Error("Amount Validation Failed...");
            }

            if ( !v || !r || !s) {
                throw new Error("Signature Validation Failed...");
            }

            if (!isAddress(walletAddress) || !isAddress(NonNativeTokenAddress)) {
                throw new Error("Address Validation Failed...");
            }

            const currentTimestamp = Math.floor(Date.now() / 1000);
            if (deadLine <= currentTimestamp) {
                throw new Error("Deadline Validation Failed...");
            }
            addBreadcrumb('removeLiquidityETHWithPermit', '[removeLiquidityETHWithPermit] Input Validation Successful', 'info');


            addBreadcrumb('removeLiquidityETHWithPermit', '[removeLiquidityETHWithPermit] simulateContract Input Data', 'info', {             
                address: routerAddress as Hex, 
                //abi: RouterABI,
                functionName: "removeLiquidityETHWithPermit",
                args: [ 
                    NonNativeTokenAddress,
                    BigInt(LPTokensToRemove),
                    BigInt(0),
                    BigInt(0),
                    walletAddress,
                    deadLine,
                    false,
                    v,
                    r,
                    s,
                ],
            });

            const preparedTx = await simulateContract(WagmiConfig, {
                address: routerAddress as Hex, 
                abi: RouterABI,
                functionName: "removeLiquidityETHWithPermit",
                args: [ 
                    NonNativeTokenAddress,
                    BigInt(LPTokensToRemove),
                    BigInt(0),
                    BigInt(0),
                    walletAddress,
                    deadLine,
                    false,
                    v,
                    r,
                    s,
                ],
            });

            addBreadcrumb('LiquidityRemoval', '[removeLiquidityETHWithPermit] simulateContract Output Data', 'info', { preparedTxOutput: preparedTx });

            // Execute transaction
            const result = await writeContract(WagmiConfig, preparedTx.request);
            addBreadcrumb('removeLiquidityETHWithPermit', '[removeLiquidityETHWithPermit] Transaction Submitted', 'info', { Output: result });

            // Wait For Transaction Execution
            const waitForTransactionReceipt = await LibfiService.waitForTransactionReceipt(result);
            
            return waitForTransactionReceipt;

        } catch (error) {
            addBreadcrumb('LiquidityRemoval', '[removeLiquidityETHWithPermit] Error Occured', 'info',  { errorMessage: error });
            throw new Error(error instanceof Error ? error.message : 'Unknown Error Occurred');
        }
    }, 

    removeLiquidityWithPermit: async (data): Promise<TransactionReceipt> => {

        const { token0Address, token1Address, LPTokensToRemove, walletAddress, signature, deadLine } = data;
        const { v, r, s } = signature;

        try {
            // Input Validation
            addBreadcrumb('LiquidityRemoval', '[removeLiquidityWithPermit] Input Data', 'info', { token0Address: token0Address, token1Address: token1Address, LPTokensToRemove: LPTokensToRemove,  walletAddress: walletAddress, signature: signature, deadLine: deadLine });
            if (BigInt(LPTokensToRemove) < 0n) {
                throw new Error("Amount Validation Failed...");
            }

            if ( !v || !r || !s) {
                throw new Error("Signature Validation Failed...");
            }

            if (!isAddress(walletAddress) || !isAddress(token0Address) || !isAddress(token1Address)) {
                throw new Error("Address Validation Failed...");
            }

            const currentTimestamp = Math.floor(Date.now() / 1000);
            if (deadLine <= currentTimestamp) {
                throw new Error("Deadline Validation Failed...");
            }
            addBreadcrumb('LiquidityRemoval', '[removeLiquidityWithPermit] Input Validation Successful', 'info');


            addBreadcrumb('LiquidityRemoval', '[removeLiquidityWithPermit] simulateContract Input Data', 'info', {             
                address: routerAddress as Hex, 
                //abi: RouterABI,
                functionName: "removeLiquidityWithPermit",
                args: [ 
                    token0Address,
                    token1Address,
                    BigInt(LPTokensToRemove),
                    BigInt(0),
                    BigInt(0),
                    walletAddress,
                    deadLine,
                    false,
                    v,
                    r,
                    s,
                ],
            });

            const preparedTx = await simulateContract(WagmiConfig, {
                address: routerAddress as Hex, 
                abi: RouterABI,
                functionName: "removeLiquidityWithPermit",
                args: [ 
                    token0Address,
                    token1Address,
                    BigInt(LPTokensToRemove),
                    BigInt(0),
                    BigInt(0),
                    walletAddress,
                    deadLine,
                    false,
                    v,
                    r,
                    s,
                ],
            });

            addBreadcrumb('LiquidityRemoval', '[removeLiquidityWithPermit] simulateContract Output Data', 'info', { preparedTxOutput: preparedTx });

            // Execute transaction
            const result = await writeContract(WagmiConfig, preparedTx.request);
            addBreadcrumb('LiquidityRemoval', '[removeLiquidityWithPermit] Transaction Submitted', 'info', { Output: result });

            // Wait For Transaction Execution
            const waitForTransactionReceipt = await LibfiService.waitForTransactionReceipt(result);
            
            return  waitForTransactionReceipt;

        } catch (error) {
            addBreadcrumb('LiquidityRemoval', '[removeLiquidityWithPermit] Error Occured', 'info',  { errorMessage: error });
            throw new Error(error instanceof Error ? error.message : 'Unknown Error Occurred');
        }
    }, 

    getLPAllowance: async (walletAddress, pairAddress): Promise<bigint> => {
        try {
            // Input Validation
            addBreadcrumb('LPAllowance', '[getLPAllowance] Input Data', 'info', { walletAddress: walletAddress, pairAddress: pairAddress });
            if (!isAddress(walletAddress) || !isAddress(pairAddress)) {
                throw new Error("Address Validation Failed...");
            }
            addBreadcrumb('LPAllowance', '[getLPAllowance] Input Validation Successful', 'info');


            addBreadcrumb('LPAllowance', '[getLPAllowance] simulateContract Input Data', 'info', {             
                address: pairAddress as Hex, 
                //abi: PairABI,
                functionName: "allowance",
                args: [
                    walletAddress as Hex,
                    routerAddress as Hex,
                ]
            });

            const getLPAllowance = await readContract(WagmiConfig, {
                address: pairAddress as Hex, 
                abi: PairABI,
                functionName: "allowance",
                args: [
                    walletAddress as Hex,
                    routerAddress as Hex,
                ]
            });
            addBreadcrumb('LPAllowance', '[getLPAllowance] Output Data', 'info', { Output: getLPAllowance });

            // Output Validation
            if (typeof getLPAllowance !== 'bigint') {
                throw new Error("Unexpected Return From Contract");
            }

            return getLPAllowance;

        } catch (error) {
            addBreadcrumb('LPAllowance', '[getLPAllowance] Error Occurred', 'info', { errorMessage: error instanceof Error ? error.message : 'Unknown Error Occurred' });
            throw new Error(error instanceof Error ? error.message : 'Unknown Error Occurred');
        }
    },

    getLPApproval: async (pairAddress): Promise<TransactionReceipt> => {
        try {
            // Input Validation
            addBreadcrumb('LPApproval', '[getLPApproval] Input Data', 'info', { pairAddress: pairAddress });
            if (!isAddress(pairAddress)) {
                throw new Error("Address Validation Failed...");
            }
            addBreadcrumb('LPApproval', '[getLPApproval] Input Validation Successful', 'info');


            addBreadcrumb('LPApproval', '[getLPApproval] simulateContract Input Data', 'info', {             
                address: pairAddress as Hex, 
                //abi: PairABI,
                functionName: "approve",
                args: [
                    routerAddress as Hex,
                    MAX_APPROVAL
                ],
            });

            const preparedTx = await simulateContract(WagmiConfig, {
                address: pairAddress as Hex, 
                abi: PairABI,
                functionName: "approve",
                args: [
                    routerAddress as Hex,
                    MAX_APPROVAL
                ],
            });

            addBreadcrumb('LPApproval', '[getLPApproval] simulateContract Output Data', 'info', { preparedTxOutput: preparedTx });

            // Execute transaction
            const result = await writeContract(WagmiConfig, preparedTx.request);
            addBreadcrumb('LPApproval', '[getLPApproval] Transaction Submitted', 'info', { Output: result });
            
            // Wait For Transaction Execution
            const waitForTransactionReceipt = await LibfiService.waitForTransactionReceipt(result);
            
            return waitForTransactionReceipt;

        } catch (error) {
            addBreadcrumb('LPApproval', '[getLPApproval] Error Occurred', 'info', { errorMessage: error instanceof Error ? error.message : 'Unknown Error Occurred' });
            throw new Error(error instanceof Error ? error.message : 'Unknown Error Occurred');
        }
    },

    removeLiquidityETH: async (data): Promise<TransactionReceipt> => {

        const { NonNativeTokenAddress, LPTokensToRemove, walletAddress, deadLine } = data;

        try {
            // Input Validation
            addBreadcrumb('LiquidityRemoval', '[removeLiquidityETH] Input Data', 'info', { NonNativeTokenAddress: NonNativeTokenAddress, LPTokensToRemove: LPTokensToRemove, walletAddress: walletAddress, deadLine: deadLine });
            if (BigInt(LPTokensToRemove) < 0n) {
                throw new Error("Amount Validation Failed...");
            }

            if (!isAddress(walletAddress) || !isAddress(NonNativeTokenAddress)) {
                throw new Error("Address Validation Failed...");
            }

            const currentTimestamp = Math.floor(Date.now() / 1000);
            if (deadLine <= currentTimestamp) {
                throw new Error("Deadline Validation Failed...");
            }
            addBreadcrumb('LiquidityRemoval', '[removeLiquidityETH] Input Validation Successful', 'info');
            

            addBreadcrumb('LiquidityRemoval', '[removeLiquidityETH] simulateContract Input Data', 'info', {             
                address: routerAddress as Hex, 
                //abi: RouterABI,
                functionName: "removeLiquidityETH",
                args: [ 
                    NonNativeTokenAddress,
                    BigInt(LPTokensToRemove),
                    BigInt(0),
                    BigInt(0),
                    walletAddress,
                    deadLine
                ],
            });

            const preparedTx = await simulateContract(WagmiConfig, {
                address: routerAddress as Hex, 
                abi: RouterABI,
                functionName: "removeLiquidityETH",
                args: [ 
                    NonNativeTokenAddress,
                    BigInt(LPTokensToRemove),
                    BigInt(0),
                    BigInt(0),
                    walletAddress,
                    deadLine
                ],
            });

            addBreadcrumb('LiquidityRemoval', '[removeLiquidityETH] simulateContract Output Data', 'info', { preparedTxOutput: preparedTx });

            // Execute transaction
            const result = await writeContract(WagmiConfig, preparedTx.request);
            addBreadcrumb('LiquidityRemoval', '[removeLiquidityETH] Transaction Submitted', 'info', { Output: result });
  
            // Wait For Transaction Execution
            const waitForTransactionReceipt = await LibfiService.waitForTransactionReceipt(result);

            return waitForTransactionReceipt;

        } catch (error) {
            addBreadcrumb('LiquidityRemoval', '[removeLiquidityETH] Error Occured', 'info',  { errorMessage: error });
            throw new Error(error instanceof Error ? error.message : 'Unknown Error Occurred');
        }
    }, 

    removeLiquidity: async (data): Promise<TransactionReceipt> => {

        const { token0Address, token1Address, LPTokensToRemove, walletAddress, deadLine } = data;

        try {
            // Input Validation
            addBreadcrumb('LiquidityRemoval', '[removeLiquidity] Input Data', 'info', { token0Address: token0Address, token1Address: token1Address, LPTokensToRemove: LPTokensToRemove, walletAddress: walletAddress, deadLine: deadLine });
            if (BigInt(LPTokensToRemove) < 0n) {
                throw new Error("Amount Validation Failed...");
            }

            if (!isAddress(walletAddress) || !isAddress(token0Address) || !isAddress(token1Address)) {
                throw new Error("Address Validation Failed...");
            }

            const currentTimestamp = Math.floor(Date.now() / 1000);
            if (deadLine <= currentTimestamp) {
                throw new Error("Deadline Validation Failed...");
            }
            addBreadcrumb('LiquidityRemoval', '[removeLiquidity] Input Validation Successful', 'info');


            addBreadcrumb('LiquidityRemoval', '[removeLiquidity] simulateContract Input Data', 'info', {             
                address: routerAddress as Hex, 
                //abi: RouterABI,
                functionName: "removeLiquidity",
                args: [ 
                    token0Address as Hex,
                    token1Address as Hex,
                    BigInt(LPTokensToRemove),
                    BigInt(0),
                    BigInt(0),
                    walletAddress as Hex,
                    deadLine
                ],
            });

            const preparedTx = await simulateContract(WagmiConfig, {
                address: routerAddress as Hex, 
                abi: RouterABI,
                functionName: "removeLiquidity",
                args: [ 
                    token0Address as Hex,
                    token1Address as Hex,
                    BigInt(LPTokensToRemove),
                    BigInt(0),
                    BigInt(0),
                    walletAddress as Hex,
                    deadLine
                ],
            });

            addBreadcrumb('LiquidityRemoval', '[removeLiquidity] simulateContract Output Data', 'info', { preparedTxOutput: preparedTx });

            // Execute transaction
            const result = await writeContract(WagmiConfig, preparedTx.request);
            addBreadcrumb('LiquidityRemoval', '[removeLiquidity] Transaction Submitted', 'info', { Output: result });

            // Wait For Transaction Execution
            const waitForTransactionReceipt = await LibfiService.waitForTransactionReceipt(result);
            
            return waitForTransactionReceipt;

        } catch (error) {
            addBreadcrumb('LiquidityRemoval', '[removeLiquidity] Error Occured', 'info',  { errorMessage: error });
            throw new Error(error instanceof Error ? error.message : 'Unknown Error Occurred');
        }
    }, 

};

export default LibfiService;
