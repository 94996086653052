import moment from "moment";
import Web3 from 'web3';
import { callContractGetMethod } from "../../../../Redux/Actions/contract.action";
import { storeInstance } from "../../../../Services/axios.service";
import {
  convertWithDecimal,
  localeStringFunction,
  multiplyBigDigitsWithDecimals,
  numberToString,
  slicedValue,
} from "../../../../Services/common.service";

import {
  getAllowanceAndApprovalHelper,
  getPairService,
  getReservesFunction,
  getTokensFromPair,
} from "../../../../Services/contractCallService";
import {
  addLiquidityEthService,
  addLiquidityService,
} from "../../../../Services/contractSendService";
import { RESPONSES } from "../../../../Utils";
import { loader } from "../../../../Redux/Slices/loader.slice";
import {
  COMMON_DATA,
  ReserveHelper,
  TOKEN_DETAILS,
} from "../../../../interfaces/commonInterfaces";
import { AllowanceAndApproval } from "../../../../interfaces/contractCallInterfaces";
import { subscribeToBlocks } from '../../../../Redux/Actions/user.action';
import { ZERO_ADDRESS } from "../../../../Utils";


const web3 = new Web3(process.env.REACT_APP_RPC_URL || 'http://localhost:8545');
/**
 * helper function to calculate reserves of a particular pool
 * @param data it is an object that contains various params required for this function
 * address of the token in the first input field
 * address of the token in the second input field
 * input value that user has entered in the first input field
 * input value that user has entered in the second input field
 * first input field out of the two fields in which user has entered value
 * boolean variable to check if user has clicked on max button or not
 * @returns reserves of the token in the input field that user has not selected
 */

const getReservesHelper = async (data: ReserveHelper) => {
  const {
    tokenOneAddress,
    tokenTwoAddress,
    input1,
    input2,
    selectedField,
    max,
    dispatch,
  } = data;
  // console.log('getReservesHelper')
  // console.log(data)

  const decimalsTokenOne: number = await dispatch(
    callContractGetMethod("decimals", [], "dynamic", false, tokenOneAddress)
  );
  const decimalsTokenTwo: number = await dispatch(
    callContractGetMethod("decimals", [], "dynamic", false, tokenTwoAddress)
  );

  // console.log('decimalsTokenOne')
  // console.log(decimalsTokenOne)
  // console.log('decimalsTokenTwo')
  // console.log(decimalsTokenTwo)
  const convertedAmountOne: any = max
    ? input1?.toString()
    : input1 * 10 ** Number(decimalsTokenOne);
  const convertedAmountTwo: any = max
    ? input2?.toString()
    : input2 * 10 ** Number(decimalsTokenTwo);

  // console.log('convertedAmountOne')
  // console.log(convertedAmountOne)
  // console.log('convertedAmountTwo')
  // console.log(convertedAmountTwo)

  let _reserve0, _reserve1;
  let tokenA, tokenB;

  if (tokenOneAddress !== undefined && tokenTwoAddress !== undefined) {
    const pairAddress: string = await getPairService({
      tokenOneAddress,
      tokenTwoAddress,
      dispatch,
    });
    if (pairAddress == ZERO_ADDRESS) {
      return;
    }
    
    ({ tokenA, tokenB } = await getTokensFromPair(pairAddress, dispatch));

    ({ _reserve0, _reserve1 } = await getReservesFunction({
      tokenOneAddress,
      tokenTwoAddress,
      dispatch,
    }));
  }

  let res: string;

  if (selectedField == "field1") {
    if (tokenOneAddress?.toLowerCase() == tokenA?.toLowerCase()) {
      if (Number(_reserve0) === 0 || Number(_reserve1) === 0) return "0";
      let ratio = convertedAmountOne * (10 ** Number(decimalsTokenTwo) / 10 ** Number(decimalsTokenOne)) * _reserve1 / _reserve0;
      res = slicedValue(localeStringFunction(Number(ratio)));
      return res;
    } else {
      if (Number(_reserve0) === 0 || Number(_reserve1) === 0) return "0";
      res = slicedValue(
        localeStringFunction(
          Number(convertedAmountOne * (10 ** Number(decimalsTokenOne) / 10 ** Number(decimalsTokenTwo)) * (_reserve0 / _reserve1))
        )
      );
      return res;
    }
  } else if (selectedField == "field2") {
    if (tokenTwoAddress?.toLowerCase() == tokenA?.toLowerCase()) {
      if (Number(_reserve0) === 0 || Number(_reserve1) === 0) return "0";
      res = slicedValue(
        localeStringFunction(
          Number(convertedAmountTwo * (10 ** Number(decimalsTokenTwo) / 10 ** Number(decimalsTokenOne)) * (_reserve1 / _reserve0))
        )
      );
      return res;
    } else {
      if (Number(_reserve0) === 0 || Number(_reserve1) === 0) return "0";
      res = slicedValue(
        localeStringFunction(
          Number(convertedAmountTwo * (10 ** Number(decimalsTokenOne) / 10 ** Number(decimalsTokenTwo)) * (_reserve0 / _reserve1))
        )
      );
      return res;
    }
  }
};

/**
 * helper function to calculate reserves of a particular pool
 * @param data it is an object that contains various params required for this function
 * address of the token in the first input field
 * address of the token in the second input field
 * input value that user has entered in the first input field
 * input value that user has entered in the second input field
 * first input field out of the two fields in which user has entered value
 * boolean variable to check if user has clicked on max button or not
 * @returns reserves of the token in the input field that user has not selected
 */

const getTotalReservesOfTokensInPool = async (data: COMMON_DATA) => {
  const { tokenOneAddress, tokenTwoAddress, dispatch } = data;

  const pairAddress: string = await getPairService({
    tokenOneAddress,
    tokenTwoAddress,
    dispatch,
  });

  console.log("Pool - pairAddress - Function: " + pairAddress)

  if (pairAddress == ZERO_ADDRESS) {
    return { reserveA: 0, reserveB: 0 };
  }
  const { tokenA, tokenB } = await getTokensFromPair(pairAddress, dispatch);

  console.log("Pool - tokenA - Function: " + tokenA)
  console.log("Pool - tokenOneAddress - Function: " + tokenOneAddress)
  console.log("Pool - tokenB - Function: " + tokenB)
  console.log("Pool - tokenTwoAddress - Function: " + tokenTwoAddress)


  const { _reserve0, _reserve1 } = await getReservesFunction({
    tokenOneAddress,
    tokenTwoAddress,
    dispatch,
  });

  console.log("Pool - _reserve0 - Function: " + _reserve0)
  console.log("Pool - _reserve1 - Function: " + _reserve1)


  if (tokenOneAddress?.toLowerCase() == tokenA?.toLowerCase()) {
    return { reserveA: _reserve0, reserveB: _reserve1 };
  } else {
    return { reserveA: _reserve1, reserveB: _reserve0 };
  }
};

/**
 * helper function to calculate current time in unix timestamp
 * @returns current unix timestamp
 */

const calculateDeadlineHelper = async () => {
  let deadLine = Math.floor(new Date().getTime() / 1000);
  deadLine = deadLine + 10 * 60;
  let currentTime = new Date();
  let deadLinetime = moment(currentTime).unix();
  deadLinetime = Number(deadLinetime) + Number(10) * 60;
  return deadLinetime;
};

/**
 * helper function to handle add Liquidity both with native coin and custom tokens
 * @param dispatch hook to update values in redux
 * @param tokenDetails memoized variable for token related details
 * @param walletAddress user address
 * @param inputOne input in the first field
 * @param inputTwo input in the second field
 * @param getBackToOriginalState function to update value when a transaction occurs
 * @returns based on the output it returns success,failure or error
 */

const addLiquidityHelperFunction = async (
  dispatch: Function,
  tokenDetails: TOKEN_DETAILS,
  inputOne: { inputValue: any; convertedValue: any },
  inputTwo: { inputValue: any; convertedValue: any },
  setModalData: Function,
  t: (key: string) => string,
) => {
  try {
    const { walletAddress, slippage } = storeInstance?.getState()?.user;
    // const slippage = 0.1;
    //const deadline = 0;
    //let deadLine = deadline ? deadline : await calculateDeadlineHelper();
    //let currentTime = new Date();
    //let deadLinetime = moment(currentTime).unix();
    //if (deadLine <= deadLinetime) {
    //  deadLine = await calculateDeadlineHelper();
    //}
    const deadLine = await calculateDeadlineHelper();

    if (tokenDetails?.isToken0Native || tokenDetails?.isToken1Native) {
      
      console.log("AAM Helper inputOne: " + JSON.stringify(inputOne))
      console.log("AAM Helper inputTwo: " + JSON.stringify(inputTwo))

      const customToken = tokenDetails?.isToken0Native
        ? tokenDetails?.token1Address
        : tokenDetails?.token0Address;
      const data1: AllowanceAndApproval = {
        customToken,
        dispatch,
        tokenOneAddress: tokenDetails?.token0Address,
        tokenTwoAddress: tokenDetails?.token1Address,
        walletAddress,
        inputOne,
        inputTwo,
      };

      setModalData({
        heading: t('ammPoolsApprovalHeading'),
        bodyText: (t('ammPoolsApprovalConfirmText'))
          .replace('{{token1}}', tokenDetails?.token0Name || "")
          .replace('{{token2}}', tokenDetails?.token1Name || ""),
        status: "pending",
        txHash: null,
      });

      console.log("Inputs getAllowanceAndApprovalHelper data", data1)

      const res: boolean | undefined = await getAllowanceAndApprovalHelper(
        data1
      );
      const amountTokenMin = tokenDetails?.isToken0Native
        ? slicedValue(
            localeStringFunction(
              inputTwo?.convertedValue -
                (inputTwo?.convertedValue * slippage) / 100
            )
          )
        : slicedValue(
            localeStringFunction(
              inputOne?.convertedValue -
                (inputOne?.convertedValue * slippage) / 100
            )
          );
      const amountTokenETHMin = tokenDetails?.isToken0Native
        ? slicedValue(
            localeStringFunction(
              inputOne?.convertedValue -
                (inputOne?.convertedValue * slippage) / 100
            )
          )
        : slicedValue(
            localeStringFunction(
              inputTwo?.convertedValue -
                (inputTwo?.convertedValue * slippage) / 100
            )
          );

      if (res) {
        setModalData({
          heading: t('ammPoolsApprovalHeading'),
          bodyText: (t('ammPoolsApprovalSuccessText'))
              .replace('{{token1}}', tokenDetails?.token0Name || "")
              .replace('{{token2}}', tokenDetails?.token1Name || ""),
          status: "success",
          txHash: null,
        });
        //setTimeout(() => {}, 2000000); //dirty
        setModalData({
          heading: t('ammPoolsAddLiquidityHeading'),
          bodyText: (t('ammPoolsTrxConfirmText'))
              .replace('{{token1}}', tokenDetails?.token0Name || "")
              .replace('{{token2}}', tokenDetails?.token1Name || ""),
          status: "pending",
          txHash: null,
        });
        let amountTokenBDesired = inputTwo?.convertedValue;
        //if (tokenDetails?.tokenOneName === "USDT") {
          // Ensure amountTokenBDesired has 18 decimal places
          //amountTokenBDesired = inputTwo?.convertedValue + "000000000000";
        //}

        //In fact, we always need to pass ETH as inputone
        const result = await addLiquidityEthService({
          inputOne: tokenDetails?.isToken0Native
            ? inputOne?.convertedValue
            : amountTokenBDesired,
          inputTwo: tokenDetails?.isToken1Native
            ? inputTwo?.convertedValue
            : amountTokenBDesired,
          tokenOneAddress: customToken,
          amountTokenMin,
          amountTokenETHMin,
          walletAddress,
          deadLine,
          dispatch,
        });

        //console.log("AAM Helper addLiquidityEthService: " + JSON.stringify(result))
        console.log("AAM Helper result: ", result)

        if (result) {
          setModalData({
            heading: t('ammPoolsAddLiquidityHeading'),
            bodyText: (t('ammPoolsTrxSuccessText'))
              .replace('{{token1}}', tokenDetails?.token0Name || "")
              .replace('{{token2}}', tokenDetails?.token1Name || ""),
            status: "success",
            txHash: result?.transactionHash,
          });
          // console.log("DONE");
          return true;
        } else {
          setModalData({
            heading: t('ammPoolsAddLiquidityHeading'),
            bodyText: (t('ammPoolsTrxFailedText'))
              .replace('{{token1}}', tokenDetails?.token0Name || "")
              .replace('{{token2}}', tokenDetails?.token1Name || ""),
            status: "error",
            txHash: null,
          });
          // console.log("FAILED");
          return false;
        }
      } else {
        setModalData({
          heading: t('ammPoolsApprovalHeading'),
          bodyText: (t('ammPoolsApprovalFailedText'))
              .replace('{{token1}}', tokenDetails?.token0Name || "")
              .replace('{{token2}}', tokenDetails?.token1Name || ""),
          status: "error",
          txHash: null,
        });
      }
    } else {
      dispatch(loader(false));
      const data2 = {
        customToken: 0,
        walletAddress,
        tokenOneAddress: tokenDetails?.token0Address,
        tokenTwoAddress: tokenDetails?.token1Address,
        dispatch,
        inputOne,
        inputTwo,
      };
      console.log("Inputs getAllowanceAndApprovalHelper data",data2)
      dispatch(loader(false));
      setModalData({
        heading: t('ammPoolsApprovalHeading'),
        bodyText: (t('ammPoolsApprovalConfirmText'))
          .replace('{{token1}}', tokenDetails?.token0Name || "")
          .replace('{{token2}}', tokenDetails?.token1Name || ""),
        status: "pending",
        txHash: null,
      });
      const res: boolean | undefined = await getAllowanceAndApprovalHelper(
        data2
      );
      const amountTokenAMin = slicedValue(
        localeStringFunction(
          inputOne?.convertedValue - (inputOne?.convertedValue * slippage) / 100
        )
      );

      const amountTokenBMin = slicedValue(
        localeStringFunction(
          inputTwo?.convertedValue - (inputTwo?.convertedValue * slippage) / 100
        )
      );

      if (res) {
        setModalData({
          heading: t('ammPoolsApprovalHeading'),
          bodyText: (t('ammPoolsApprovalSuccessText'))
              .replace('{{token1}}', tokenDetails?.token0Name || "")
              .replace('{{token2}}', tokenDetails?.token1Name || ""),
          status: "success",
          txHash: null,
        });
        //setTimeout(() => {}, 2000000); //dirty clean fix incoming

        setModalData({
          heading: t('ammPoolsAddLiquidityHeading'),
          bodyText: (t('ammPoolsTrxConfirmText'))
              .replace('{{token1}}', tokenDetails?.token0Name || "")
              .replace('{{token2}}', tokenDetails?.token1Name || ""),
          status: "pending",
          txHash: null,
        });

        let amountTokenBDesired = inputTwo?.convertedValue;
        //if (tokenDetails?.tokenOneName === "USDT") {
          // Ensure amountTokenBDesired has 18 decimal places
        //  amountTokenBDesired = inputTwo?.convertedValue + "000000000000";
        //}

        const result: any = await addLiquidityService({
          tokenOneAddress: tokenDetails?.token0Address,
          tokenTwoAddress: tokenDetails?.token1Address,
          amountTokenADesired: inputOne?.convertedValue,
          amountTokenBDesired: amountTokenBDesired,
          amountTokenAMin:
            inputOne?.convertedValue < 100000000000000000 ? 0 : amountTokenAMin,
          amountTokenBMin:
            inputTwo?.convertedValue < 100000000000000000 ? 0 : amountTokenBMin,
          walletAddress,
          deadLine,
          dispatch,
        });

        console.log("AAM Helper result: ", result)

        if (result) {
          setModalData({
            heading: t('ammPoolsAddLiquiditySuccessHeading'),
            bodyText: (t('ammPoolsTrxSuccessText'))
              .replace('{{token1}}', tokenDetails?.token0Name || "")
              .replace('{{token2}}', tokenDetails?.token1Name || ""),
            status: "success",
            txHash: result?.transactionHash,
          });
          // console.log("DONE");
          return true;
        } else {
          setModalData({
            heading: t('ammPoolsAddLiquidityFailedHeading'),
            bodyText: (t('ammPoolsTrxFailedText'))
              .replace('{{token1}}', tokenDetails?.token0Name || "")
              .replace('{{token2}}', tokenDetails?.token1Name || ""),
            status: "error",
            txHash: null,
          });
          // console.log("FAILED");
          return false;
        }
      } else {
        setModalData({
          heading: t('ammPoolsApprovalHeading'),
          bodyText: (t('ammPoolsApprovalFailedText'))
              .replace('{{token1}}', tokenDetails?.token0Name || "")
              .replace('{{token2}}', tokenDetails?.token1Name || ""),
          status: "error",
          txHash: null,
        });
      }
    }
  } catch (error) {
    return false;
  }
};

export {
  getReservesHelper,
  addLiquidityHelperFunction,
  calculateDeadlineHelper,
  getTotalReservesOfTokensInPool,
};
