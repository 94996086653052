import { debounce } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import OtpInput from 'react-otp-input';
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { ConvertIcon, InfiniteSpinner, InfoIcon, LeftArrow } from "../../../../../Assets/Images/Icons/SvgIcons";

import { useDynamicContext, useOtpVerificationRequest, useUserUpdateRequest } from "@dynamic-labs/sdk-react-core";
import { TOKENS, TOKEN_DETAILS_SINGLE } from "../../../../../interfaces/commonInterfaces";
import { fetchTokenBalance } from "../../../../../LibfiServices/Hooks/usefetchTokenBalance";
import { callApiPostMethod } from "../../../../../Redux/Actions/api.action";
import { DOLLAR_VAL } from "../../../../../Redux/Actions/apiResponseInterfaces";
import { toCustomRoundedFixed, validateInput } from "../../../../../Services/common.service";
import { dynamicContractDetails, tokenCollection } from "../../../../../Services/dynamicContractDetails";
import { RESPONSES } from "../../../../../Utils";
import ButtonCustom from "../../../../Common/Button/ButtonCustom";
import CommonCard from "../../../../Common/Cards/CommonCard/CommonCard";
import Dropdown from "../../../../Common/FormInputs/Dropdown/Dropdown";
import InputCustom from "../../../../Common/FormInputs/Input/Input";
import Tooltip from "../../../../Common/Tooltip/Tooltip";
import "./OnOffRampPage.scss";
import OnOffRampSuccessPage from "./OnOffRampSuccessPage";
import toaster from "../../../../Common/Toast";
import { useNavigate } from "react-router-dom";
import { userDetails } from "../../../../../Redux/Slices/user.slice";
import { updateUserModal } from "../../../../../Redux/Actions/user.action";
import { addBreadcrumb, captureException } from "../../../../../SentryContext";

// List of all steps once user process with ON/OFF RAMP
enum PAYMENT_STEPS {
    INITIAL,                        // When Payment process is not yet started, when we are on Buy/Sell Page and not processed further
    ENTER_EMAIL,                    // When User clicked on Buy/Sell and he needs to enter his email address
    OTP_VERIFICATION,               // When user processed futher and needs to verify OTP 
    ENTER_BASIC_DETAILS,            // When user needs to enter his/her basic details e.g. First name, last name
    ENTER_BANK_DETAILS,             // When user needs to enter his/her bank details (In Offramp process)
    WALLET_CONFIRMATION,            // When user needs to confirm wallet address
    ONRAMP_INITIATE_SUCCESS,        // Once user intiated the ONRAMP process and can see the transfer details to which they need to deposite the currency amount
    OFFRAMP_INITIATE_SUCCESS        // Once user intiated the OFFRAMP process and can see the transfer details to which they need to deposite their crypto tokens
}

// Whether user is Buying or Selling the Crypto
export enum ON_OFF_RAMP_TYPES {
    BUY,
    SELL
}

type OnOffRampPageProps = {
    type: ON_OFF_RAMP_TYPES     // Type of the transaction user is doing
}

// Refresh the rate in every 30 seconds
const REFRESH_TIME_IN_SECONDS = 30;

// Show warnings of rate revise after 20 seconds
const REFRESH_TIME_SHOW_WARNING_AT = 20;

// Convert the crypto/currency after 500 milli seconds (after user enters the amount)
const DEBOUNCE_TIME = 500;

const OnOffRampPage = ({ type }: OnOffRampPageProps) => {

    const { user, primaryWallet } = useDynamicContext()
    const { verifyOtp } = useOtpVerificationRequest();
    const { updateUser } = useUserUpdateRequest();
    const navigate = useNavigate();
    const dispatch: any = useDispatch();
    const { t } = useTranslation()
    const walletAddress: any = primaryWallet?.address;
    const userDetailsData: any = useSelector(
        (state: any) => state?.user?.userDetails
    );

    // Used for checking email verification
    const [verificationUUID, setVerificationUUID] = useState<string>();
    // Used to store all bank codes for selected currency
    const [bankCodes, setBankCodes] = useState([]);

    // Used to store list of all supported currencies
    const [currencies, setCurrencies] = useState([]);

    // Used to store list of all supported crypto tokens
    const [tokens, setTokens] = useState<TOKENS[]>([]);

    // Used to store the selected currency from the dropdown
    const [selectedCurrency, setSelectedCurrency] = useState<any>({});

    // Used to store the selected crypto token from the dropdown
    const [selectedToken, setSelectedToken] = useState<any>({});

    // Used to store entered currency amount with displayvalue
    const [currencyAmount, setCurrencyAmount] = useState<{ displayValue: string; originalValue: number; }>({ displayValue: "", originalValue: 0, });

    // Used to store entered crypto amount with displayvalue
    const [cryptoAmount, setCryptoAmount] = useState<{ displayValue: string; originalValue: number; }>({ displayValue: "", originalValue: 0 });

    // Used to store minimum transaction amount that requires to perform on/off ramp (In Dollar)
    const [minimumTrxAmountInDollar, setMinimumTrxAmountInDollar] = useState<number>(0);

    // Used to store platform fee applicable (In Selected Currency)
    const [platformFeeInCurrency, setPlatformFeeInCurrency] = useState<number>(0);

    // Used to store platform fee applicable (In Selected Crypto Token)
    const [platformFeeInCrypto, setPlatformFeeInCrypto] = useState<number>(0);

    // Used to store platform fee in percentage
    const [platformFeeInCurrencyPercentage, setPlatformFeeInCurrencyPercentage] = useState<number>(0);

    // Used to store platform fee in percentage
    const [platformFeeInCryptoPercentage, setPlatformFeeInCryptoPercentage] = useState<number>(0);

    // Used to store the exchange rate of crypto to currency
    const [exchangeRate, setExchangeRate] = useState<number>(0);

    // Used to store minimum transaction amount that requires to perform on/off ramp (in selected currrency)
    const [minimumTrxAmountInCurrency, setMinimumTrxAmountInCurrency] = useState<number>(0);

    // Used to store minimum transaction amount that requires to perform on/off ramp (in selected crypto)
    const [minimumTrxAmountInCrypto, setMinimumTrxAmountInCrypto] = useState<number>(0);

    // Used to store dollar values of each crypto token
    const [tokenDollarValue, setTokenDollarValue] = useState<any>([]);

    // Used to store flag to show minimum limit error in currency
    const [showCurrencyMinTrxLimitError, setShowCurrencyMinTrxLimitError] = useState<boolean>(false);

    // Used to store flag to show minimum limit error in crypto
    const [showCryptoMinTrxLimitError, setShowCryptoMinTrxLimitError] = useState<boolean>(false);

    // Used to store user email while doing on/off payment process, it will get defaulted with the email address stored in user profile section
    const [userEmail, setUserEmail] = useState<string>(userDetailsData?.emailAddress || "");

    // Used to store user first name while doing on/off ramp payment process
    const [userFirstName, setUserFirstName] = useState<string>();

    // Used to store user last name while doing on/off ramp payment process
    const [userLastName, setUserLastName] = useState<string>();

    // Used to store user bank code while doing off ramp payment process
    const [userBankCode, setUserBankCode] = useState<{ bankCode: string, bankName: string, label?: string }>();

    // Used to store user bank account number while doing off ramp payment process
    const [userBankAccount, setUserBankAccount] = useState<string>();

    // Used to store otp entered while doing on/off ramp payment process
    const [otp, setOtp] = useState<string>();

    // Used to store current payment steps, by this variable it will move around all payment screens
    const [step, setStep] = useState<PAYMENT_STEPS>(PAYMENT_STEPS.INITIAL);

    // Used to store all errors in the screen. This object will contains the field name on which error comes with the error description
    const [errors, setErrors] = useState<any>({});

    // Used to store on/off ramp initiate payment response
    const [initiatePaymentResponse, setInitiatePaymentResponse] = useState<any>({});

    // Used to store balance of currenctly selected token
    const [tokenBalance, setTokenBalance] = useState<number>();

    // This flag will be set to true when we are fetching the exchange rate and is used to show a loading stuff during the fetching process.
    const [fetchingExchangeRate, setFetchingExchangeRate] = useState<boolean>(false);

    // This flag will be set to true when we are fetching the token balance and is used to show a loading stuff during the fetching process.
    const [fetchingMinimumBalance, setFetchingMinimumBalance] = useState<boolean>(false);

    // This flag will be set to true when we are fetching the token balance and is used to show a loading stuff during the fetching process.
    const [fetchingBalance, setFetchingBalance] = useState<boolean>(false);

    // This flag will be set to true when we are fetching the crypto amount and is used to show a loading stuff during the fetching process.
    const [fetchingCryptoAmount, setFetchingCryptoAmount] = useState<boolean>(false);

    // This flag will be set to true when we are fetching the currency amount and is used to show a loading stuff during the fetching process.
    const [fetchingCurrencyAmount, setFetchingCurrencyAmount] = useState<boolean>(false);

    // Used to store timer value when screen is idle and about to fetch new exchange rate
    const [timer, setTimer] = useState<number>(REFRESH_TIME_IN_SECONDS);

    // Used to store timer warning time value when screen is idle and about to show refresh exchange rate warning
    const [timerWarningTime, setTimerWarningTime] = useState<number>(REFRESH_TIME_IN_SECONDS);

    // This flag will be set to true when we are moving from one payment screen to next screen and loading their content, during that period it will be used to show a loader
    const [loader, setLoader] = useState<boolean>(false);

    // This reference is created to avoid multiple call of updateMinimumAmount function
    const callIdRef = useRef(0);

    /**
     * Callback function to set timer to its default value
     */
    const resetTimer = useCallback(() => {
        setTimer(REFRESH_TIME_IN_SECONDS);
    }, []);

    /**
     * when component is loaded, load all initial data
     */
    useEffect(() => {
        if (primaryWallet?.connected) {
            loadInitialData();
        } else {
            // true indicates user disconnected
            reset(true)
        }
    }, [primaryWallet?.connected]);
    /**
     * Once idle time reaches its limit, fetch request exchange rate and update the currency/crypto amounts accordingly.
     * This will happened only for initial stage because if user go further step then no need to show timer
     */
    useEffect(() => {
        //Reset the timer if user is not connected or if user has not entered either currency or crypto amount
        if (!primaryWallet?.connected || currencyAmount.originalValue === 0 || cryptoAmount.originalValue === 0) {
            return setTimer(REFRESH_TIME_IN_SECONDS);
        }
        if (step === PAYMENT_STEPS.INITIAL) {
            if (timer <= 0) {
                //Once timer goes at 0 value fetch required data and refresh the amounts
                setTimer(REFRESH_TIME_IN_SECONDS);
                setTimerWarningTime(REFRESH_TIME_IN_SECONDS);

                // Fetch exchange rate
                fetchExchangeRate();

                // Updates Currency/Crypto amount
                if (type === ON_OFF_RAMP_TYPES.BUY) {
                    onChangeCurrencyAmount(currencyAmount?.originalValue || "");
                } else {
                    onChangeCryptoAmount(cryptoAmount?.originalValue || "");
                }
            }
        } else {
            // reset the timer for other steps as it is not required
            setTimer(REFRESH_TIME_IN_SECONDS);
        }
    }, [timer]);

    /**
     * once reset timer is called start the interval which decrements by every second
     */
    useEffect(() => {
        const decrementTimer = () => {
            setTimer((prevTimer) => prevTimer - 1);
        };
        // Decrement idle time every second
        const interval = setInterval(decrementTimer, 1000);
        return () => {
            clearInterval(interval);
        };
    }, [resetTimer]);

    /**
     * Reset the timer and warning time once any field is updated or user switch from buy to sell or sell to buy
     */
    useEffect(() => {
        setTimerWarningTime(REFRESH_TIME_SHOW_WARNING_AT);
        resetTimer();
    }, [cryptoAmount, currencyAmount, type])

    /**
     * On change of the currency Amount show currency minimum transaction limit warning if it is below the allowed minimum transaction limit
     */
    useEffect(() => {
        if (currencyAmount && currencyAmount.originalValue) {
            setShowCurrencyMinTrxLimitError(currencyAmount.originalValue < minimumTrxAmountInCurrency);
        } else {
            setShowCurrencyMinTrxLimitError(false);
        }
    }, [currencyAmount])

    /**
     * On change of the crypto amount show crypto minimum transaction limit warning if it is below the allowed minimum transaction limit (applicable only for Buy) (There is not limit for sell)
     */
    useEffect(() => {
        if (cryptoAmount && cryptoAmount.originalValue) {
            setShowCryptoMinTrxLimitError(cryptoAmount.originalValue < (type === ON_OFF_RAMP_TYPES.SELL ? minimumTrxAmountInCrypto : 0));
        } else {
            setShowCryptoMinTrxLimitError(false);
        }
    }, [cryptoAmount])

    /**
     * Fetch the exchange rate and bank codes once selected currency or selected tokens changed
     */
    useEffect(() => {
        if (!primaryWallet?.connected) return
        fetchExchangeRate();
        fetchBankCodes();
    }, [selectedCurrency, selectedToken]);

    /**
     * Fetch the token balance once selected token is changed.
     */
    useEffect(() => {
        if (!primaryWallet?.connected) return
        fetchBalance(selectedToken);
    }, [selectedToken, primaryWallet?.address])

    /**
     * Reset all things after few milli seconds to wait for the fetchCrypto or fetch Currency debounce events to be completed.
     */
    useEffect(() => {
        debouncedReset();
    }, [type])

    useEffect(() => {
        if (!primaryWallet?.connected) return
        updateMinimumAmounts();
    }, [selectedCurrency, selectedToken, type]);

    /**
     * This will format the crypto amount to its format
     * 
     * @param amount crypto amount
     * @param decimals decimals of the crypto token
     * @returns 
     */
    const formatCryptoAmountWithDecimals = (amount: number, decimals: number) => {
        return Number(toCustomRoundedFixed(amount, decimals))
    }

    /**
     * This will format the crypto amount to its format via passing selected tokens decimal value or 6.
     * 
     * @param amount crypto amount
     * @returns 
     */
    const formatCryptoAmount = (amount: number) => {
        return Number(toCustomRoundedFixed(amount, selectedToken?.decimals || 6))
    }

    /**
    * This will format the currency amount to its format (2 decimal)
    * 
    * @param amount crypto amount
    * @returns 
    */
    const formatCurrencyAmount = (amount: number) => {
        return Number(toCustomRoundedFixed(amount, 2))
    }

    /**
     * This will return the Title of Buy Sell Button Title e.g. Buy USDT, Sell USDT etc...
     * 
     * @returns 
     */
    const buySellButtonTitle = () => {
        if (type === ON_OFF_RAMP_TYPES.BUY) {
            return `${t('onOffRampBuy')} ${selectedToken?.symbol || ""}`
        } else {
            if (!!walletAddress && (Number(cryptoAmount.originalValue) || 0) > (Number(tokenBalance) || 0)) {
                return `${t('tradeStopOrdersInsufficient').replace("{{token}}", selectedToken?.symbol || "")}`;
            }
            return `${t('onOffRampSell')} ${selectedToken?.symbol || ""}`
        }
    }

    /**
     * This will fetch the exchange rates between currently seelcted currency and token by passing 1 value in currency amount 
     */
    const fetchExchangeRate = async () => {
        setFetchingExchangeRate(true);  // Set the flag to true to show loading stuff
        if (selectedCurrency && selectedCurrency?.symbol && selectedToken && selectedToken?.symbol) {
            const data = { amount: 1, currency: selectedCurrency?.symbol, token: selectedToken?.symbol };
            const exchangeRateResponse: any = await dispatch(callApiPostMethod("FETCH_EXCHANGE_RATES_ON_RAMP", data));
            if (exchangeRateResponse?.data) {
                // fiatRate
                const { fiatRate: rate } = exchangeRateResponse?.data;
                setExchangeRate(rate);
            }

        }
        setFetchingExchangeRate(false); // Set the flag to false to hide loading stuff
    }

    /**
     * This method will convert the entered currency amount into crypto amount and also set the platform fee and their percentages
     * 
     * @param amount Currency amount entered
     * @param currency Selected Currency
     * @param selectedToken Selected Crypto token
     * @returns 
     */
    const fetchCryptoAmount = async (amount: number, currency: string, selectedToken: TOKENS) => {
        // setFetchingCryptoAmount(true);  // Set the flag to true to show loading stuff
        if (amount > 0 && currency && selectedToken?.symbol) {
            const data = { amount, currency, token: selectedToken?.symbol, includeBusinessFee: true };
            const result: any = await dispatch(callApiPostMethod("FETCH_EXCHANGE_RATES_ON_RAMP", data));
            if (result?.data) {
                const { cryptoAmount, cryptoAmountLessFee, businessFeeInFiat, platformFeeInFiat } = result?.data;
                const fees = businessFeeInFiat + platformFeeInFiat;
                setPlatformFeeInCurrency(formatCurrencyAmount(fees));
                setPlatformFeeInCurrencyPercentage(percentage(fees, amount));
                setCryptoAmount({
                    originalValue: formatCryptoAmountWithDecimals(cryptoAmountLessFee, selectedToken?.decimals || 6),
                    displayValue: formatCryptoAmountWithDecimals(cryptoAmountLessFee, selectedToken?.decimals || 6) + ""
                })
            }
        } else {
            setCryptoAmount({
                originalValue: 0,
                displayValue: ""
            })
        }
        setFetchingCryptoAmount(false);     // Set the flag to false to hide loading stuff
    }

    /**
     * This method will convert the entered crypto amount into currency amount and also set the platform fee and their percentages
     * 
     * @param amount Currency amount entered
     * @param currency Selected Currency
     * @param selectedToken Selected Crypto token
     * @returns 
     */
    const fetchCurrencyAmount = async (amount: number, currency: string, selectedToken: TOKENS) => {
        setFetchingCurrencyAmount(true);    // Set the flag to true to show loading stuff
        if (amount > 0 && currency && selectedToken?.symbol) {
            const data = { amount, currency, token: selectedToken.symbol };
            const result: any = await dispatch(callApiPostMethod("FETCH_EXCHANGE_RATES_OFF_RAMP", data));

            if (result?.data) {
                const { fiatAmountPayable, businessFeeInCrypto, feeInCrypto } = result?.data;
                const fees = businessFeeInCrypto + feeInCrypto;
                setPlatformFeeInCrypto(formatCryptoAmountWithDecimals(fees, selectedToken?.decimals || 6));
                setPlatformFeeInCryptoPercentage(percentage(fees, amount));
                setCurrencyAmount({
                    displayValue: formatCurrencyAmount(fiatAmountPayable) + "",
                    originalValue: formatCurrencyAmount(fiatAmountPayable)
                });
            }
        } else {
            setCurrencyAmount({
                displayValue: "",
                originalValue: 0
            });
        }
        setFetchingCurrencyAmount(false);   // Set the flag to false to hide loading stuff
    }

    /**
     * This method will update minimum trx amount on change of currency, token or on buy/sell tab switch
     */
    const updateMinimumAmounts = useCallback(async () => {
        // Do not update minimum amounts when any one of below is blank or null
        if (!selectedCurrency?.symbol) return;
        if (!selectedToken?.symbol) return;

        setFetchingMinimumBalance(true);

        // initially make minimum amounts to 0
        setMinimumTrxAmountInCurrency(0);
        setMinimumTrxAmountInCrypto(0);

        // Increment the call ID for each function call
        const currentCallId = ++callIdRef.current;

        if (type === ON_OFF_RAMP_TYPES.BUY) {
            const minAmountData = { type: "ONRAMP", currencyOrToken: selectedCurrency.symbol }
            const minAmountResult: any = await dispatch(callApiPostMethod("ON_OFF_RAMP_FETCH_MINIMUM_TRX_AMOUNT", minAmountData));
            let minAmount = minAmountResult?.data?.minimumAmount;
            if (minAmount) {
                minAmount = Math.ceil((minAmount / 5)) * 5;
                if (callIdRef.current !== currentCallId) return;    // return if another call executed
                setMinimumTrxAmountInCurrency(minAmount);
                const data = { amount: minAmount, currency: selectedCurrency.symbol, token: selectedToken.symbol };
                // Convert the provided tokens to currency
                const result: any = await dispatch(callApiPostMethod("FETCH_EXCHANGE_RATES_ON_RAMP", data));
                if (result?.data) {
                    const { cryptoAmountLessFee } = result?.data;
                    if (callIdRef.current !== currentCallId) return;    // return if another call executed
                    setMinimumTrxAmountInCrypto(formatCurrencyAmount(cryptoAmountLessFee));
                }
            }

        } else {
            const minAmountData = { type: "OFFRAMP", currencyOrToken: selectedToken.symbol }
            const minAmountResult: any = await dispatch(callApiPostMethod("ON_OFF_RAMP_FETCH_MINIMUM_TRX_AMOUNT", minAmountData));
            let minAmount = minAmountResult?.data?.minimumAmount;
            if (minAmount) {
                if (callIdRef.current !== currentCallId) return;    // return if another call executed
                setMinimumTrxAmountInCrypto(formatCurrencyAmount(minAmount));
                const data = { amount: minAmount, currency: selectedCurrency.symbol, token: selectedToken.symbol };
                // Convert the provided tokens to currency
                const result: any = await dispatch(callApiPostMethod("FETCH_EXCHANGE_RATES_OFF_RAMP", data));
                if (result?.data) {
                    const { fiatAmountPayable } = result?.data;
                    if (callIdRef.current !== currentCallId) return;    // return if another call executed                
                    setMinimumTrxAmountInCurrency(Math.ceil((fiatAmountPayable / 5)) * 5);
                }
            }
        }

        if (callIdRef.current !== currentCallId) return;    // return if another call executed 
        setFetchingMinimumBalance(false);
    }, [selectedCurrency, selectedToken, type]);

    /**
     * This function will load all initial data and set default values of selected token, selected currency
     * It will do following details
     * - Minimum transaction amount in dollar
     * - currencies
     * - tokens
     * - fetch token dollar values
     * - set default value of currencies dropdown to first one
     * - set default value of tokens dropdown to first one
     */
    const loadInitialData = async () => {
        const result: any = await dispatch(callApiPostMethod("ON_OFF_RAMP_INITIAL_DATA", {}, false, false));
        if (result && result?.data) {
            let { currencies, tokens, minimumTrxAmountInDollar } = result.data;
            setMinimumTrxAmountInDollar(formatCurrencyAmount(minimumTrxAmountInDollar));
            setCurrencies(currencies);
            let tokenData = tokenCollection?.filter((token: any) => tokens.some(t => t.symbol === token.symbol))
            setTokens(tokenData);
            if (currencies && currencies.length > 0) setSelectedCurrency(currencies[0]);
            if (tokens && tokens.length > 0) setSelectedToken(tokenData[0]);
        }
        await fetchTokenDollarValue();
    }

    /**
     * This method will fetch all supported banks from the selected currency
     */
    const fetchBankCodes = async () => {
        const response: any = await dispatch(callApiPostMethod("FETCH_BANK_CODES", { currency: selectedCurrency?.symbol }));
        if (response?.data) {
            let bankCodes = response?.data || [];
            bankCodes = bankCodes.map((bankDetails) => ({
                ...bankDetails,
                label: `${bankDetails?.bankCode} - ${bankDetails?.bankName}`    // Set the display label for bank code dropdown
            }))
            setBankCodes(bankCodes);
        }
    }

    /**
     * This method will fetch all tokens dollar value and store it into the state variable
     */
    const fetchTokenDollarValue = async () => {
        const tokenDollarValueResponse: DOLLAR_VAL | undefined = await dispatch(
            callApiPostMethod("DOLLAR_VALUE", {}, false, false)
        );
        setTokenDollarValue(tokenDollarValueResponse?.data || []);
    }

    /**
     * This function will calculate the exchange amount based on the passed currency or crypto amount with the help of the exchange rate fetched at the initial stage
     * 
     * @param amount Currency or Crypto Amount from 
     * @returns Converted amount
     */
    const calculateExchangeAmount = (amount: number) => {
        if (amount && exchangeRate) {
            if (type === ON_OFF_RAMP_TYPES.SELL) return amount * exchangeRate;
            else return amount * (1 / exchangeRate);
        }
        return 0;
    }

    /**
     * This method will validate the entered input and correct its format and if it meets the minimum limit criteria then fetch the crypto amount based on it.
     * 
     * @param value Entered Currency amount
     */
    const onChangeCurrencyAmount = async (value: number | "") => {
        const values = validateInput(value, "field1", "2", selectedToken?.decimals || 6);
        // const amount = formatCurrencyAmount(value);
        if (values) {
            // If input is valid then store amount in originalValue and store values (formatted value) into displayValue
            setCurrencyAmount({
                originalValue: value || 0,
                displayValue: values
            });
            if (value && value >= minimumTrxAmountInCurrency) {
                setFetchingCryptoAmount(true);
                await debouncedFetchCryptoAmount(value, selectedCurrency?.symbol, selectedToken);
            } else {
                setCryptoAmount({
                    originalValue: 0,
                    displayValue: ""
                });
            }
        } else {
            // If input is invalid
            setCryptoAmount({
                originalValue: 0,
                displayValue: ""
            });
            setCurrencyAmount({
                originalValue: 0,
                displayValue: ""
            });
        }

    }

    /**
     * This method will validate the entered input and correct its format and if it meets the minimum limit criteria then fetch the currency amount based on it.
     * 
     * @param value Entered Crypto amount
    */
   const onChangeCryptoAmount = async (value: number | "") => {
       const values = validateInput(value, "field1", selectedToken?.decimals || 6, "2");
       if (values) {
           // If input is valid then store amount in originalValue and store values (formatted value) into displayValue
            setCryptoAmount({
                originalValue: value || 0,
                displayValue: values
            });
            if (value && value >= minimumTrxAmountInCrypto) {
                setFetchingCurrencyAmount(true);
                await debouncedFetchCurrencyAmount(value, selectedCurrency?.symbol, selectedToken);
            } else {
                setCurrencyAmount({
                    originalValue: 0,
                    displayValue: ""
                });
            }
        } else {
            setCryptoAmount({
                originalValue: 0,
                displayValue: ""
            });
            setCurrencyAmount({
                originalValue: 0,
                displayValue: ""
            });
        }
    };

    /**
     * Calculates the percentage of amount with the total amount
     * 
     * @param amt1 Amount
     * @param amt2 Total Amount
     * @returns 
     */
    const percentage = (amount: number, total: number) => (amount / total) * 100;

    /**
     * Validates email address
     * 
     * @param email Email address 
     * @returns 
     */
    const isValidEmail = (email: string) => {
        return (email || "").match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)
    }

    /**
     * Validates user's name
     * 
     * @param name User name
     * @returns 
     */
    const isValidName = (name: string) => {
        return true;
    }

    /**
    * This method will update the user's email user collection after that it will also update
    * the email in userDetails state in redux store this will help both the database and store 
    * to refer to the same email.
    * @returns 
    */

    const updateUserEmail = async (email: string) => {
        const updatedFields: { userId: any; image?: any; name?: string; email?: string; shariah?: boolean; } = { userId: user?.userId, email };
        try {
            const result = await updateUserModal({
                ...updatedFields,
                dispatch,
            });
            // update user email address in store
            dispatch(userDetails({ emailAddress: email }))
        }
        catch (error) {
            console.log(error)
        }
    }

    /**
     * This method will validate the input data before going into next step and set the errors if it is invalid
     * - Initial Step - Validate all mandatory fields
     * - Enter Email Step - Validate whether email address is entered and valid format or not
     * - OTP Vetification - Validates OTP entered and its length
     * - Enter Basic Details - Validates whether firstname and lastname is entered and in valid format or not
     * - Enter Bank Details - Validates whether bank code and bank account is entered or not
     * 
     * @returns 
     */
    const validate = () => {
        if (step === PAYMENT_STEPS.INITIAL) {
            return selectedCurrency && selectedToken && currencyAmount.originalValue && cryptoAmount.originalValue;
        } else if (step === PAYMENT_STEPS.ENTER_EMAIL) {
            if (!userEmail) {
                setErrors({ ...errors, email: t('validationEmailNull') })
                return false;
            } else if (!isValidEmail(userEmail)) {
                setErrors({ ...errors, email: t('validationEmailInvalid') })
                return false;
            }
        } else if (step === PAYMENT_STEPS.OTP_VERIFICATION) {
            if (!otp || otp.length < 6) return false;
        } else if (step === PAYMENT_STEPS.ENTER_BASIC_DETAILS) {
            if (!userFirstName) {
                setErrors({ ...errors, firstname: t('validationFirstNameNull') })
                return false;
            } else if (!isValidName(userFirstName)) {
                setErrors({ ...errors, firstname: t('validationFirstNameInvalid') })
                return false;
            } else if (!userLastName) {
                setErrors({ ...errors, lastname: t('validationLastNameNull') })
                return false;
            } else if (!isValidName(userLastName)) {
                setErrors({ ...errors, lastname: t('validationLastNameInvalid') })
                return false;
            }
        } else if (step === PAYMENT_STEPS.ENTER_BANK_DETAILS) {
            if (!userBankCode || !userBankAccount) {
                setErrors({ ...errors, bankdetails: t('validationBankDetailsNull') });
                return false;
            }
        }
        setErrors({});
        return true;
    }

    /**
     * This method will process further with next step and fetch initial data of the new step if validations happens successfully.
     * 
     * @returns 
     */
    const next = async () => {
        if (!validate()) return;
        setLoader(true);    // Set the flag to true to show loading stuff
        if (step === PAYMENT_STEPS.INITIAL) {
            // Every time user is on initial stage we ensure that the value of email is the verified updated email which is coming from store and database
            setUserEmail(userDetailsData?.emailAddress)
            setStep(PAYMENT_STEPS.ENTER_EMAIL);
        } else if (step === PAYMENT_STEPS.ENTER_EMAIL) {

            // Check if user has modified their email
            const isModifiedEmail = userDetailsData?.emailAddress !== userEmail
            // no validation required if email is not modified
            if (!isModifiedEmail) {
                await getIvoryPayCustomerDetails();
                setStep(PAYMENT_STEPS.ENTER_BASIC_DETAILS)
            }
            else {

                // validate email with Dynamics.xyz 
                try {
                    const { isEmailVerificationRequired, updateUserProfileResponse } = await updateUser({ email: userEmail });
                    if (isEmailVerificationRequired) {
                        setVerificationUUID(updateUserProfileResponse.emailVerification?.verificationUUID);
                        setStep(PAYMENT_STEPS.OTP_VERIFICATION);
                    } else {
                        await getIvoryPayCustomerDetails();
                        await updateUserEmail(userEmail);
                        setStep(PAYMENT_STEPS.ENTER_BASIC_DETAILS)
                    }
                } catch (error: any) {
                    if (error.toString().includes("Too many email verification attempts")) {
                        setErrors({ ...errors, email: t("otpTooManyAttempts") })
                    } else {
                        setErrors({ ...errors, email: t("otpErrorGenericCode") })
                    }
                }
                setOtp("");
            }
        } else if (step === PAYMENT_STEPS.OTP_VERIFICATION) {
            if (!otp) {
                setErrors({ ...errors, otp: t('validationOTPInvalid') });
            } else {
                try {
                    const verificationToken = {
                        verificationToken: otp,
                        verificationUUID: verificationUUID
                    };
                    await verifyOtp(verificationToken.verificationToken, "email", verificationToken.verificationUUID);
                    await updateUserEmail(userEmail)
                    await getIvoryPayCustomerDetails();
                    setStep(PAYMENT_STEPS.ENTER_BASIC_DETAILS)
                } catch (error: any) {
                    if (error.toString().includes("This email is associated to another account")) {
                        setErrors({ ...errors, otp: t("otpErrorExistingEmail") })
                    } else {
                        setErrors({ ...errors, otp: t("otpErrorWrongCode") })
                    }
                } finally {
                    setOtp("");
                }
            }
        } else if (step === PAYMENT_STEPS.ENTER_BASIC_DETAILS) {

            if (type === ON_OFF_RAMP_TYPES.BUY) {
                setStep(PAYMENT_STEPS.WALLET_CONFIRMATION);
            } else {
                await fetchBankCodes();
                await setDefaultBankValues();
                setStep(PAYMENT_STEPS.ENTER_BANK_DETAILS);
            }
        } else if (step === PAYMENT_STEPS.ENTER_BANK_DETAILS) {
            setStep(PAYMENT_STEPS.WALLET_CONFIRMATION);
        } else if (step === PAYMENT_STEPS.WALLET_CONFIRMATION) {
            await initiatePayment();
        }
        setLoader(false);       // Set the flag to false to hide loading stuff
    }

    /**
     * TODO: Delete this function once we are on mainnet
     * Temporary code for Testnet, so that user will have testing bank details
     */

    const setDefaultBankValues = async () => {
        setUserBankCode(bankCodes?.find((bank: any) => bank.bankCode === "000004"));
        setUserBankAccount("2184648058");
    }
    /**
     * This method is used to go into previous step once back arrow is pressed
     * 
     * @returns 
     */
    const previous = async () => {
        if (step === PAYMENT_STEPS.INITIAL) return;
        else if (step === PAYMENT_STEPS.ENTER_BASIC_DETAILS) setStep(PAYMENT_STEPS.ENTER_EMAIL);
        else if (step === PAYMENT_STEPS.WALLET_CONFIRMATION && type === ON_OFF_RAMP_TYPES.BUY) setStep(PAYMENT_STEPS.ENTER_BASIC_DETAILS);
        else if (step === PAYMENT_STEPS.OFFRAMP_INITIATE_SUCCESS) setStep(PAYMENT_STEPS.WALLET_CONFIRMATION);
        else setStep(step - 1);
    }

    /**
     * Once user comes under OTP Verification step, it should send an verification code to user's email address
     */
    const sendVerificationEmail = async () => {
        try {
            const { isEmailVerificationRequired, updateUserProfileResponse } = await updateUser({ email: userEmail });
            toaster.success(t('otpSuccessResent'))
            setVerificationUUID(updateUserProfileResponse.emailVerification?.verificationUUID);
            setStep(PAYMENT_STEPS.OTP_VERIFICATION);
        } catch (error: any) {

            if (error.toString().includes("Too many email verification attempts")) {
                setErrors({ ...errors, otp: t('otpTooManyAttempts') })
            } else {
                setErrors({ ...errors, otp: t("otpErrorGenericCode") })
            }
        }
    }

    /**
     * fetches customer details if they have initiated payment before,
     * if it is invalid then show errors
     * else fetch first name and last name of the user if that user is already a part of ivory pay
     */
    const getIvoryPayCustomerDetails = async () => {
        try {
            const result: any = await dispatch(callApiPostMethod("ON_OFF_RAMP_CUSTOMER_DETAILS", { emailAddress: userEmail, walletAddress, otp }))
            if (result?.data) {
                const { firstName, lastName } = result?.data;
                setUserFirstName(firstName);
                setUserLastName(lastName);
            }
        } catch (error) {
            console.error(error)
        }
    }

    /**
     * This method will be called once user confirms his/her wallet address and want to process further with on/off ramp initiate process.
     * Save the response of the API into state variable which will be used to display the success page or error message
     * 
     * @returns 
     */
    const initiatePayment = async () => {
        if (type === ON_OFF_RAMP_TYPES.BUY) {

            const data = {
                firstName: userFirstName,
                lastName: userLastName,
                emailAddress: userEmail,
                amount: Number(currencyAmount.originalValue),
                amountReceivable: Number(cryptoAmount.originalValue),
                currency: selectedCurrency?.symbol,
                reference: uuidv4(),
                token: selectedToken?.symbol,
                walletAddress,
            }

            addBreadcrumb('OnRamp - Order Creation', '[initiatePayment] Input Data', 'info', { inputData: data });
            const result: any = await dispatch(callApiPostMethod("ON_RAMP_INITIATE_PAYMENT", data));
            addBreadcrumb('OnRamp - Order Creation', '[initiatePayment] Output Data', 'info', { Output: result });

            if (result?.status === RESPONSES.SUCCESS) {
                setInitiatePaymentResponse(result?.data);
                setStep(PAYMENT_STEPS.ONRAMP_INITIATE_SUCCESS);
            } else {
                captureException(result);
                setErrors({
                    ...errors,
                    initiatePayment: t('onOffRampInitiateTrxError')
                })
            }
        } else {
            const data = {
                firstName: userFirstName,
                lastName: userLastName,
                emailAddress: userEmail,
                amount: Number(cryptoAmount.originalValue),
                amountReceivable: Number(currencyAmount.originalValue),
                currency: selectedCurrency?.symbol,
                reference: uuidv4(),
                token: selectedToken?.symbol,
                accountNumber: userBankAccount,
                bankCode: userBankCode?.bankCode,
                walletAddress
            }

            addBreadcrumb('OffRamp - Order Creation', '[initiatePayment] Input Data', 'info', { inputData: data });
            const result: any = await dispatch(callApiPostMethod("OFF_RAMP_INITIATE_PAYMENT", data));
            addBreadcrumb('OffRamp - Order Creation', '[initiatePayment] Output Data', 'info', { Output: result });

            if (result?.status === RESPONSES.SUCCESS) {
                setInitiatePaymentResponse(result?.data);
                setStep(PAYMENT_STEPS.OFFRAMP_INITIATE_SUCCESS);
            } else {
                captureException();
                setErrors({
                    ...errors,
                    initiatePayment: t('onOffRampInitiateTrxError')
                })
            }
        }
    }

    /**
     * This method will find the token details from the symbol e.g. tokenAddress & isTokenNative
     * 
     * @param asset Token symbol
     * @returns 
     */
    const getTokenDetails = (asset: TOKENS) => {
        let returnData: TOKEN_DETAILS_SINGLE | undefined;

        const tokenData: TOKENS | undefined = dynamicContractDetails.find(
            (a) => a.symbol == "ETH"
        );
        returnData = {
            tokenAddress: asset?.address || '',
            isTokenNative: tokenData?.symbol === asset?.symbol ? true : false
        };
        return returnData;
    };

    /**
     * This method will fetch the balance of the provided token symbol
     * 
     * @param asset Token for which balance needs to fetched
     */
    const fetchBalance = async (asset: TOKENS) => {
        setFetchingBalance(true);   // Set flat to true to show loading stuff
        const tokenDetails: TOKEN_DETAILS_SINGLE = {
            tokenAddress: getTokenDetails(asset)?.tokenAddress || "",
            isTokenNative: getTokenDetails(asset)?.isTokenNative || false,
        };

        const balance = await fetchTokenBalance({ walletAddress, tokenDetails });
        setTokenBalance(balance?.tokenBalance || 0);
        setFetchingBalance(false);  // Set flat to false to hide loading stuff
    }

    /**
     * This method will reset all the stuffs
     * it will reset
     * - Selected currency to default
     * - Selected crypto token to default
     * - currency amount to 0
     * - crypto amount to 0
     * - payment step to initial
     * - user's email address, first name, last name, bank code, bank account
     * - otp entered to blank
     */
    const reset = (userDisconnected = false) => {
        if (userDisconnected) {
            setSelectedCurrency({});
            setSelectedToken({})
        } else {
            if (currencies && currencies.length > 0) setSelectedCurrency(currencies[0]);
            if (tokens && tokens.length > 0) setSelectedToken(tokens[0]);
            //Do not reset email when user has disconnected
            setUserEmail(userDetailsData?.emailAddress || "");
        }
        setCurrencyAmount({
            displayValue: "",
            originalValue: 0
        });
        setCryptoAmount({
            displayValue: "",
            originalValue: 0
        });
        setStep(PAYMENT_STEPS.INITIAL);

        setUserFirstName("");
        setUserLastName("");
        setOtp("");
        // if (bankCodes.length > 0) setUserBankCode(bankCodes && bankCodes[0]);
        setUserBankCode(undefined);
        setUserBankAccount("");
    }

    // This method will call reset method after debounce time set
    const debouncedReset = useCallback(debounce(reset, DEBOUNCE_TIME), []);

    // This method will call reset fetchCryptoAmount after debounce time set
    const debouncedFetchCryptoAmount = useCallback(debounce(fetchCryptoAmount, DEBOUNCE_TIME), []);

    // This method will call reset fetchCurrencyAmount after debounce time set
    const debouncedFetchCurrencyAmount = useCallback(debounce(fetchCurrencyAmount, DEBOUNCE_TIME), []);

    const TestNetSimulateToastMsg = () => {
        return <div className="toast-msg">
            <span>
                {t('simulateTestNetCautionMessage')} <a href="#" onClick={() => navigate("/on-off-ramp/order-history")}>{t('onOffRampOrderHistory')}</a>
            </span>
        </div>
    }

    const displayToast = () => {
        toaster.loading(<TestNetSimulateToastMsg />, "TestNetSimulate")
    };

    const dismiss = (id) => toaster.dismiss(id)

    useEffect(() => {
        if (step == PAYMENT_STEPS.ONRAMP_INITIATE_SUCCESS) {
            displayToast()
        } else {
            dismiss("TestNetSimulate");
        }
        return () => dismiss("TestNetSimulate");
    }, [step])

    return (
        <Row>
            <Col lg={6}>
                <CommonCard
                    noHeaderSpacing
                    cardTitle={""}
                    className={`on-off-ramp-buy-page ${step > 0 ? "disabled d-mobile-none" : ""}`}
                >
                    <div >
                        <div className="on-off-ramp-buy-sec">
                            {
                                type === ON_OFF_RAMP_TYPES.BUY ?
                                    // Buy Section
                                    (<div>
                                        {/* Send Section */}
                                        <div className="on-off-ramp-section">
                                            <div className="on-off-ramp-label-container">
                                                <p>{t('onOffRampSpent')}</p>
                                            </div>
                                            <div className="on-off-ramp-input-container">
                                                {/* Currency Amount Input Field */}
                                                <div className={`on-off-ramp-input-container-input ${fetchingCurrencyAmount || fetchingMinimumBalance ? 'text-shadow' : ''}`}>
                                                    <InputCustom
                                                        placeholder={t('onOffRampCurrencyPlaceHolder').replace("{{amount}}", formatCurrencyAmount(minimumTrxAmountInCurrency).toString())}  // Placeholder should be like 'Min. 1000' 
                                                        type="text"
                                                        maxLength={18}
                                                        inputMode="decimal"
                                                        name="currencyAmount"
                                                        autoComplete="off"
                                                        onChange={(e) => {
                                                            e.preventDefault();
                                                            onChangeCurrencyAmount(e.target.value);
                                                        }}
                                                        value={currencyAmount.displayValue}
                                                    />
                                                </div>
                                                {/* Currency Dropdown which lists all supported currencies */}
                                                <Dropdown
                                                    options={currencies}
                                                    dropIcon
                                                    selectedOption={selectedCurrency}
                                                    labelField={"symbol"}
                                                    valueField={"symbol"}
                                                    onChange={(selected) => setSelectedCurrency(selected)}
                                                />
                                            </div>

                                            <div className="on-off-ramp-data-container">
                                                <div className="on-off-ramp-data-container-input-dollar-value">
                                                    {/* Show error message when minimum transaction amount do not match */}
                                                    {showCurrencyMinTrxLimitError ? (
                                                        <p>
                                                            <span className="error-msg">
                                                                {
                                                                    t('onOffRampMinTrxLimitError')
                                                                        .replace("{{amount}}", minimumTrxAmountInCurrency + "")
                                                                        .replace("{{asset}}", selectedCurrency?.symbol)
                                                                }
                                                            </span>
                                                        </p>
                                                    ) :
                                                        // Show platform fee related information based on the entered currency amount
                                                        (platformFeeInCurrency && currencyAmount.originalValue) ?
                                                            (<p className={(fetchingCurrencyAmount || fetchingCryptoAmount) ? 'text-shadow' : ''}>
                                                                <div className="fees-text">
                                                                    <span>-</span>
                                                                    <span>{platformFeeInCurrency}</span>
                                                                    <span></span>
                                                                    <span>{selectedCurrency?.symbol}</span>
                                                                </div>
                                                                <div className="fees-text">
                                                                    <span>(</span>
                                                                    <span>{formatCurrencyAmount(platformFeeInCurrencyPercentage)}</span>
                                                                    <span>%</span>
                                                                    <span>{t('onOffRampFees')}</span>
                                                                    <span>)</span>
                                                                </div>
                                                            </p>) : <></>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        {/* Convert icon section between two text field  */}
                                        <button className={`swapBtn ${(fetchingExchangeRate || fetchingCryptoAmount || fetchingCurrencyAmount) ? 'active' : ''}`} >
                                            <ConvertIcon />
                                        </button>

                                        {/* Receive Section */}
                                        <div className="on-off-ramp-section">
                                            <div className="on-off-ramp-label-container">
                                                <p>{t('onOffRampReceive')}</p>
                                            </div>
                                            <div className="on-off-ramp-input-container">
                                                {/* Crypto Amount Input Field */}
                                                <div className={`on-off-ramp-input-container-input ${fetchingCryptoAmount ? 'text-shadow' : ''}`}>
                                                    <InputCustom
                                                        placeholder={"0.00"}
                                                        type="text"
                                                        maxLength={18}
                                                        inputMode="decimal"
                                                        name="cryptoAmount"
                                                        autoComplete="off"
                                                        onChange={(e) => {
                                                            e.preventDefault();
                                                            onChangeCryptoAmount(e.target.value)
                                                        }}
                                                        value={cryptoAmount.displayValue}
                                                    />
                                                </div>
                                                {/* Crypto token dropdown which contains all supported tokens */}
                                                <Dropdown
                                                    options={tokens}
                                                    dropIcon
                                                    selectedOption={selectedToken}
                                                    labelField={"symbol"}
                                                    valueField={"symbol"}
                                                    onChange={(selected) => setSelectedToken(selected)}
                                                />
                                            </div>
                                        </div>
                                    </div>) :
                                    // Sell Section
                                    (<div>
                                        {/* Send section */}
                                        <div className="on-off-ramp-section">
                                            <div className="on-off-ramp-label-container">
                                                <p>{t('onOffRampSpent')}</p>
                                            </div>
                                            <div className="on-off-ramp-input-container">
                                                <div className={`on-off-ramp-input-container-input ${fetchingCryptoAmount || fetchingMinimumBalance ? 'text-shadow' : ''}`}>
                                                    {/* Crypto Amount Input Field */}
                                                    <InputCustom
                                                        placeholder={t('onOffRampCryptoPlaceHolder').replace("{{amount}}", formatCryptoAmount(minimumTrxAmountInCrypto).toString())}
                                                        type="text"
                                                        maxLength={18}
                                                        inputMode="decimal"
                                                        name="cryptoAmount"
                                                        autoComplete="off"
                                                        onChange={(e) => {
                                                            e.preventDefault();
                                                            onChangeCryptoAmount(e.target.value);
                                                        }}
                                                        value={cryptoAmount.displayValue}
                                                    />
                                                </div>
                                                {/* Crypto token dropdown which contains all supported tokens */}
                                                <Dropdown
                                                    options={tokens}
                                                    dropIcon
                                                    selectedOption={selectedToken}
                                                    labelField={"symbol"}
                                                    valueField={"symbol"}
                                                    onChange={(selected) => setSelectedToken(selected)}
                                                />
                                            </div>
                                            <div className="on-off-ramp-data-container">
                                                <div className="on-off-ramp-data-container-input-dollar-value">
                                                    {/* Show error message when minimum transaction amount do not match */}
                                                    {showCryptoMinTrxLimitError ? (
                                                        <p>
                                                            <span className="error-msg">
                                                                {
                                                                    t('onOffRampMinTrxLimitError')
                                                                        .replace("{{amount}}", minimumTrxAmountInCrypto + "")
                                                                        .replace("{{asset}}", selectedToken?.symbol)
                                                                }
                                                            </span>
                                                        </p>
                                                    ) :
                                                        // Show platform fee related information based on the entered currency amount
                                                        (cryptoAmount.originalValue && platformFeeInCrypto) ?
                                                            (<p className={(fetchingCurrencyAmount || fetchingCryptoAmount) ? 'text-shadow' : ''}>
                                                                <div className="fees-text">
                                                                    <span>-</span>
                                                                    <span>{platformFeeInCrypto}</span>
                                                                    <span></span>
                                                                    <span>{selectedToken?.symbol}</span>
                                                                </div>
                                                                <div className="fees-text">
                                                                    <span>(</span>
                                                                    <span>{formatCurrencyAmount(platformFeeInCryptoPercentage)}</span>
                                                                    <span>%</span>
                                                                    <span>{t('onOffRampFees')}</span>
                                                                    <span>)</span>
                                                                </div>

                                                            </p>) : <></>
                                                    }
                                                </div>
                                                {
                                                    // Show Token Balance related information with Max Button
                                                    !!walletAddress && <p className="balance-text">
                                                        {t('onOffRampBalance')}:
                                                        <span className={fetchingBalance ? 'text-shadow' : 'lineUp'}>
                                                            {walletAddress
                                                                ? formatCryptoAmount(tokenBalance || 0)
                                                                : 0}
                                                        </span>
                                                        <ButtonCustom
                                                            title={t('onOffRampMaxBtn')}
                                                            className="max-btn"
                                                            onClick={() => onChangeCryptoAmount(formatCryptoAmount(tokenBalance || 0))}
                                                        />
                                                    </p>
                                                }
                                            </div>
                                        </div>

                                        {/* Convert icon section between two text field  */}
                                        <button className={`swapBtn ${(fetchingExchangeRate || fetchingCryptoAmount || fetchingCurrencyAmount) ? 'active' : ''}`} >
                                            <ConvertIcon />
                                        </button>

                                        {/* Receive section */}
                                        <div className="on-off-ramp-section">
                                            <div className="on-off-ramp-label-container">
                                                <p>{t('onOffRampReceive')}</p>
                                            </div>
                                            <div className="on-off-ramp-input-container">
                                                {/* Currency Amount Input Field */}
                                                <div className={`on-off-ramp-input-container-input ${fetchingCurrencyAmount ? 'text-shadow' : ''}`}>
                                                    <InputCustom
                                                        placeholder={"0.00"}
                                                        type="text"
                                                        maxLength={18}
                                                        inputMode="decimal"
                                                        name="currencyAmount"
                                                        autoComplete="off"
                                                        onChange={(e) => {
                                                            e.preventDefault();
                                                            onChangeCurrencyAmount(e.target.value);
                                                        }}
                                                        value={currencyAmount.displayValue}
                                                    />
                                                </div>
                                                {/* Currency Dropdown which lists all supported currencies */}
                                                <Dropdown
                                                    options={currencies}
                                                    dropIcon
                                                    selectedOption={selectedCurrency}
                                                    labelField={"symbol"}
                                                    valueField={"symbol"}
                                                    onChange={(selected) => setSelectedCurrency(selected)}
                                                />
                                            </div>
                                        </div>
                                    </div>)
                            }

                            {/* Section to show warning message that rates will get reflected after few time */}
                            {primaryWallet?.connected && <div className={`on-off-ramp-data-sec-footer on-off-timer ${step === PAYMENT_STEPS.INITIAL && timer <= timerWarningTime ? ' hidden' : ''}`}>
                                {
                                    step === PAYMENT_STEPS.INITIAL && timer <= timerWarningTime && <p className="timer">{t('onOffRampQuoteUpdateIn').replace("<<second>>", timer + "")}</p>
                                }
                            </div>}
                            {/* Section will contains the exchange rates and the button to proceed further with on/off ramp */}
                            <div className="on-off-ramp-data-sec-footer on-off-current-rate">
                                {
                                    (selectedCurrency?.symbol && selectedToken?.symbol && exchangeRate)
                                        ? type === ON_OFF_RAMP_TYPES.BUY ?
                                            <p>
                                                <Tooltip icon={<InfoIcon />} heading="" content={t('onOffRampCurrentRateTooptip')} />
                                                <span className={`on-off-ramp-data-sec-footer-text ${fetchingExchangeRate ? 'text-shadow' : ''}`}>
                                                    <span>1</span>
                                                    <span>{selectedCurrency?.symbol}</span>
                                                    <span>=</span>
                                                    <span>{formatCryptoAmount(calculateExchangeAmount(1))}</span>
                                                    <span>{selectedToken?.symbol}</span>
                                                </span>
                                            </p>
                                            :
                                            <p>
                                                <Tooltip icon={<InfoIcon />} heading="" content={t('onOffRampCurrentRateTooptip')} />
                                                <span className={`on-off-ramp-data-sec-footer-text ${fetchingExchangeRate ? 'text-shadow' : ''}`}>
                                                    <span>1</span>
                                                    <span>{selectedToken?.symbol}</span>
                                                    <span>=</span>
                                                    <span>{formatCurrencyAmount(calculateExchangeAmount(1))}</span>
                                                    <span>{selectedCurrency?.symbol}</span>
                                                </span>
                                            </p> : <p></p>

                                }
                                {
                                    type === ON_OFF_RAMP_TYPES.SELL ?
                                        <ButtonCustom
                                            title={buySellButtonTitle()}
                                            /**
                                            * disable button when crypto amount is less than minimum transaction amount (in crypto) or
                                            * greater than token balance or when the display value for token and currency is not set yet
                                            */
                                            disabled={((Number(cryptoAmount.originalValue) || 0) < Number(minimumTrxAmountInCrypto)) || ((Number(cryptoAmount.originalValue) || 0) > (Number(tokenBalance) || 0)) || (!cryptoAmount.displayValue || !currencyAmount.displayValue) || (fetchingExchangeRate || fetchingCryptoAmount || fetchingCurrencyAmount)}
                                            onClick={next}
                                        />
                                        :
                                        <ButtonCustom
                                            title={buySellButtonTitle()}
                                            disabled={((currencyAmount.originalValue || 0) < minimumTrxAmountInCurrency) || ((!cryptoAmount.displayValue || !currencyAmount.displayValue)) || (fetchingExchangeRate || fetchingCryptoAmount || fetchingCurrencyAmount)} //disable when minimum transaction limit criteria does not match
                                            onClick={next}
                                        />
                                }
                            </div>
                        </div>
                    </div>
                </CommonCard>
            </Col>
            {/* Right side section - Payment Section */}
            {step > PAYMENT_STEPS.INITIAL &&
                <Col lg={6}>
                    <CommonCard
                        noHeaderSpacing
                        cardTitle={""}
                    >
                        {
                            loader ? <div className="on-off-ramp-payment-page"><InfiniteSpinner /></div> :
                                <div className="on-off-ramp-payment-page">
                                    {/*
                            {
                                loader && <div className="spinner"><InfiniteSpinner /></div>
                            }
                            */}
                                    {/* Show back arrow when transaction is not initiated */}
                                    {
                                        step !== PAYMENT_STEPS.ONRAMP_INITIATE_SUCCESS && step !== PAYMENT_STEPS.OFFRAMP_INITIATE_SUCCESS &&
                                        <div className="on-off-ramp-payment-prev-arrow">
                                            <ButtonCustom transparent onClick={previous}>
                                                <LeftArrow />
                                            </ButtonCustom>
                                        </div>
                                    }
                                    {
                                        // Enter user's email address, show error message when invalid
                                        step === PAYMENT_STEPS.ENTER_EMAIL &&
                                        <div className="on-off-ramp-payment-section">
                                            <div className="on-off-ramp-payment-header">
                                                <h2>{t('onOffRampEnterEmailHeader')}</h2>
                                                <p>{t('onOffRampEnterEmailSubHeader')}</p>
                                            </div>
                                            <div className="on-off-ramp-payment-body row">
                                                <div className="on-off-ramp-payment-input-box">
                                                    <InputCustom
                                                        label={t('onOffRampEnterEmail')}
                                                        name="email"
                                                        type="email"
                                                        value={userEmail}
                                                        onChange={(e) => {
                                                            setUserEmail(e.target.value || "");
                                                            errors.email && delete errors.email;
                                                        }}
                                                    />
                                                    <span className="error-msg">{errors.email && errors.email}</span>
                                                </div>
                                            </div>
                                            <div className="on-off-ramp-payment-footer">
                                                <ButtonCustom
                                                    title={t('onOffRampContinueBtn')}
                                                    disabled={!userEmail || !isValidEmail(userEmail)}
                                                    fluid
                                                    onClick={next}
                                                />
                                            </div>
                                        </div>
                                    }
                                    {
                                        // Enter OTP sent to email address, show error message when invalid
                                        step === PAYMENT_STEPS.OTP_VERIFICATION &&
                                        <div className="on-off-ramp-payment-section">
                                            <div className="on-off-ramp-payment-header">
                                                <h2>{t('onOffRampOTPVerificationHeader')}</h2>
                                                <p>{t('onOffRampOTPVerificationSubHeader').replace('{{email}}', userEmail)}</p>
                                            </div>
                                            <div className="on-off-ramp-payment-body">
                                                <div className="on-off-ramp-payment-input-box otp-box">
                                                    {/* External input component to enter OTP */}
                                                    <OtpInput
                                                        value={otp}
                                                        onChange={(value) => {
                                                            setOtp(value);
                                                            errors.otp && delete errors.otp;
                                                        }}
                                                        numInputs={6}
                                                        containerStyle="otp-container Input"
                                                        inputStyle="otp-input"
                                                        inputType="number"
                                                        renderInput={(props) => <input {...props} />}
                                                    />
                                                    {/* Resend OTP link */}
                                                    <div className="otp-resend">
                                                        <span>{t('onOffRampOTPVerificationOTPNotReceived')}</span> <a href="javascript:void(0)"
                                                            onClick={() => {
                                                                errors.otp && delete errors.otp;
                                                                setOtp("");
                                                                sendVerificationEmail();
                                                            }}>{t('onOffRampOTPVerificationResend')}</a>
                                                    </div>
                                                    <div className="text-center">
                                                        <span className="error-msg ">{errors.otp && errors.otp}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="on-off-ramp-payment-footer">
                                                <ButtonCustom
                                                    title={t('onOffRampContinueBtn')}
                                                    disabled={!otp || otp.length < 6}
                                                    fluid
                                                    onClick={next}
                                                />
                                            </div>
                                        </div>
                                    }
                                    {
                                        // Enter user's basic information like first name and last name
                                        step === PAYMENT_STEPS.ENTER_BASIC_DETAILS &&
                                        <div className="on-off-ramp-payment-section">
                                            <div className="on-off-ramp-payment-header">
                                                <h2>{t('onOffRampBasicDetailsHeader')}</h2>
                                                <p>{t('onOffRampBasicDetailsSubHeader')}</p>
                                            </div>
                                            <div className="on-off-ramp-payment-body row">
                                                <div className="on-off-ramp-payment-input-box col-lg-6">
                                                    <InputCustom
                                                        label={t('onOffRampEnterFirstName')}
                                                        name="firstname"
                                                        type="text"
                                                        value={userFirstName}
                                                        onChange={(e) => {
                                                            setUserFirstName(e.target.value || "");
                                                            errors.firstname && delete errors.firstname;
                                                        }}
                                                    />
                                                    <span className="error-msg">{errors.firstname && errors.firstname}</span>
                                                </div>
                                                <div className="on-off-ramp-payment-input-box col-lg-6">
                                                    <InputCustom
                                                        label={t('onOffRampEnterLastName')}
                                                        name="lastname"
                                                        type="text"
                                                        value={userLastName}
                                                        onChange={(e) => {
                                                            setUserLastName(e.target.value || "");
                                                            errors.lastname && delete errors.lastname;
                                                        }}
                                                    />
                                                    <span className="error-msg">{errors.lastname && errors.lastname}</span>
                                                </div>
                                            </div>
                                            <div className="on-off-ramp-payment-footer">
                                                <ButtonCustom
                                                    title={t('onOffRampContinueBtn')}
                                                    fluid
                                                    disabled={!userFirstName || !isValidName(userFirstName) || !userLastName || !isValidName(userLastName)}
                                                    onClick={next}
                                                />
                                            </div>
                                        </div>
                                    }
                                    {
                                        // Enter user's bank details (bank code and bank account) for offramp 
                                        step === PAYMENT_STEPS.ENTER_BANK_DETAILS &&
                                        <div className="on-off-ramp-payment-section">
                                            <div className="on-off-ramp-payment-header">
                                                <h2>{t('onOffRampEnterBankDetailsHeader')}</h2>
                                                <p>{t('onOffRampEnterBankDetailsSubHeader')}</p>
                                            </div>
                                            <div className="on-off-ramp-payment-body row">
                                                <div className="on-off-ramp-payment-input-box col-xl-4 col-lg-5">
                                                    <Dropdown
                                                        options={bankCodes}
                                                        dropIcon
                                                        className="bank-code"
                                                        title={t('onOffRampBankCode')}
                                                        selectedOption={userBankCode}
                                                        valueField={"bankCode"}
                                                        labelField={"label"}
                                                        displayField={"bankCode"}
                                                        onChange={(selected) => {
                                                            setUserBankCode(selected);
                                                            errors.bankCode && delete errors.bankCode;
                                                        }}
                                                        enableSearch={true}
                                                    />
                                                </div>
                                                <div className="on-off-ramp-payment-input-box col-xl-8 col-lg-7">
                                                    <InputCustom
                                                        label={t('onOffRampEnterBankDetailsAccountNumber')}
                                                        name="accountNumber"
                                                        type="text"
                                                        value={userBankAccount}
                                                        onChange={(e) => {
                                                            setUserBankAccount(e.target.value || "");
                                                            errors.bankAccount && delete errors.bankAccount;
                                                        }}
                                                    />
                                                </div>
                                                <span className="error-msg">{errors.bankdetails && errors.bankdetails}</span>
                                            </div>
                                            <div className="on-off-ramp-payment-footer">
                                                <ButtonCustom
                                                    title={t('onOffRampContinueBtn')}
                                                    disabled={!userBankCode || !userBankAccount}
                                                    fluid
                                                    onClick={next}
                                                />
                                            </div>

                                        </div>
                                    }
                                    {
                                        // Confirm User's wallet address before initiating on/off ramp transaction
                                        step === PAYMENT_STEPS.WALLET_CONFIRMATION &&
                                        <div className="on-off-ramp-payment-section">
                                            <div className="on-off-ramp-payment-header">
                                                <h2>{t('onOffRampWalletAddressHeader')}</h2>
                                                <p>{t('onOffRampWalletAddressSubHeader')}</p>
                                            </div>
                                            <div className="on-off-ramp-payment-body">
                                                <div className="on-off-ramp-payment-input-box">
                                                    <InputCustom
                                                        label={t('onOffRampWalletAddress')}
                                                        name="walletAddress"
                                                        type="text"
                                                        value={walletAddress}
                                                        disabled={true}
                                                    />
                                                    <span className="error-msg">{errors.initiatePayment && errors.initiatePayment}</span>
                                                    {
                                                        errors.initiatePayment &&
                                                        <a href="javascript:void(0)"
                                                            className="try-again"
                                                            onClick={() => {
                                                                errors.initiatePayment && delete errors.initiatePayment;
                                                                reset();
                                                            }}>{t('onOffRampTryAgainLink')}</a>
                                                    }
                                                </div>
                                            </div>
                                            <div className="on-off-ramp-payment-footer">
                                                <ButtonCustom
                                                    title={t('onOffRampInitiateTrxBtn')}
                                                    fluid
                                                    onClick={next}
                                                />
                                            </div>
                                        </div>
                                    }
                                    {
                                        // Once transaction is initiated show the transfer informations 
                                        step === PAYMENT_STEPS.ONRAMP_INITIATE_SUCCESS &&
                                        <OnOffRampSuccessPage
                                            type={ON_OFF_RAMP_TYPES.BUY}
                                            reference={initiatePaymentResponse?.reference}
                                            bankName={initiatePaymentResponse?.bankName}
                                            accountName={initiatePaymentResponse?.accountName}
                                            accountNumber={initiatePaymentResponse?.accountNumber}
                                            amount={initiatePaymentResponse?.amount}
                                            currency={initiatePaymentResponse?.currency}
                                            expiresAt={initiatePaymentResponse?.expiresAt}
                                            walletAddress={initiatePaymentResponse?.walletAddress}
                                            handleClose={reset}
                                        />
                                    }
                                    {
                                        // Once transaction is initiated show the transfer informations 
                                        step === PAYMENT_STEPS.OFFRAMP_INITIATE_SUCCESS &&
                                        <OnOffRampSuccessPage
                                            type={ON_OFF_RAMP_TYPES.SELL}
                                            reference={initiatePaymentResponse?.reference}
                                            amount={initiatePaymentResponse?.amount}
                                            currency={initiatePaymentResponse?.currency}
                                            token={initiatePaymentResponse?.token}
                                            expiresAt={initiatePaymentResponse?.expiresAt}
                                            walletAddress={initiatePaymentResponse?.trxWalletAddress}
                                            handleClose={reset}
                                        />
                                    }
                                </div>
                        }
                    </CommonCard>
                </Col>

            }
        </Row >
    )
}

export default OnOffRampPage;